import api from "@/api";

export default {
  state: {
    permissions: [],
    selectedPermissions: [],
    rolePermissions: [],
  },

  getters: {
    permissions: (state) => state.permissions,
    selectedPermissions: (state) => state.selectedPermissions,
    rolePermissions: (state) => state.rolePermissions,
  },

  mutations: {
    setPermissions: (state, data) => {
      state.permissions = data;
    },
    setSelectedPermissions: (state, data) => {
      state.selectedPermissions = data;
    },

    setRolePermissions: (state, data) => {
      state.rolePermissions = data;

      //   // Remove duplicate permissions, then update the state
      //   state.rolePermissions = [
      //     ...state.rolePermissions,
      //     ...data.filter(
      //       (permission) =>
      //         !state.rolePermissions.find((p) => p.id == permission.id)
      //     ),
      //   ];
    },
  },

  actions: {
    getPermissions: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/permissions"
        );
        const { data: permissions } = response.data;
        commit("setPermissions", permissions);
      } catch (err) {
        console.error(err);
      }
    },

    createPermission: async ({ commit }, title) => {
      try {
        await api({ requiresAuth: true }).post("/v1/permissions", {
          title,
        });
        commit("setToast", {
          display: true,
          type: "success",
          message: "Permission created successfully.",
        });
      } catch (err) {
        console.error(err);
      }
    },

    // getRolePermissions: async ({ commit }, roleId) => {
    //   try {
    //     const response = await api({ requiresAuth: true }).get(
    //       `/v1/roles/${roleId}`
    //     );
    //     const { permissions } = response.data.data;
    //     commit("setRolePermissions", permissions);
    //   } catch (err) {
    //     console.error(err);
    //   }
    // },
  },
};
