import api from "@/api";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {

    deleteSaleCategoryPriceCompany: async (_, saleCategoryPriceCompanyId) => {
      try {
        await api({ requiresAuth: true }).delete(
          `/v1/sales-category-price-companies/${saleCategoryPriceCompanyId}`
        );
      } catch (err) {
        console.error(err);
      }
    },

    updateSaleCategoryPriceCompany: async (_, { saleCategoryPriceCompanyId, provision }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/sales-category-price-companies/${saleCategoryPriceCompanyId}`,
          {
            agent_provision: provision,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },
   addSaleCategoryPriceCompany: async (_, { salesCategoryPriceId, provision, companyId }) => {
      try {
        await api({ requiresAuth: true }).post(
          `/v1/sales-category-price-companies`,
          {
              sales_category_price_id: salesCategoryPriceId,
              agent_provision: provision,
              company_id: companyId
          }
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
};
