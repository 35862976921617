import api from "@/api";

export default {
  state: {
    genders: [],
  },
  getters: {
    genders: (state) => state.genders,
  },
  mutations: {
    setGenders: (state, data) => {
      state.genders = data;
    },
  },
  actions: {
    getGenders: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get("/v1/genders");
        const { data } = response.data;
        commit("setGenders", data);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
