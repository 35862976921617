<template>
  <div
    class="contact"
    :class="{ 'contact--selected': selectedCompany.id === company.id }"
    @click="selectCompany(company)"
  >
    <div class="contact__logo">
      <img v-if="company.logo" :src="company.logo.url" alt="" />
    </div>
    <div class="contact__name">{{ company.name }}</div>
  </div>
</template>

<script>
export default {
  name: "Contact",

  props: {
    company: {
      required: true,
      type: Object,
    },

    selectedCompany: {
      required: true,
      type: Object,
    },

    selectCompany: {
      required: true,
      type: Function,
    },
  },
};
</script>

<style scoped lang="scss">
.contact {
  background: var(--bg-16);
  border-radius: var(--radius-1);
  margin: 0 5px 10px 5px;
  flex: calc(100% / 3 - 10px);
  max-width: calc(100% / 3 - 10px);
  height: 130px;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    max-height: 75px;
    position: absolute;
    left: 0;
    top: 0;
    background: var(--bg-1);
    z-index: 1;
    box-shadow: inset 0px 3px 6px transparent, 0px 3px 7px var(--shadow-3);
    border-radius: inherit;
    transition: all 0.3s ease-in-out;
  }

  &:hover::after,
  &--selected::after {
    max-height: 100%;
  }

  &--selected::after {
    background: var(--bg-16);
  }

  &__logo,
  &__name {
    z-index: 2;
  }

  &__logo {
    position: relative;
    max-width: 125px;
    max-height: 56px;
    height: 56px;
    margin: 8px auto;

    img {
      height: 100%;
    }
  }

  &__name {
    position: absolute;
    width: 100%;
    bottom: 30px;
    text-align: center;
    font-size: var(--x-small);
    color: var(--text-9);
    transform: translateY(50%);
  }
}
</style>
