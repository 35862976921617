import api from "@/api";

export default {
  state: {
    roles: [],
    role: {},
  },

  getters: {
    roles: (state) => state.roles,
    role: (state) => state.role,
  },

  mutations: {
    setRoles: (state, data) => {
      state.roles = data;
    },
    setRole: (state, data) => {
      state.role = data;
    },
  },

  actions: {
    getRoles: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get("/v1/roles");
        const { data: roles } = response.data;
        commit("setRoles", roles);
      } catch (err) {
        console.error(err);
      }
    },

    getRole: async ({ commit }, id) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          `/v1/roles/${id}`
        );
        const { data: role } = response.data;
        commit("setRole", role);
      } catch (err) {
        console.error(err);
      }
    },

    updateRole: (_, { id, title, permissions }) =>
      api({ requiresAuth: true }).patch(`/v1/roles/${id}`, {
        title,
        permissions,
      }),

    createRole: async ({ commit }, { title, permissions }) => {
      try {
        await api({ requiresAuth: true }).post("/v1/roles", {
          title,
          permissions,
        });
        commit("setToast", {
          display: true,
          type: "success",
          message: "Role created successfully.",
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
