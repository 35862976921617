<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--company"></div>
      <div class="modal__back" @click="goBack()"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators">
          <div
            class="navigator"
            @click="goTo(0)"
            :class="{
              'navigator--active':
                currentPage === 0 || currentPage === 1 || currentPage === 2,
            }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">
              {{ $t(`addCompanyModal.${"details"}`) }}
            </div>
          </div>
          <div
            class="navigator"
            @click="goTo(1)"
            :class="{
              'navigator--active': currentPage === 1 || currentPage === 2,
            }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">
              {{ $t(`addCompanyModal.${"contact"}`) }}
            </div>
          </div>
          <div
            class="navigator"
            @click="goTo(2)"
            :class="{ 'navigator--active': currentPage === 2 }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">
              {{ $t(`addCompanyModal.${"logo"}`) }}
            </div>
          </div>
        </div>

        <form class="add__form">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <transition name="fade" mode="out-in">
                <div class="details" key="0" v-if="currentPage === 0">
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Name *"
                      v-model="customerDetails.companyName"
                      :class="{ 'input--error': nameHasError }"
                    />
                    <div class="form__error" v-if="nameHasError">
                      Please enter a name
                    </div>
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Ansprechspartner"
                    />
                  </div>
                  <div class="form__field">
                    <VSelect
                      :options="companyTypes"
                      :reduce="(type) => type.id"
                      placeholder="Rechtsform *"
                      label="type"
                      v-model="customerDetails.companyType"
                      class="select select--default"
                      :class="{ 'select--error': typeHasError }"
                    />
                    <div class="form__error" v-if="typeHasError">
                      Please select a type
                    </div>
                  </div>
                  <div class="form__field">
                    <VSelect
                      :options="languages"
                      :reduce="(language) => language.id"
                      placeholder="Sprache *"
                      label="code"
                      v-model="customerDetails.language"
                      class="select select--default"
                      :class="{ 'select--error': languageHasError }"
                    />
                    <div class="form__error" v-if="languageHasError">
                      Please select a language
                    </div>
                  </div>
                  <div class="form__row">
                    <div class="form__field form__field--double">
                      <input
                        type="text"
                        class="input input--default"
                        placeholder="Strasse *"
                        v-model="customerAddress[0].street"
                        :class="{
                          'input--error': streetHasError,
                        }"
                      />
                      <div class="form__error" v-if="streetHasError">
                        Please enter a street name
                      </div>
                    </div>
                    <div class="form__field">
                      <input
                        type="text"
                        class="input input--default input--nr"
                        placeholder="Nr."
                        v-model="customerAddress[0].number"
                      />
                    </div>
                  </div>
                  <div class="form__row">
                    <div class="form__field form__field--double">
                      <input
                        type="text"
                        class="input input--default"
                        placeholder="Postfach"
                        v-model="customerAddress[0].postBox"
                      />
                    </div>
                    <div class="form__field form__field--double">
                      <input
                        type="text"
                        class="input input--default input--plz"
                        placeholder="PLZ *"
                        v-model="customerAddress[0].zip"
                        :class="{ 'input--error': zipHasError }"
                      />
                      <div class="form__error" v-if="zipHasError">
                        {{ zipCodePlaceholder }}
                      </div>
                    </div>
                  </div>
                  <div class="form__field">
                    <VSelect
                      v-if="zipCodeData.length > 1"
                      :options="zipCodeData"
                      :reduce="(city) => city.city"
                      placeholder="ORT *"
                      label="city"
                      v-model="customerAddress[0].city"
                      class="select select--default"
                      :class="{ 'select--error': cityHasError }"
                    />

                    <div
                      v-else-if="zipCodeData.length"
                      class="input input--default input--readonly"
                      :class="{ 'input--error': cityHasError }"
                    >
                      {{ customerAddress[0].city }}
                    </div>

                    <input
                      v-else-if="!zipCodeData.length"
                      type="text"
                      class="input input--default"
                      placeholder="ORT *"
                      v-model="customerAddress[0].city"
                      :class="{ 'input--error': cityHasError }"
                    />

                    <div class="form__error" v-if="cityHasError">
                      City should be filled
                    </div>
                  </div>
                </div>

                <div class="contact" key="1" v-if="currentPage === 1">
                  <div class="form__field">
                    <div
                      class="form__input-wrapper"
                      v-for="(email, index) in customerEmails"
                      :key="index"
                    >
                      <input
                        type="text"
                        class="input input--default"
                        :placeholder="isAddingMember ? 'E-mail' : 'E-mail '"
                        @input="validateEmail($event, index)"
                        :value="email.email"
                        :class="{
                          'input--error': email.hasError,
                        }"
                      />
                      <div
                        class="form__add"
                        v-if="index === 0 && email.email"
                        @click="addInput('email')"
                      ></div>
                      <div
                        class="form__remove"
                        v-else-if="index !== 0"
                        @click="removeInput('email', email.id, index)"
                      ></div>
                      <div class="form__error" v-if="email.hasError">
                        {{ email.errorPlaceholder }}
                      </div>
                    </div>
                  </div>
                  <div class="form__field form__field--prefix">
                    <div
                      class="form__row"
                      v-for="(phone, index) in customerPhones"
                      :key="index"
                    >
<!--                      <div
                        class="form__input-wrapper form__input-wrapper&#45;&#45;prefix"
                      >
                        <VSelect
                          :options="phonePrefixes"
                          class="select select&#45;&#45;default"
                          :class="{
                            'input&#45;&#45;error': phone.prefixHasError,
                          }"
                          placeholder="Prefix"
                          @input="validatePrefix($event, index)"
                          :value="phone.prefix"
                        />
                        <div class="form__error" v-if="phone.prefixHasError">
                          {{ phone.prefixErrorPlaceholder }}
                        </div>
                      </div>-->
                      <div class="form__input-wrapper">
                        <input
                          type="text"
                          class="input input--default"
                          :placeholder="
                            isAddingMember ? 'Telefon' : 'Telefon '
                          "
                          @input="validatePhone($event, index)"
                          :value="phone.phone"
                          :class="{
                            'input--error': phone.hasError,
                          }"
                        />
                        <div
                          class="form__add"
                          v-if="index === 0 && phone.phone"
                          @click="addInput('phone')"
                        ></div>
                        <div
                          class="form__remove"
                          v-else-if="index !== 0"
                          @click="removeInput('phone', phone.id, index)"
                        ></div>
                        <div class="form__error" v-if="phone.hasError">
                          {{ phone.errorPlaceholder }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Web"
                      v-model="customerContact.web"
                    />
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="IBAN"
                      v-model="customerBank.account"
                    />
                  </div>
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Bankname"
                      v-model="customerBank.name"
                    />
                  </div>
                  <div class="form__field">
                    <input
                      type="hidden"
                      class="input input--default"
                      placeholder="UID"
                      v-model="customerContact.uid"
                    />
                  </div>
                </div>

                <div class="logo" key="2" v-if="currentPage === 2">
                  <div class="add__avatar">
                    <img
                      v-if="userImage"
                      :src="userImage"
                      alt=""
                      ref="userImage"
                    />
                    <div v-else class="add__avatar-empty">
                      <img
                        class="add__avatar-empty"
                        src="../assets/icons/default/Bold 2px-building.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="add__upload-text">
                    Upload your Company Image / Logo
                  </div>
                  <div class="add__upload-wrapper">
                    <button
                      type="button"
                      class="button button--default button--upload"
                      @click="$refs.uploadFile.click()"
                    >
                      Upload Image
                    </button>
                    <input
                      ref="uploadFile"
                      type="file"
                      class="add__upload"
                      @change="uploadImage($event)"
                    />
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </form>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        type="button"
        class="modal__button modal__button--submit button button--default"
        @click="currentPage !== 2 ? goForward() : createCustomer()"
      >
        <span v-if="currentPage === 2">Save</span>
        <span v-else>{{ $t(`addCompanyModal.${"next"}`) }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/partials/Loading";
import statics from "@/statics";
import parseFieldErrors from "@/helpers/parseFieldErrors";
export default {
  name: "AddCompanyModal",

  components: {
    Loading,
  },

  data() {
    return {
      statics,
      parseFieldErrors,
      currentPage: 0,
      formLoading: false,
      isRequesting: false,
      image: "",
      file: "",
      userImage: "",
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

      editingCustomer: {},

      nameHasError: false,
      languageHasError: false,
      typeHasError: false,
      zipHasError: false,
      cityHasError: false,
      streetHasError: false,
      emailHasError: false,
      phoneHasError: false,

      zipCodePlaceholder: "",
      emailPlaceholder: "",

      customerDetails: {
        companyName: "",
        companyType: "",
        language: "",
      },

      customerAddress: [
        {
          street: "",
          zip: "",
          city: "",
          postBox: "",
          number: "",
        },
      ],

      customerContact: {
        web: "",
        uid: "",
      },

      customerEmails: [
        {
          email: "",
          hasError: false,
          errorPlaceholder: "",
        },
      ],

      customerPhones: [
        {

          phone: "",
          hasError: false,
          errorPlaceholder: "",
        },
      ],

      customerBank: {
        name: "",
        account: "",
        address: "",
      },

      specificErrors: [],
    };
  },

  watch: {
    "customerDetails.companyName"(value) {
      if (value) {
        this.nameHasError = false;
      } else {
        this.nameHasError = true;
      }
    },
    "customerDetails.companyType"(value) {
      if (value) {
        this.typeHasError = false;
      } else {
        this.typeHasError = true;
      }
    },
    "customerDetails.language"(value) {
      if (value) {
        this.languageHasError = false;
      } else {
        this.languageHasError = true;
      }
    },
    zip(zip) {
      if (zip) {
        this.zipHasError = false;
        this.zipCodePlaceholder = "";

        if (Number(zip) >= 1000 && Number(zip) <= 9999) {
          this.loadCities(zip);
        } else {
          this.zipCodeData = [];
          this.customerAddress[0].city = "";
          this.zipHasError = true;
          this.zipCodePlaceholder = "Invalid zip code";
        }
      } else {
        this.customerAddress[0].city = "";
        this.zipHasError = true;
        this.zipCodePlaceholder = "Please enter a zip code";
      }
    },
    street(value) {
      if (value) {
        this.streetHasError = false;
      } else {
        this.streetHasError = true;
      }
    },
    city(value) {
      if (!this.isAddingMember) {
        if (value) {
          this.cityHasError = false;
        } else {
          this.cityHasError = true;
        }
      }
    },

    specificErrors(errors) {
      const errorKeys = errors.map((error) => error.errorKey);

      if (errorKeys.includes("email")) {
        this.currentPage = 1;
        errors.forEach((error) => {
          if (error.errorKey === "email") {
            this.customerEmails[error.errorPos].errorPlaceholder =
              error.errorValue;
            this.customerEmails[error.errorPos].hasError = true;
          }
        });
      } else {
        this.customerEmails.forEach((email) => {
          email.errorPlaceholder = "";
          email.hasError = false;
        });
      }

      if (errorKeys.includes("phone")) {
        this.currentPage = 1;
        errors.forEach((error) => {
          if (error.errorKey === "phone") {
            this.customerPhones[error.errorPos].errorPlaceholder =
              error.errorValue;
            this.customerPhones[error.errorPos].hasError = true;
          }
        });
      } else {
        this.customerPhones.forEach((phone) => {
          phone.errorPlaceholder = "";
          phone.hasError = false;
        });
      }
    },
  },

  async mounted() {
    this.formLoading = true;
    await this.$store.dispatch("getCustomerForms");

    if (this.companyIsEdit) {
      const customer = await this.$store.dispatch(
        "getCustomer",
        this.companyToEdit
      );
      this.editingCustomer = customer;
      this.loadFieldsForEdit(customer);
    }

    if (this.isAddingMember) {
      this.customerAddress[0].id = this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].id
        : "";
      this.customerAddress[0].street = this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].street
        : "";
      this.customerAddress[0].postBox = this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].post_box
        : "";
      this.customerAddress[0].zip = this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].zip
        : "";
    }
    this.formLoading = false;
  },

  computed: {
    ...mapGetters([
      "customerForms",
      "companyIsEdit",
      "companyToEdit",
      "isAddingMember",
      "customer",
      "isAddingPotentialCustomer",
    ]),

    languages() {
      return this.customerForms.languages;
    },

    companyTypes() {
      return this.customerForms["company-types"];
    },



    zipCodeData: {
      get() {
        return this.$store.state.zipCodes.zipCodeData;
      },
      set(value) {
        this.$store.commit("setZipCodeData", value);
      },
    },

    zip() {
      return this.customerAddress[0].zip;
    },
    street() {
      return this.customerAddress[0].street;
    },
    city() {
      return this.customerAddress[0].city;
    },
    email() {
      return this.customerEmails[0].email;
    },
    phone() {
      return this.customerPhones[0].phone;
    },
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddCompanyModal", false);
      this.$store.commit("setCompanyIsEdit", false);
      this.$store.commit("setIsAddingMember", false);
      this.$store.commit("setIsAddingPotentialCustomer", false);
      this.zipCodeData = [];
    },

    loadFieldsForEdit(customer) {
      this.customerDetails.companyName = customer.company_name || "";
      this.customerDetails.companyType = customer.company_type ? customer.company_type.id : "";
      this.customerDetails.language = customer ? customer.language.id : "";

      this.customerAddress[0].id = customer.customer_addresses.length
        ? customer.customer_addresses[0].id
        : "";
      this.customerAddress[0].street = customer.customer_addresses.length
        ? customer.customer_addresses[0].street
        : "";
      this.customerAddress[0].number = customer.customer_addresses.length
        ? customer.customer_addresses[0].number
        : "";
      this.customerAddress[0].postBox = customer.customer_addresses.length
        ? customer.customer_addresses[0].post_box
        : "";
      this.customerAddress[0].zip = customer.customer_addresses.length
        ? customer.customer_addresses[0].zip
        : "";
      this.customerAddress[0].city = customer.customer_addresses.length
        ? customer.customer_addresses[0].city
        : "";

      this.customerEmails = customer.customer_emails.length
        ? customer.customer_emails.map((email) => ({
            id: email.id,
            email: email.email,
            hasError: false,
            errorPlaceholder: "",
          }))
        : [
            {
              id: "",
              email: "",
              hasError: false,
              errorPlaceholder: "",
            },
          ];

      this.customerPhones = customer.customer_phones.length
        ? customer.customer_phones.map((phone) => ({
            id: phone.id,

            phone: phone.phone,
            hasError: false,
            errorPlaceholder: "",
          }))
        : [
            {
              id: "",

              phone: "",
              hasError: "",
              errorPlaceholder: "",

            },
          ];

      this.customerContact.web = customer.web || "";
      this.customerContact.uid = customer.uuid || "";
      this.userImage = customer.photo ? customer.photo.preview : "";
      this.customerBank.account = customer.banks.length
        ? customer.banks[0].account
        : "";
      this.customerBank.name = customer.banks.length
        ? customer.banks[0].name
        : "";
      this.customerBank.bankId = customer.banks.length
        ? customer.banks[0].id
        : "";
      this.customerBank.address = customer.banks.length
        ? customer.banks[0].address
        : "";
    },

    goBack() {
      this.currentPage--;
      if (this.currentPage < 0) {
        this.$store.commit("setShowAddCompanyModal", false);
        this.$store.commit("setCompanyIsEdit", false);
        this.$store.commit("setShowAddCustomerModal", true);
      }
    },

    goTo(page) {
      if (page === 1) {
        this.validateCustomerDetails((state) => {
          if (state) {
            this.currentPage = page;
          }
        });
      } else if (page === 2) {
        this.validateCustomerDetails((detailsState) => {
          this.validateCustomerContact((contactState) => {
            if (contactState && detailsState) {
              this.currentPage = page;
            }
          });
        });
      } else {
        this.currentPage = page;
      }
    },

    goForward() {
      if (this.currentPage < 2) {
        if (this.currentPage === 0) {
          this.validateCustomerDetails((state) => {
            if (state) {
              this.currentPage++;
            }
          });
        } else if (this.currentPage === 1) {
          this.validateCustomerContact((state) => {
            if (state) {
              this.currentPage++;
            }
          });
        }
      }
    },

    validateCustomerDetails(callback) {
      const { companyName, companyType, language } = this.customerDetails;
      const { zip, city, street } = this.customerAddress[0];
      if (!companyName) {
        this.nameHasError = true;
      }
      if (!companyType) {
        this.typeHasError = true;
      }
      if (!language) {
        this.languageHasError = true;
      }
      if (!zip) {
        this.zipHasError = true;
        this.zipCodePlaceholder = "Please enter a zip code";
      }
      if (!city) {
        this.cityHasError = true;
      }
      if (!street) {
        this.streetHasError = true;
      }

      if (
        !this.nameHasError &&
        !this.typeHasError &&
        !this.languageHasError &&
        !this.zipHasError &&
        !this.streetHasError &&
        !this.cityHasError
      ) {
        callback(true);
      }
    },

    validateCustomerContact(callback) {
  /*    if (!this.isAddingMember) {
        this.customerEmails.forEach((email) => {
          if (!email.email) {
            email.hasError = true;
            email.errorPlaceholder = "Email address field is required";
          }
        });
        this.customerPhones.forEach((phone) => {
                 if (!phone.phone) {
            phone.hasError = true;
            phone.errorPlaceholder = "Phone field is required";
          }
        });
      }*/
      this.customerEmails = [...this.customerEmails];
      this.customerPhones = [...this.customerPhones];
      if (
        !this.customerEmails.some((email) => email.hasError) &&
        !this.customerPhones.some(
          (phone) => phone.hasError
        )
      ) {
        callback(true);
      }
    },

    validateEmail(event, index) {
      const { value } = event.target;
      this.customerEmails[index].email = value;
      if (!this.isAddingMember) {
        if (this.customerEmails[index].email) {
          this.customerEmails[index].hasError = false;
          this.customerEmails[index].errorPlaceholder = "";

          if (
            !this.emailRegex.test(
              this.customerEmails[index].email.toLowerCase()
            )
          ) {
            this.customerEmails[index].hasError = true;
            this.customerEmails[index].errorPlaceholder =
              "Invalid email address.";
          }
        }/* else {
          this.customerEmails[index].hasError = true;
          this.customerEmails[index].errorPlaceholder =
            "Email address field is required.";
        }*/
      } else {
        if (
          this.customerEmails[index].email &&
          !this.emailRegex.test(this.customerEmails[index].email.toLowerCase())
        ) {
          this.customerEmails[index].hasError = true;
          this.customerEmails[index].errorPlaceholder =
            "Invalid email address.";
        } else {
          this.customerEmails[index].hasError = false;
          this.customerEmails[index].errorPlaceholder = "";
        }
      }

      this.customerEmails = [...this.customerEmails];
    },



    validatePhone(event, index) {
      const { value } = event.target;
      this.customerPhones[index].phone = value;
      if (!this.isAddingMember) {
        if (this.customerPhones[index].phone) {
          if (!this.statics.phoneRegex.test(value)) {
            this.customerPhones[index].hasError = true;
            this.customerPhones[index].errorPlaceholder =
              "Invalid phone number.";
          } else {
            this.customerPhones[index].hasError = false;
            this.customerPhones[index].errorPlaceholder = "";
          }
        } /*else {
          this.customerPhones[index].hasError = true;
          this.customerPhones[index].errorPlaceholder =
            "Phone field is required.";
        }*/
      } else {
        if (
          this.customerPhones[index].phone &&
          !this.statics.phoneRegex.test(value)
        ) {
          this.customerPhones[index].hasError = true;
          this.customerPhones[index].errorPlaceholder = "Invalid phone number.";
        } else {
          this.customerPhones[index].hasError = false;
          this.customerPhones[index].errorPlaceholder = "";
        }
      }

      this.customerPhones = [...this.customerPhones];
    },

    async loadCities(value) {
      await this.$store.dispatch("getZipCodeData", value);
      this.customerAddress[0].zip = value;
      this.customerAddress[0].city = this.zipCodeData[0].city;
    },

    addInput(type) {
      if (type === "email") {
        this.customerEmails = [...this.customerEmails, { email: "" }];
      } else if (type === "phone") {
        this.customerPhones = [
          ...this.customerPhones,
          { phone: "", }
        ];
      }
    },

    async removeInput(type, id, index) {
      if (type === "email") {
        if (!id) {
          this.customerEmails.splice(index, 1);
        } else {
          try {
            await this.$store.dispatch("deleteCustomerEmail", id);
            this.customerEmails.splice(
              this.customerEmails.findIndex((email) => email.id === id),
              1
            );
          } catch (err) {
            console.error(err);
          }
        }
      } else if (type === "phone") {
        if (!id) {
          this.customerPhones.splice(index, 1);
        } else {
          try {
            await this.$store.dispatch("deleteCustomerPhone", id);
            this.customerPhones.splice(
              this.customerPhones.findIndex((phone) => phone.id === id),
              1
            );
          } catch (err) {
            console.error(err);
          }
        }
      }
    },

    uploadImage(event) {
      this.file = event.target.files[0];
      this.userImage = URL.createObjectURL(this.file);
    },

    async createCustomer() {
      this.isRequesting = true;
      if (this.companyIsEdit) {
        this.image = this.editingCustomer.photo
          ? this.editingCustomer.photo.file_name
          : "";
      }
      const customer_emails = this.customerEmails[0].email
        ? this.customerEmails.map((email) => ({
            id: email.id,
            email: email.email,
          }))
        : [];
      const customer_phones = this.customerPhones[0].phone
        ? this.customerPhones.map((phone) => ({
            id: phone.id,
            phone: phone.phone
          }))
        : [];
      const customer = {
        ...this.customerDetails,
        ...this.customerContact,
        ...this.customerBank,
        file: this.file,
        member_type_id: this.statics.memberTypes.company,
        customerAddresses: this.customerAddress[0],
        customer_emails,
        customer_phones,
      };
      try {
        if (this.companyIsEdit) {
          const { id } = await this.$store.dispatch("updateCustomer", {
            ...customer,
            companyId: this.companyToEdit,
          });
          if (this.$route.name === "AccountDetails") {
            await this.$store.dispatch("getGlobalCustomer", id);
          }
          this.$store.commit("setToast", {
            display: true,
            type: "success",
            message: "Customer edited successfully.",
          });
        } else {
          if (this.isAddingMember) {
            const { parent_id } = this.customer;
            const { id } = this.$route.params;
            await this.$store.dispatch("addCustomer", {
              ...customer,
              parentId: parent_id || id,
            });
            await this.$store.dispatch("getGlobalCustomer", id);
            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Member added successfully.",
            });
          } else {
            const { id } = await this.$store.dispatch("addCustomer", customer);
            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Customer added successfully.",
            });
            this.$router.push({
              name: "AccountDetails",
              params: { entity: "customer", id },
            });
            if (this.isAddingPotentialCustomer) {
              await this.$store.dispatch("getGlobalCustomer", id);
              this.$store.commit("setCustomerDetails", this.customer);
              this.$store.commit("setShowAddPotentialPolicyModal", true);
            }
          }
        }
        this.closeModal();
      } catch (err) {
        const { errors } = err.response.data;
        this.specificErrors = this.parseFieldErrors(errors);
        this.isRequesting = false;
      }
    },
  },
};
</script>
