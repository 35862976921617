<template>
  <div class="add customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>

      </div>
      <div class="add__new" >Policy provision import</div>
      <div class="modal__main-icon modal__main-icon--provision"></div>
      <div class="modal__close" @click="closeModal()"></div>
      <div class="form">
        <div class="add__form import__form">
          <!-- Replace input file with drop file component -->
          <div class="form__row">
            <DropFile v-if="showInputFile" @input="handleFileUpload" />
          </div>
          <div class="form__row">
            <div class="sample__csv">
              <a v-if="showInputFile" href="/sample_import.xlsx" class="btn">Download Sample File</a>
            </div>
          </div>
          <div class="map__fields" v-if="headers.length">
            <div class="form__row">
              <h4 class="form__title">Map CSV Fields:</h4>
            </div>
            <div class="form__row">
              <div class="form__field form__field--double">
                <VSelect :options="headers" :reduce="(header) => header" placeholder="Policy number" label="name" v-model="policyNumberField" class="select select--default" />
              </div>
              <div class="form__field form__field--double">
                <VSelect :options="headers" :reduce="(header) => header" placeholder="Provision" label="name" v-model="provisionField" class="select select--default" />
              </div>
            </div>

            <div v-if="isRequesting" class="modal__button modal__button--submit button button--default button--loading"></div>
            <button v-else :class="{'disabled': !canProcessCSV}" class="modal__button modal__button--submit button button--default" @click="processMappedData">
              <span>Process CSV </span>
            </button>
          </div>

          <!-- Display parsed CSV data and import results -->
          <div v-if="csvData.length" class="map__fields processed__table">
            <h4 class="form__title">Parsed CSV Data:</h4>
            <table>
              <thead>
              <tr>
                <th>Policy Number</th>
                <th>Provision</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in csvData" :key="index">
                <td>{{ item.policyNumber }}</td>
                <td>{{ item.provision }}</td>
              </tr>
              </tbody>
            </table>
            <div v-if="isRequesting" class="modal__button modal__button--submit button button--default button--loading"></div>
            <button v-else class="modal__button modal__button--submit button button--default" @click="sendDataToServer">
              <span>Save</span>
            </button>
          </div>

          <!-- Display tables for successful and failed imports -->
          <div v-if="successfullyImported.length || failedImports.length" class="results">
            <h4>Import Results:</h4>
            <div v-if="successfullyImported.length">
              <h5>Successfully Imported Records:</h5>
              <table>
                <thead>
                <tr>
                  <th>Policy Number</th>
                  <th>Provision</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in successfullyImported" :key="index">
                  <td>{{ item.policyNumber }}</td>
                  <td>{{ item.provision }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-if="failedImports.length">
              <h5>Failed Imports:</h5>
              <table>
                <thead>
                <tr>
                  <th>Policy Number</th>
                  <th>Provision</th>
                  <th>Error</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in failedImports" :key="index">
                  <td>{{ item.policyNumber }}</td>
                  <td>{{ item.provision }}</td>
                  <td>{{ item.error }}</td>
                </tr>
                </tbody>
              </table>

              <button class="modal__button modal__button--submit button button--default button--download" @click="downloadErrorRows">
                <img src="../assets/icons/default/download_icon.svg"> <br>
                Download error rows
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropFile from './DropFile.vue';
import api from "@/api";
import Vue from "vue";
import * as XLSX from 'xlsx';
export default {
  name: "PolicyProvisionImportModal",
  components: {
    DropFile,
  },
  data() {
    return {
      csvData: [],
      headers: [],
      lines: [],
      policyNumberField: '',
      provisionField: '',
      isRequesting: false,
      successfullyImported: [],
      failedImports: [],
      showInputFile: true,
      bus: new Vue(),
    };
  },
  computed: {
    canProcessCSV() {
      return this.policyNumberField && this.provisionField;
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("setShowPolicyProvisionImportModal", false);
    },
    handleFileUpload(files) {
      const file = files[0].file;
      if (file) {
        // File validation
        if (!this.isValidCSV(file)) {
          this.$store.commit("setToast", {
            display: true,
            type: "error",
            message: 'Invalid file type or size. Please upload a CSV, XLS, or XLSX file.',
          });
          return;
        }

        const reader = new FileReader();
        if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel') {
          reader.onload = this.processCSV;
          reader.readAsText(file);
        } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const csv = XLSX.utils.sheet_to_csv(sheet);
            this.processCSV({ target: { result: csv } });
          };
          reader.readAsArrayBuffer(file);
        }

        this.showInputFile = false;
      }
    },
    isValidCSV(file) {
      const validTypes = [
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];
      if (!validTypes.includes(file.type)) {
        return false;
      }

      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        return false;
      }

      return true;
    },
    detectDelimiter(csv) {
      const delimiters = [',', ';', '\t'];
      let bestDelimiter = delimiters[0];
      let maxColumns = 0;

      delimiters.forEach(delimiter => {
        const lines = csv.split('\n');
        const columns = lines[0].split(delimiter).length;
        if (columns > maxColumns) {
          maxColumns = columns;
          bestDelimiter = delimiter;
        }
      });

      return bestDelimiter;
    },
    processCSV(event) {
      const csv = event.target.result.trim();
      const delimiter = this.detectDelimiter(csv);
      const lines = csv.split('\n');
      this.headers = lines[0].split(delimiter);
      this.lines = lines;
    },
    processMappedData() {
      if (this.lines.length > 1 && this.policyNumberField && this.provisionField) {
        const result = [];
        for (let i = 1; i < this.lines.length; i++) {
          const line = this.lines[i].trim();
          if (!line) continue;
          const fields = line.split(',');
          const obj = {
            policyNumber: fields[this.headers.indexOf(this.policyNumberField)].replace(/"/g, ''),
            provision: parseFloat(fields[this.headers.indexOf(this.provisionField)].replace(/"/g, '')),
          };
          result.push(obj);
        }
        this.csvData = result;
      }
    },
    downloadErrorRows() {
      if (!this.failedImports.length) {
        return;
      }

      let csvContent = "\uFEFF"; // Add BOM for Excel compatibility
      csvContent += "Policy Number,Provision,Error\n"; // Add headers

      this.failedImports.forEach(item => {
        const row = `${this.escapeCsvValue(item.policyNumber)},${this.escapeCsvValue(item.provision)},${this.escapeCsvValue(item.error)}`;
        csvContent += row + "\n";
      });

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "error_rows.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    escapeCsvValue(value) {
      if (typeof value === 'string') {
        value = value.replace(/"/g, '""');
        if (value.search(/("|,|\n)/g) >= 0) {
          value = `"${value}"`;
        }
      }
      return value;
    },
    async sendDataToServer() {
      try {
        this.isRequesting = true;
        const response = await api({
          requiresAuth: true,
        }).post(`/v1/import-policy-provisions`, { "csvData": this.csvData });

        const responseData = response.data;
        this.successfullyImported = responseData.successfullyImported || [];
        this.failedImports = responseData.failedImports || [];

        this.csvData = [];
        this.headers = [];
        this.lines = [];
        this.policyNumberField = '';
        this.provisionField = '';

        const fileInput = this.$refs.fileInput;
        if (fileInput) {
          fileInput.value = "";
        }

        this.showInputFile = false;

        this.$store.commit("setToast", {
          display: true,
          type: "success",
          message: responseData.message,
        });

      } catch (error) {
        const errorData = error.response?.data?.message || 'An error occurred while processing the CSV.';
        this.$store.commit("setToast", {
          display: true,
          type: "error",
          message: errorData,
        });

      } finally {
        this.isRequesting = false;
      }
    },
  },
};
</script>



<style scoped lang="scss">
@import "../assets/scss/_add-panel-modal.scss";
.modal {
  .import__form {
    width: 520px !important;
  }
  .modal__button--submit {
    right: 30px;
  }
  .form__row {
    justify-content: center;
  }
  &__panel {
    height: auto !important;
  }
  &__provision {
    padding: 28px 0;

    &-title {
      font-size: var(--medium);
    }

    &-title,
    &-label {
      color: var(--text-2);
    }

    &-label {
      margin-right: 10px;
    }

    &-data {
      color: var(--text-9);
      font-family: var(--muli-text-semi-bold);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 335px;
      display: flex;
    }

    &-container {
      margin-top: 25px;
      padding-top: 15px;
      border-top: 1px solid var(--border-10);
    }

    &-statuses {
      max-height: 300px;
      overflow: visible;
      overflow-y: scroll;
    }

    &-row {
      display: flex;
      flex-wrap: wrap;
      padding: 0 50px;

      .form__field {
        width: calc(50% - 10px);
        background: var(--bg-23);
        padding: 15px;
        border-radius: var(--radius-1);
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        &--margin {
          margin-right: 20px;
        }

        &--status {
          cursor: pointer;
        }

        &--paid {
          background: var(--bg-25);
          border: 1px solid var(--border-2);
          .modal__provision-data {
            color: var(--text-5);
          }
        }

        &--unpaid {
          background: var(--bg-24);
          .modal__provision-data {
            color: var(--text-12);
          }
        }

        &--supervisors {
          width: 100%;
        }

        &--empty {
          background: 0;
        }
      }

      .button {
        background-color: var(--bg-2);
        justify-content: center;
        font-family: var(--muli-text-regular);

        &:hover {
          background-color: var(--hover-1);
        }
      }
    }

    &-arrow {
      background: url("../assets/icons/default/next_arrow.svg");
      height: 16px;
      width: 16px;
      margin: 0 10px;
    }
  }
  .add__new, .add__title {
    color: var(--text-5);
    text-align: center;

    margin-bottom: 30px;
  }
  &__submit {
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--modal-bg-2);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    &-panel {
      width: 370px;
      padding: 9px 15px;
      background: var(--text-5);
      border-radius: var(--radius-1);
      display: flex;
      align-items: center;
    }

    &-icon {
      background: url("../assets/icons/default/question_icon.svg");
      min-width: 30px;
      height: 30px;
    }

    &-border {
      width: 1px;
      height: 70px;
      margin: 0 15px;
      background: var(--border-16);
    }

    &-container {
      flex-direction: column;
      align-items: flex-start;
    }

    &-buttons,
    &-container {
      display: flex;
      justify-content: center;
    }

    &-button {
      border-radius: var(--radius-1);
      box-shadow: 0px 3px 6px var(--shadow-3);
      color: var(--text-2);
      padding: 5px 0 5px 0;
      width: 100px;
      transition: all 0.3s ease;

      &:hover {
        color: var(--text-5);
        box-shadow: none;
      }

      &:first-child {
        margin-right: 50px;
      }

    }

    &-text {
      color: var(--text-1);
      margin-bottom: 15px;
    }
  }
  .sample__csv{
    margin-top: 30px;
    :hover {
      text-decoration: underline;
    }

  }
  .button--download {
    background: white;
    color: #7F8FA4;
    box-shadow: none;
    font-weight: normal;
    font-size: 14px;
    width: 170px;
    height: 50px;
    &:hover {
      color: var(--text-5);

    }
    img {
      width: 30px;
    }
  }
}
.results {

  h4, h5 {
    color: #22DB8E;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 10px;
    text-align: center;
  }
  h5 {
    color: #7F8FA4;
    margin-top: 20px;
  }

}
.processed__table, .results {
  max-height: 300px;
  overflow: scroll;
}
.map__fields {

  margin-top: 10px;
  margin-bottom: 10px;
  .form__title {
    color: #7F8FA4;
    font-weight: normal;
  }
}

.form__title {
  margin-top: 10px;
  margin-bottom: 20px;

}
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0; /* No spacing between rows */
  margin-top: 20px;
  border-spacing: 6px 0px;
}

th, td {
  padding: 16px;
  text-align: left;
  background-color: #fff;
  box-shadow: 0px 0px 10px #0000000D;
  border-bottom: 1px solid #DADCDE;
  border-radius: 0px;

}
td {
  font: normal normal 600 14px/18px;
  letter-spacing: 0px;
  color: #7F8FA4;
}
tr:first-child th {

  border-radius: 8px 8px 0px 0px;
}
tr:last-child td{
  border-radius: 0px 0px 8px 8px;
  border: none;
}

th {
  color : #22DB8E;
  font-weight: normal;
  font-size: 14px;
}

tbody tr + tr td {
  margin-top: 15px; /* Adds a gap between rows without separating cells */
}

tbody tr:hover td {
  background-color: #f1f1f1;
}
</style>



