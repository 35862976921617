import api from "@/api";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    updateModelCategory: async (_, { name, categoryId, modelId }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/service-model-categories/${categoryId}`,
          {
            name,
            model_id: modelId,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    deleteModelCategory: async (_, categoryId) => {
      try {
        await api({ requiresAuth: true }).delete(
          `/v1/service-model-categories/${categoryId}`
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
};
