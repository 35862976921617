<template>
<div class="dashboard"> 
    <div class="dashboard-header">
        <form class="search search--filter" @submit.prevent="keywordsSearch()">
            <div class="search__wrapper">
                <input class="search__search search__search--active" ref="search" type="text" v-model="searchKeywords" />
                <div class="search__icon"></div>
                <div class="search__splitter"></div>
            </div>
            <div class="search__filter" ref="filterToggler" @click="showSearchFilter = !showSearchFilter"></div>
            <SearchFilter v-if="showSearchFilter" :filterToggler="$refs.filterToggler" :toggleSearchFilter="
              (state) => {
                showSearchFilter = state;
              }
            " />
        </form>
   
        <button type="button" class="button button--default" @click="showAddCustomerModal()" v-if="$checkRolePermission('customer_create')">
            {{ $t(`dashboard.statistics.${"addCustomerBtn"}`) }}
        </button>
    </div>

    <div class="statistics-list" >

        <div class="statistics-item">
            <div v-if="loadingData" >
                <Loading />
            </div>

            <div v-else>
                <div class="statistics-img potential-pendencies"><img src="../assets/icons/default/potentialcustomer_icon.svg"></div>
                <div class="statistics-content">
                    <h2>{{ $t(`dashboard.statistics.${"totalPotentialTitle"}`) }}</h2>
                    <h1>{{dashboardSales.totalPotentialPolicies}}</h1>
                </div>
            </div>
        </div>

        <div class="statistics-item">
            <div v-if="loadingData">
                <Loading />
            </div>

            <div v-else>
                <div class="statistics-img customers"><img src="../assets/icons/default/users_icon.svg"></div>
                <div class="statistics-content">
                    <h2>{{ $t(`dashboard.statistics.${"customersTitle"}`) }}</h2>
                    <h1>{{dashboardSales.totalCustomers}}</h1>
                </div>

                <!-- <div class="statistics-badge statistics-badge__positive"><p>+ 2.3%</p></div> -->
            </div>
        </div>

        <div class="statistics-item">
            <div v-if="loadingData ">
                <Loading />
            </div>

            <div v-else>
                <div class="statistics-img contracts-process"><img src="../assets/icons/default/contact_icon_left_menu.svg"></div>
                <div class="statistics-content">
                    <h2>{{ $t(`dashboard.statistics.${"contractsProcessTitle"}`) }}</h2>
                    <h1>{{dashboardSales.totalContractsInProcess}}</h1>
                </div>

                <!-- <div class="statistics-badge statistics-badge__negative"><p>- 1.6%</p></div> -->
            </div>
        </div>

    </div>

    <div class="notes-list"  > 
        <div class="pendencies sales-pendencies">
            <div v-if="loadingData" style="height: 300px;">
                <Loading />
            </div> 
            <div v-else style="overflow-y: scroll;">
                <div class="notes-title ">
                    <div class="notes-title-img">
                        <img src="../assets/icons/default/pendencies_icon_grey.svg">
                    </div> 
                    <h2>{{ $t(`dashboard.statistics.${"pendenciesNotesTitle"}`) }}  </h2>
                </div> 
                 
                    <div class="container-wrapper" v-if="dashboardSales.pendencies && dashboardSales.pendencies.length > 0 ">
                        <div style="margin-bottom: 30px;" v-for="pendency in dashboardSales.pendencies" :key="pendency.id">
                            <div class="notes-container" :class="{ 'has-reply': pendency.latestReply }">
                               
                                <div class="sales-pendency-about" >
                                    <div class="user-agent-wrapper">
                                        <div class="user-container" > 
                                            <div class="name-img" :style="{ 'background-color': getRandomPendencyBgColor(pendency.customer.color, lastColorIndex).color }">
                                                
                                                <p v-if="!pendency.latestReply && pendency.user">{{ getInitials(pendency.user.first_name, pendency.user.last_name) }}</p> 
                                                <p v-else-if="pendency.latestReply && pendency.latestReply.user">{{ getInitials(pendency.latestReply.user.first_name, pendency.latestReply.user.last_name) }}</p>
                                            </div>
                                            <p v-if="!pendency.latestReply && pendency.user" class="user-name">{{pendency.user.first_name}} {{pendency.user.last_name}}</p>
                                            <p v-else-if="pendency.latestReply && pendency.latestReply.user" class="user-name">{{pendency.latestReply.user.first_name}} {{pendency.latestReply.user.last_name}}</p>
                                        </div>  
                                        <div  class="icon icon__long-arrow"></div>
                                        <div class="agent-container">
                                            <div class="name-img" :style="{ 'background-color': getRandomPendencyBgColor(pendency.customer.color, lastColorIndex).color }">
                                             
                                                <p v-if="!pendency.latestReply && pendency.agent">{{ getInitials(pendency.agent.first_name, pendency.agent.last_name) }}</p>
                                                <p v-else-if="pendency.latestReply && pendency.latestReply.agent">{{ getInitials(pendency.latestReply.agent.first_name, pendency.latestReply.agent.last_name) }}</p>  
                                            </div> 
                                            <p v-if="!pendency.latestReply && pendency.agent" class="agent-name">{{pendency.agent.first_name}} {{pendency.agent.last_name}}</p>
                                            <p v-else-if="pendency.latestReply && pendency.latestReply.agent" class="agent-name">{{pendency.latestReply.agent.first_name}} {{pendency.latestReply.agent.last_name}}</p>
                                        </div>
                                    </div>     
                                    <div class="user-actions">
                                        <a v-if="!pendency.latestReply && pendency.user && pendency.user.phone" :href="'tel:' + pendency.user.phone">
                                            <div class="icon icon__phone--active"></div>
                                        </a>
                                        <a v-else-if="pendency.latestReply && pendency.latestReply.user && pendency.latestReply.user.phone" :href="'tel:' + pendency.latestReply.user.phone">
                                            <div class="icon icon__phone--active"></div>
                                        </a>
                                        <div v-else class="icon icon__phone"></div> 

                                        <a v-if="!pendency.latestReply && pendency.user && pendency.user.email" :href="'mailto:' + pendency.user.email">
                                            <div class="icon icon__mail--active"></div>
                                        </a>
                                        <a v-else-if="pendency.latestReply && pendency.latestReply.user && pendency.latestReply.user.email" :href="'mailto:' + pendency.latestReply.user.email">
                                            <div class="icon icon__mail--active"></div>
                                        </a>
                                        <div v-else class="icon icon__mail"></div> 
                                        <router-link
                                            v-if="pendency.customer"
                                            class="icon icon__send-to-arrow"
                                            :to="{
                                                name: 'AccountDetails',
                                                params: {
                                                    entity: 'customer',
                                                    id: pendency.customer.id,
                                                    tab: 4
                                                },
                                            }"
                                        ></router-link>
                                    </div>    
                                </div>
                                <div class="pendency-content">
                                    <div class="customer-container">
                                         
                                            <div class="name-img" :style="{ 'background-color': getRandomPendencyBgColor(pendency.customer.color, lastColorIndex).color }">
                                                <p v-if="pendency.customer">{{ getInitials(pendency.customer.first_name, pendency.customer.last_name) }}</p> 
                                            </div>
                                        <p v-if="pendency.customer" class="customer-name">{{pendency.customer.first_name}} {{pendency.customer.last_name}}</p>
                                    </div> 
                                    <div class="date-actions-wrapper">
                                        <div class="pendency-date-wrapper" > 
                                            <div v-if="!pendency.latestReply"  class="icon icon__time--color"></div>
                                            <div v-else class="icon icon__round-tick"></div>
                                            <p v-if="!pendency.latestReply ">
                                                <span v-if="pendency.deadline">
                                                    {{formatDate(pendency.deadline)}}
                                                </span>
                                                <span v-else> {{ $t(`dashboard.statistics.${"noDeadline"}`) }}</span>
                                            </p>
                                            <p v-else-if="pendency.latestReply && pendency.latestReply.created_at">
                                                {{formatReplyData(pendency.latestReply.created_at)}}
                                            </p> 
                                        </div> 
                                        <div class="pendency-actions-wrapper">
                                            <div @click="openPendencyFilesModal(pendency.id, pendency.files, pendency.customer.id)">
                                                <div class="icon icon__pdf--active" v-if="pendency.files && pendency.files.length > 0"></div>
                                                <div class="icon icon__pdf" v-else></div>
                                            </div>
                                            <div class="icon icon__add" 
                                            @click="showAddReplyPendencyModal(pendency.id, pendency.agent, pendency.customer.id)"></div>
                                        </div>
                                    </div> 
                                    <div class="pendency-msg">
                                        <p v-if="!pendency.latestReply">{{pendency.description}}  </p>
                                        <p v-else>{{pendency.latestReply.description}}</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else style="height: 500px;">
                        <NoData style="transform: scale(0.8);" :type="'dashboard-pendencies'" /> 
                    </div>
                 
            </div>
        </div> 
        <div class="policies">
            <div v-if="loadingData" style="height: 300px;">
                <Loading />
            </div> 
            <div v-else style="overflow-y: scroll;">
                <div class="notes-title policies-title">
                    <div class="notes-title-img">
                        <img src="../assets/icons/default/potentialcustomer_icon_grey.svg">
                    </div> 
                    <h2>{{ $t(`dashboard.statistics.${"potentialPoliciesTitle"}`) }}</h2>
                </div> 
                <div class="container-wrapper" v-if="dashboardSales.potentialPolicies && dashboardSales.potentialPolicies.length > 0">
                    <div class="notes-container policy-container" v-for="policy  in dashboardSales.potentialPolicies" :key="policy.id">
                        <div class="policy-about">
                            <div class="policy-creator">
                                <div class="name-img policy-name-img" :style="{ 'background-color': getRandomPolicyBgColor() }" >
                                    <p v-if="policy.agent">{{ getInitials(policy.agent.first_name, policy.agent.last_name) }}</p>
                                </div>
                                <p v-if="policy.agent">{{policy.agent.first_name}} {{policy.agent.last_name}}</p>
                            </div>
                            <div class="sales-pendency-date-wrapper ">
                                <div class="pendency-date">
                                    <div class="icon icon__date"></div>
                                    <p v-if="policy.date">{{formatDate(policy.date)}}</p>
                                </div> 
                                <div class="policy-category pendency-date">
                                    <div class="icon icon__insurance"></div>
                                    <p>Category <span>{{policy.category.code}}</span></p>
                                </div> 
                                <div @click="openPolicyFilesModal(policy.id, policy.files, policy.customer_id)">
                                    <div v-if="policy.files && policy.files.length > 0" class="icon icon__pdf--active tool-icon"></div>
                                    <div v-else class="icon icon__pdf tool-icon"></div>
                                </div>
                              <router-link
                                  class="icon icon__send-to-arrow"
                                  :to="{
                                        name: 'AccountDetails',
                                        params: {
                                          entity: 'customer',
                                          id: policy.customer_id,
                                          tab: 1
                                             },
                                        }"
                              ></router-link> 
                            </div> 
                        </div>
                        <div class="policy-msg">
                            <p>{{ policy.text }}</p>
                        </div>
                    </div>
                </div>
                <div v-else style="height: 500px;">
                    <NoData style="transform: scale(0.8);" :type="'potential-policies-dashboard'" /> 
                </div>
            </div>
        </div>
    </div> 
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import NoData from "@/partials/NoData";
 import Loading from "@/partials/Loading";
import SearchFilter from "@/partials/SearchFilter"; 
export default {
    name: "Dashboard",
    computed: {
        ...mapGetters([
            'dashboardSales',
            'loggedUser',
        ]),
    },
    components: {
        SearchFilter,
        NoData,
         Loading
    },
    data() {
        return {  
            loadingData: true,
 
            searchKeywords: "",
            showSearchFilter: false,
            selectedAgents: [],
            selectedCompanyTypes: [],
            selectedCompanies: [],
            selectedBranchCategories: [],
            selectedPolicyStatuses: [],
            searchPlaceholder: "",
            hasTerminations: '',

            startDateFrom: "",
            startDateTo: "",

            endDateFrom: "",
            endDateTo: "",

            submitDateFrom: "",
            submitDateTo: "",

            paymentStatus: '',

            activeAgent: '',

            bgColors:[
                '#76B4FA',
                '#c51717',
                '#46afaf',
                '#807676',
                '#9d8585',
                '#2a8080',
                '#1E61BD',
                '#1EBD7B',
            ],

            lastColorIndex: null,
        }
    },
    async created() {
        if (Object.keys(this.$route.query).length > 0) { 
            this.$router.replace({
                path: this.$route.path,
                query: {}
            });
        } 
        if (this.loggedUser) {
            this.activeAgent = this.loggedUser.id
        } 
        await this.$store.dispatch("getSearchCustomerForms");
        await this.fetchSalesData();
    }, 
    methods: { 
        getRandomPolicyBgColor() {
            let colorIndex;
            do {
                colorIndex = Math.floor(Math.random() * this.bgColors.length);
            } while (colorIndex === this.lastGeneratedIndex);
            
            this.lastGeneratedIndex = colorIndex;
            return this.bgColors[colorIndex];
        },

        getRandomPendencyBgColor(color, lastIndex) {
            let colorIndex;
            do {
                colorIndex = Math.floor(Math.random() * this.bgColors.length);
            } while (this.bgColors[colorIndex] === color || colorIndex === lastIndex);
            return { color: this.bgColors[colorIndex], newIndex: colorIndex };
        },
  
        showAddCustomerModal() {
            this.$store.commit("setShowAddCustomerModal", true);
            this.$store.commit("setIsAddingCustomer", true);
        },

        async keywordsSearch() {
            this.$store.commit("setCustomersLoading", true);
            await this.$store.dispatch("searchCustomers", {
                search: this.searchKeywords,
                agents: this.selectedAgents,
                companyTypes: this.selectedCompanyTypes,
                companies: this.selectedCompanies,
                branchCategories: this.selectedBranchCategories,
                policyStatuses: this.selectedPolicyStatuses,
                birthday: this.$route.query.birthday,
                zip: this.$route.query.zip,
                has_terminations: this.hasTerminations, 
                startDateFrom: this.startDateFrom,
                startDateTo: this.startDateTo, 
                endDateFrom: this.endDateFrom,
                endDateTo: this.endDateTo, 
                submitDateFrom: this.submitDateFrom,
                submitDateTo: this.submitDateTo, 
                payment_status: this.paymentStatus
            });

            this.$router.replace({
                path: "/customers",
                query: {
                    page: 1,
                    search: this.searchKeywords,
                    has_terminations: this.hasTerminations,
                    payment_status: this.paymentStatus,
                },
            }); 
            this.$store.commit("setCustomersLoading", false);
        },

        async fetchSalesData() { 
            try {
                await this.$store.dispatch("getSalesDashboard");
                 
               this.loadingData= false;
            } catch (error) {
                this.$store.commit("setToast", {
                    display: true,
                    type: "error",
                    message: error,
                });
            } 
        },

        getInitials(firstName, lastName) {
            const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
            const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
            return `${firstInitial}${lastInitial}`;
        },

        formatDate(dateString) {
            const [year, month, day] = dateString.split('-');
            return `${day}.${month}.${year}`;
        },

        formatReplyData(dateString){
            const [datePart] = dateString.split('T');  
            const [year, month, day] = datePart.split('-');  

            return `${day}.${month}.${year}`;
        }, 

        showAddReplyPendencyModal(serviceId, agent, customerId) {
            this.$store.commit("setPendencyForReply", true);
            this.$store.commit("setShowAddPendencyModal", true);
            this.$store.commit("setPendencyReplyData", { 
                serviceId,
                agent
            }); 
            this.$store.commit("setCustomerFromDashboard", customerId);
        },

        openPendencyFilesModal(pendencyId, pendencyFiles, customerId) {
            this.$store.commit("setShowFilesEditModal", true);
            this.$store.commit("setFilesToEditId", pendencyId);
            this.$store.commit("setFilesToEdit", pendencyFiles);
            this.$store.commit("setFilesEditMode", 'Pendencies');

            this.$store.commit("setFilesFromDashboard", true);
            this.$store.commit("setCustomerFromDashboard", customerId);
        },

        openPolicyFilesModal(policyId, policyFiles, customerId) {
            this.$store.commit("setPolicyFileId", policyId);
            this.$store.commit("setPolicyFiles", policyFiles);
            this.$store.commit("setPolicyFilesModalIsPotential", true);
            this.$store.commit("setShowPolicyFilesModal", true);

            this.$store.commit("setFilesFromDashboard", true);
            this.$store.commit("setCustomerFromDashboard", customerId);
        },
    }
};
</script>

<style lang="scss" scoped> 
.office-staff-pendency-about .user-agent-wrapper{
    min-width: 420px !important;
}


.office-staff-pendency-content{
    padding: 18px 10px !important;
}


.pendency-costumer-container{
    min-width: 200px !important;
    display: flex;
    align-items: center;
    gap: 10px;
}

.pendency-deadline-container{
    min-width: 130px !important;
    display: flex;
    align-items: center;
    gap: 10px;
}

.dashboard {
    height: 100%;
    position: relative;

    &__top {
        margin-bottom: 20px;
        height: calc(100% - 432px - 20px);
        overflow: auto;
    }

    &__bottom {
        height: 432px;
        display: flex;
    }
}

.dashboard-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.dashboard-header .button--default {
    width: 200px;
}

.search {
    background-color: white;
    width: 50%;
}

.search__wrapper {
    width: 100%;
}

.search-filter {
    z-index: 5 !important;
    top: 60px !important;
    right: initial !important;
    left: 0 !important;
}

.search--filter .search__search--active,
.search--filter .search__search:focus {
    width: 90% !important;
}

.statistics-list,
.notes-list {
    width: 100%;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 50px 0;
    padding-bottom: 20px;
}

.statistics-item {
    width: 32%;
    box-sizing: border-box;
    padding: 15px;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 10px #0000000D;
    position: relative;
    padding-top: 60px;
    border-radius: 10px;
    transition: 0.3s ease;
}

.statistics-item:hover {
    box-shadow: none;
    margin-top: -10px;
}

.statistics-img {
    width: 70px;
    height: 70px;

    box-shadow: 0px 5px 10px #00000014;
    border-radius: 50%;
    position: absolute;
    top: -35px;
    left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 50%;
        height: 50%;
    }
}

.potential-pendencies {
    background-color: #F9FAFC;
}

.customers {
    background-color: #FEF3E1;
}

.contracts-process {
    background-color: #DBEDF6;
}

.statistics-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-right: auto;

    h1 {
        font-family: var(--muli-text-black);
        color: #22DB8E;
        font-size: 74px;
    }

    h2 {
        font-family: var(--muli-text-black);
        color: #B9C2CD;
        font-size: 24px;
    }
}

.statistics-badge {
    padding: 10px;
    border-radius: 10px;

    position: absolute;
    top: 12px;
    right: 12px;

    p {
        font-family: var(--muli-text-bold);
        font-size: 18px;
    }

    &__positive {
        background-color: #E5F9F0;

        p {
            color: #22DB8E;
        }
    }

    &__negative {
        background-color: #FBE8E8;

        p {
            color: #FF3333;
        }
    }
}
 

.all-pendencies-about {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;
}

 

.container-wrapper {
    max-height: 800px;
    padding: 15px;
}

.pendencies,
.policies {
    width: 49%;
    box-sizing: border-box;
    position: relative;
    // padding: 15px;
    padding-top: 50px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 10px #0000000D;
}

.notes-title {
    position: absolute;
    top: -35px;
    padding-left: 15px;

    display: flex;
    align-items: center;

    h2 {
        font-size: 24px;
        background-color: #E1E8FE;
        color: #7F8FA4;
        padding: 8px 12px;
        padding-left: 27px;
        z-index: 1;
        margin-left: -15px;
        border-radius: 10px;
        font-family: var(--muli-text-black);
    }
}

.notes-title-img {
    z-index: 2;
    width: 70px;
    height: 70px;
    box-shadow: 0px 5px 10px #00000014;
    background-color: #E1E8FE;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 50%;
    }
}

.notes-container {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px #0000000D;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
    margin-bottom: 30px;
    color: #363636;
    transition: 0.3s ease;
}

.notes-container:hover {
    box-shadow: 0px 0px 20px #00000026;
}

.notes-container:last-child {
    margin-bottom: 0;
}

.sales-pendency-about,
.pendency-content-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 15px;
}

.pendency-content-actions {
    padding: 0;
}

.action-btns {
    display: flex;
    align-items: center;
    gap: 15px;
}

.sales-pendency-creator,
.pendency-receiver {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 10px;

    p {
        font-size: 16px;
    }
}

.pendency-date {
    background-color: #F5F9FD;
    border-radius: 10px;
    padding: 2px 10px;

    display: flex;
    align-items: center;
    gap: 5px;

    p {
        color: #7F8FA4;
        font-size: 14px;
        font-family: var(--muli-text-semi-bold);
    }

    span {
        font-size: 16px;
        font-family: var(--muli-text-bold);
    }
}

.pendency-content {
    background-color: #F5F9FD;
    padding: 15px;
    border-radius: 10px;
    position: relative;
    padding-top: 55px;
    padding-bottom: 20px;
    margin-top: 40px;
}

.name-img  {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #76B4FA;
    display: flex;
    border: 2px solid #22DB8E;
    align-items: center;
    justify-content: center;

    p {
        color: white;
        font-family: var(--muli-text-bold);
        font-size: 10px;
    }

    img {
        width: 100%;
    }
}

 

.policy-container {
    margin-top: 20px;
    margin-bottom: 50px;
}

.policy-about {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: relative;
    margin-bottom: 16px;
}

.policy-creator {
    position: absolute;
    top: -35px;
    left: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-size: 16px;
    }
}

.sales-pendency-date-wrapper,
.pendency-category-wrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: auto;
}

.policy-msg {
    width: 100%;

    p {
        font-size: 14px;
        color: #707070;
    }
}

.policy-name-img {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;

    p {
        font-size: 12px;
    }
}

.dashboard-icon {
    width: 50px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;

}

.icon--pendencies {
    background-image: url("../assets/icons/default/modal_pendencies_icon.svg");
}


.user-agent-wrapper,
.user-container,
.agent-container,
.user-actions,
.pendency-date-wrapper,
.pendency-actions-wrapper{
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-name,
.agent-name,
.customer-name{
    font-size: 16px;
    color: #363636;
}

.customer-container{
    position: absolute;
    top:  0%;   
    left: 50%;  

    transform: translate(-50%, -50%);
    text-align: center;

    .name-img{
        margin: 0 auto;
    }
}

.date-actions-wrapper{
    position: absolute; 
    top: -25px; 
    right: 0;
    background-color: #F5F9FD;
    padding: 8px 14px;
    border-radius: 10px;
}

.pendency-date-wrapper{
    p{
        color: #7F8FA4;
        font-size: 14px;
        font-weight: bold;
    }
}

.pendency-date-wrapper{
    margin-bottom: 5px;
}
.pendency-actions-wrapper{
    justify-content: center;
}

.pendency-msg{
    font-size: 14px;
    color: #363636;
}

.has-reply{
    background-color: #22DB8D33 !important;
    border: 1px solid #22DB8E !important;
}

.limited-pendency-costumer,
.limited-pendency-deadline{
    display: flex;
    align-items: center;
    gap: 10px;
}

/* 2304-1470 */
@media (min-width: 2304px) {

    .pendencies,
    .policies {
        padding-top: 60px;
    }

    .statistics-content h2 {
        font-size: 28px;
    }

    .sales-pendency-creator p,
    .pendency-receiver p {
        font-size: 18px;
    }

    .pendency-msg p {
        font-size: 16px;
    }

    .pendency-date {
        p {
            font-size: 16px;
        }

        span {
            font-size: 18px;
        }
    }

    .name-img {
        width: 40px;
        height: 40px;

        p {
            font-size: 12px;
        }
    }

    .policy-container {
        margin-top: 30px;
    }

    .policy-about {
        margin-bottom: 24px;
    }

    .policy-msg {
        p {
            font-size: 16px;
        }
    }

    .policy-creator {
        top: -40px;

        p {
            font-size: 18px;
        }
    }

    .policy-name-img {
        width: 45px;
        height: 45px;

        p {
            font-size: 14px;
        }
    }
}

/* imac retina */
/*2560-1440*/
@media (min-width: 2560px) {
    .office-staff-pendency-about .user-agent-wrapper {
        min-width: 450px !important;
    }

    .pendency-costumer-container {
        min-width: 220px !important;
    }

    .pendency-deadline-container {
        min-width: 150px !important;
        p{
            font-size: 18px;
        }
    }

   

 
    //stats
    .statistics-img {
        width: 80px;
        height: 80px;
        top: -45px;
    }

    .statistics-content h2 {
        font-size: 30px;
    }

    .statistics-content h1 {
        font-size: 76px;
    }

    .statistics-badge p {
        font-size: 20px;
    }

    // lists
    .pendencies,
    .policies {
        padding-top: 70px;
    }

    .notes-title {
        top: -40px;
    }

    .notes-title-img {
        width: 80px;
        height: 80px;
    }

    .notes-title h2 {
        font-size: 28px;
    }

    .sales-pendency-creator p,
    .pendency-receiver p {
        font-size: 20px;
    }

    .pendency-msg p {
        font-size: 18px;
    }

    .pendency-date {
        p {
            font-size: 18px;
        }

        span {
            font-size: 20px;
        }
    }

    .name-img {
        width: 45px;
        height: 45px;

        p {
            font-size: 14px;
        }
    }

    .policy-container {
        margin-top: 40px;
    }

    .policy-about {
        margin-bottom: 30px;
    }

    .policy-msg {
        p {
            font-size: 18px;
        }
    }

    .policy-creator {
        top: -50px;

        p {
            font-size: 20px;
        }
    }

    .policy-name-img {
        width: 50px;
        height: 50px;

        p {
            font-size: 16px;
        }
    }


    .user-name, 
    .agent-name, 
    .customer-name {
        font-size: 18px;
    }

    .pendency-date-wrapper p { 
        font-size: 16px;
    }
}

/* 2880-1670 */
@media(min-width: 2880px) { 
    .office-staff-pendency-about .user-agent-wrapper {
        min-width: 550px !important; 
    }

    .pendency-costumer-container {
        min-width: 260px !important;
    }

    .pendency-deadline-container {
        min-width: 180px !important;
        p {
            font-size: 20px;
        }
    }
 
    //stats
    .statistics-img {
        width: 90px;
        height: 90px;
        top: -45px;
    }

    .statistics-content h2 {
        font-size: 34px;
    }

    .statistics-content h1 {
        font-size: 80px;
    }

    .statistics-badge p {
        font-size: 22px;
    }

    // lists
    .pendencies,
    .policies {
        padding-top: 80px;
    }

    .notes-title {
        top: -45px;
    }

    .notes-title-img {
        width: 85px;
        height: 85px;
    }

    .notes-title h2 {
        font-size: 30px;
    }

    .sales-pendency-creator p,
    .pendency-receiver p {
        font-size: 22px;
    }

    .pendency-msg p {
        font-size: 20px;
    }

    .pendency-date {
        p {
            font-size: 20px;
        }

        span {
            font-size: 22px;
        }
    }

    .name-img {
        width: 50px;
        height: 50px;

        p {
            font-size: 16px;
        }
    }

    .policy-container {
        margin-top: 50px;
    }

    .policy-about {
        margin-bottom: 40px;
    }

    .policy-msg {
        p {
            font-size: 20px;
        }
    }

    .policy-creator {
        top: -60px;

        p {
            font-size: 22px;
        }
    }

    .policy-name-img {
        width: 55px;
        height: 55px;

        p {
            font-size: 18px;
        }
    }

    .user-name, 
    .agent-name, 
    .customer-name {
        font-size: 20px;
    }
}

/*3200-1800*/
@media(min-width: 3200px) { 
    .office-staff-pendency-about .user-agent-wrapper {
        min-width: 600px !important; 
    }

    .pendency-deadline-container p {
        font-size: 22px;
    }

    //stats  
    .statistics-content h2 {
        font-size: 36px;
    }

    .statistics-content h1 {
        font-size: 82px;
    }

    .statistics-badge p {
        font-size: 22px;
    }

    // lists 
    .pendencies,
    .policies {
        padding-top: 90px;
    }

    .sales-pendency-creator p,
    .pendency-receiver p {
        font-size: 22px;
    }

    .pendency-date {
        p {
            font-size: 20px;
        }

        span {
            font-size: 22px;
        }
    }

    .name-img {
        width: 50px;
        height: 50px;

        p {
            font-size: 16px;
        }
    }

    .policy-container {
        margin-top: 50px;
    }

    .policy-about {
        margin-bottom: 40px;
    }

    .policy-creator {
        top: -60px;

        p {
            font-size: 22px;
        }
    }

    .policy-name-img {
        width: 55px;
        height: 55px;

        p {
            font-size: 18px;
        }
    }


    .user-name, 
    .agent-name, 
    .customer-name {
        font-size: 22px;
    }

    .pendency-date-wrapper p {
        font-size: 18px;
    }
}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px) {
 
    //stats
    .statistics-img {
        width: 100px;
        height: 100px;
    }

    .statistics-content h2 {
        font-size: 40px;
    }

    .statistics-content h1 {
        font-size: 86px;
    }

    .statistics-badge p {
        font-size: 24px;
    }

    // lists 
    .notes-title-img {
        width: 95px;
        height: 95px;
    }

    .notes-title h2 {
        font-size: 34px;
    }

    .pendencies,
    .policies {
        padding-top: 100px;
    }

    .sales-pendency-creator p,
    .pendency-receiver p {
        font-size: 28px;
    }

    .pendency-msg p {
        font-size: 24px;
    }

    .pendency-date {
        p {
            font-size: 24px;
        }

        span {
            font-size: 26px;
        }
    }

    .name-img {
        width: 60px;
        height: 60px;

        p {
            font-size: 18px;
        }
    }

    .policy-container {
        margin-top: 60px;
    }

    .policy-about {
        margin-bottom: 50px;
    }

    .policy-msg {
        p {
            font-size: 24px;
        }
    }

    .policy-creator {
        top: -60px;

        p {
            font-size: 24px;
        }
    }

    .policy-name-img {
        width: 65px;
        height: 65px;

        p {
            font-size: 22px;
        }
    }

    .user-name, 
    .agent-name, 
    .customer-name {
        font-size: 24px;
    }

    .pendency-date-wrapper p {
        font-size: 20px;
    }
}

/* 1920x961 (current) */

/* macbook pro 16 2021 */
/*1728x1085*/
@media(max-width: 1728px) {
    //stats
    // .statistics-item {
    //   padding-top: 80px;
    // }

    .statistics-img {
        width: 65px;
        height: 65px;
        top: -30px;
    }

    .statistics-content h1 {
        font-size: 60px;
    }

    .statistics-badge p {
        font-size: 16px;
    }

    //notes
    .notes-title-img {
        width: 65px;
        height: 65px;
    }

    .notes-title h2 {
        font-size: 22px;
    }
}

/*1688-1080*/
@media(max-width: 1688px) {

    .office-staff-pendency-about{
        padding: 0;
    }

    .office-staff-pendency-about .user-agent-wrapper {
        min-width: 400px !important;
    }

    .pendency-costumer-container {
        min-width: 180px !important;
    }
}

/*1680-1080*/
@media(max-width: 1680px) {
    //stats
    // .statistics-item {
    //   padding-top: 70px;
    // }

    .statistics-img {
        width: 60px;
        height: 60px;
        top: -30px;
    }

    .statistics-content {
        width: 100%;
    }

    .statistics-content h1 {
        font-size: 50px;
    }

    .statistics-badge p {
        font-size: 14px;
    }

    .statistics-content h2 {
        font-size: 22px;
    }

    //notes
    .notes-title {
        top: -30px;
    }

    .notes-title-img {
        width: 60px;
        height: 60px;
    }

    .notes-title h2 {
        font-size: 20px;
    }

    .sales-pendency-date-wrapper,
    .pendency-category-wrapper {
        gap: 10px;
    }

    .pendency-date p {
        font-size: 12px;
    }

    .pendency-date span {
        font-size: 14px;
    }

    .sales-pendency-creator p,
    .pendency-receiver p,
    .policy-creator p {
        font-size: 14px;
    }

    .name-img p {
        font-size: 10px;
    }

    .policy-name-img p {
        font-size: 12px;
    }
}

/*1640-1080*/
@media(max-width: 1640px) { 
 
}

/* 1600 - 757 */
@media (max-width: 1600px) {}

/*1536-864*/
@media(max-width:1536px) {
    .all-pendencies-about {
        flex-wrap: wrap;
    }
 
    .user-agent-wrapper, 
    .user-container, 
    .agent-container, 
    .user-actions, 
    .pendency-date-wrapper, 
    .pendency-actions-wrapper { 
        gap: 5px;
        
    }

    .sales-pendency-about{
        flex-wrap: wrap;
    }

    .user-name, 
    .agent-name, 
    .customer-name {
        font-size: 14px;
    }

    // .sales-pendency-about,
    .policy-about {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0;
    }

    .sales-pendency-date-wrapper {
        margin-left: 0;
        margin-top: 15px;
        width: 100%;
        gap: 0;
        justify-content: space-between;
    }

    .policy-container {
        margin-top: 0px;
    }

    .policy-creator {
        position: relative;
        top: 0;
        flex-direction: row;
        gap: 10px;
    }

    .policy-name-img {
        margin: 0;
    }

    .name-img {
        width: 35px;
        height: 35px;

        p {
            font-size: 10px;
        }
    }

}

/* macbook pro/air */
/* 1440-900 */
@media(max-width:1440px) {}

/*1366-638*/
@media (max-width:1366px) {
    .office-staff-pendency-about .user-agent-wrapper {
        min-width: 350px !important;
    }

    .pendency-costumer-container {
        min-width: 160px !important;
    }

    .pendency-deadline-container{
        min-width: 110px !important;
        p{
            font-size: 14px;
        }
    }


    .statistics-img {
        width: 50px;
        height: 50px;
        top: -25px;
    }

    .statistics-content h1 {
        font-size: 36px;
    }

    .statistics-content h2 {
        font-size: 18px;
    }

    .statistics-badge p {
        font-size: 12px;
    }

    .notes-title {
        top: -25px;
    }

    .notes-title-img {
        width: 50px;
        height: 50px;
    }

    .notes-title h2 {
        font-size: 18px;
    }

    .notes-container {
        padding: 10px;
    }
}

/* macbook air */
/* 1280-800 */
@media (max-width:1280px) {
    .sales-pendency-about{
        gap: 10px;
    } 

    .user-actions{
        margin-left: auto;
    }
}

/* 1200-1920 */
@media (max-width:1200px) {
    .office-staff-pendency-about{
        flex-wrap: wrap;
    }

    .statistics-item {
        width: 49%;
        margin-bottom: 50px;
    }

    .statistics-item:last-child {
        margin-bottom: 0;
    }

    .pendencies,
    .policies {
        width: 100%;
    }

    .pendencies {
        margin-bottom: 80px;
    }
}

/*1134x712*/
@media(max-width:1134px) {}

/* tablets */

/* micro duo */
/*1114x705*/
@media(max-width:1114px) {
 
}

/*1024-768*/
/* ipad pro */
/*1024-1366*/
@media (max-width:1024px) {}

/* ipad pro 2/3 */
/* 981x1024 */
@media (max-width:981px) {
    .statistics-item {
        width: 100%;
    }
}

/* 962x601 */
@media(max-width: 962px) {}

/* 900x1600 */
@media(max-width: 900px) {}

/* galaxy fold */
/* 884 x 1104 */
@media(max-width: 884px) { 
 
.statistics-item:hover { 
  margin-top: 0;
}
}

/* ipad pro 2022 */
/*834-1136*/
@media(max-width: 834px) {
    .office-staff-pendency-about .user-agent-wrapper {
        min-width: initial !important;
        width: 100% !important;
    }

    .pendency-costumer-container, .pendency-deadline-container {
        min-width: initial !important;
        width: 48% !important;
    }

    .pendency-deadline-container {
        justify-content: flex-end;
    }
}

/* ipad air 4 */
/* 820x1180 */
@media(max-width: 820px) {}

/* ipad 2020 */
/* 810x1080 */
@media(max-width: 810px) {}

/* galaxy tab 7 */
/* 800x1280 */
@media(max-width: 800px) {

    .statistics-list,
    .notes-list {
        width: 100%;
    }

    .statistics-img {
        width: 60px;
        height: 60px;
        top: -30px;
    }

    .statistics-content {
        h2 {
            font-size: 26px;

            br {
                display: none;
            }
        }
    }

    .statistics-content h1 {
        font-size: 46px;
    }

    .statistics-badge {
        padding: 8px 10px;

        p {
            font-size: 14px;
        }
    }

    //notes
    .notes-title {
        top: -30px;
    }

    .notes-title-img {
        width: 60px;
        height: 60px;
    }

    .notes-title h2 {
        font-size: 22px;
    }

    .sales-pendency-creator p,
    .pendency-receiver p,
    .policy-creator p {
        font-size: 16px;
    }

    .name-img p {
        font-size: 12px;
    }

}

/*778-304*/
@media(max-width: 778px) {}

/*775*/
@media(max-width: 775px) {}

/* ipad air/mini */
/* 768x1024 */
@media(max-width: 768px) {
    .user-agent-wrapper{
        flex-wrap: wrap;
    }

    .customer-container {
        left: 18%
    }

    .pendency-date-wrapper p {
        font-size: 12px;
    }
}

/*712-1138*/
@media(max-width:712px) {}

/* ipad air 2/3 */
/* 694-768 */
@media(max-width:694px) {
    .pendency-content-actions {
        flex-wrap: wrap;
    }

    .sales-pendency-creator,
    .pendency-receiver {
        width: 100%;
    }

    .pendency-content-actions .pendency-receiver {
        margin-bottom: 10px;
    }

    .pendency-content-actions .action-btns {
        margin-left: auto;
    }
}

/* ipad pro 1/2 */
/* 678-1024 */
@media(max-width:678px) { 
}

/* 600x962 */
@media(max-width:600px) {
 
}

/* 577-951 */
@media(max-width:577px) {}

/* 540-960 */
@media(max-width:540px) {}

/* mobiles */

/* s-note */
/*480-853*/
@media (max-width:480px) {
    .statistics-content h2 {
        font-size: 24px;
    }

    .notes-title h2 {
        font-size: 20px;
    }

    .pendency-date p {
        font-size: 12px;
    }

}

/* ipad air 2/3 */
/*438-1024*/
@media(max-width:438px) {
    .office-staff-pendency-about {
        gap: 20px;
    }

    .user-agent-wrapper{
        gap: 15px;
    }

    .sales-pendency-about{
        padding: 0;
    }

    .pendency-costumer-container, .pendency-deadline-container {
        width: 100% !important;
    }

    .pendency-deadline-container {
        justify-content: initial;
    }

    
    .office-staff-pendency-about .user-actions{
        width: 100%;
        justify-content: space-between;
    }
}

/* ip14 pro max */
/* 430x932 */
@media(max-width:430px) {
    .policy-about .sales-pendency-date-wrapper {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
    }

    .policy-category {
        margin: 10px 0;
    }

    .sales-pendency-date-wrapper .icon {
        margin-left: auto;
    }

    .pendency-date p {
        font-size: 14px;
    }

    .statistics-content h2 {
        font-size: 22px;
    }


}

/* ip13 pro max */
/* 428-926 */
@media (max-width:428px) {}

/* ip11 pro max */
/* 414-896/736*/
@media (max-width:414px) {
    .statistics-img {
        width: 55px;
        height: 55px;
        top: -26px;
    }

    .statistics-content h1 {
        font-size: 44px;
    }

    .statistics-badge p {
        font-size: 12px;
    }

    .notes-title {
        top: -27px;

        h2 {
            font-size: 18px;
        }
    }

    .notes-title-img {
        width: 55px;
        height: 55px;
    }

}

/* s21 ultra, pix6 */
/* 412-915*/
/* heights: 732, 824, 846, 869, 892, 906, 915, 919 */
@media (max-width:412px) {}

/* ip14 pro, pix5 */
/*393 x 852*/
@media(max-width:393px) {}

/* ip13 pro */
/*390 x 844*/
@media(max-width:390px) {
    .date-actions-wrapper { 
        padding: 8px 5px;
    }
}

/* s21 plux */
/*384-854*/
@media(max-width:384px) {
    .pendency-deadline-container p {
        font-size: 12px;
    }

    .statistics-content h2 {
        font-size: 18px;
    }

    .statistics-content h1 {
        font-size: 38px;
    }

    .notes-title {
        top: -24px;
    }

    .notes-title h2 {
        font-size: 16px;
    }

    .notes-title-img {
        width: 50px;
        height: 50px;
    }

    .user-name, 
    .agent-name, 
    .customer-name {
        font-size: 12px;
    }

    .sales-pendency-creator p,
    .pendency-receiver p,
    .policy-creator p {
        font-size: 14px;
    }

    .pendency-date p {
        font-size: 12px;
    }

    .pendency-content p {
        font-size: 12px;
    }

    .name-img {
        width: 30px;
        height: 30px;

        p {
            font-size: 10px;
        }
    }

    .icon {
        height: 22px;
        width: 22px;
    }
}

/* ip X */
/*375-812/667*/
@media(max-width:375px) {}

/* s22 ultra */
/*360x772*/
@media(max-width:360px) {
    .date-actions-wrapper{
        position:relative;
    }
    .pendency-content {
        padding-top: 50px;
    }
}

/* ip SE */
/*320x568*/
@media(max-width:320px) {
    .statistics-content h2 {
        font-size: 16px;
    }

    .statistics-content h1 {
        font-size: 34px;
    }

    .notes-title {
        top: -22px;
    }

    .notes-title h2 {
        font-size: 14px;
    }

    .notes-title-img {
        width: 45px;
        height: 45px;
    }

    .sales-pendency-creator p,
    .pendency-receiver p,
    .policy-creator p {
        font-size: 14px;
    }

    .pendency-date p {
        font-size: 12px;
    }

    .pendency-content p {
        font-size: 12px;
    }

    .name-img {
        width: 30px;
        height: 30px;

        p {
            font-size: 10px;
        }
    }

    .icon {
        height: 22px;
        width: 22px;
    }
}
</style>
