import moment from "moment";
import statics from "../statics";

export const parseBirthday = (birthDate) =>
  birthDate ? moment().diff(birthDate, "years") : "";

export const parseCustomerName = (customer) => {
  let customerName = "";
  if (customer.member_type.id === statics.memberTypes.company) {
    customerName = customer.company_name || "-";
  } else if (customer.member_type.id === statics.memberTypes.private) {
    customerName = `${customer.first_name || ""} ${customer.last_name || ""}`;
  }
  return customerName;
};

export const parseInitials = (firstName, lastName) =>
  `${firstName ? firstName.charAt(0) : ""}${
    lastName ? lastName.charAt(0) : ""
  }`.toUpperCase();
