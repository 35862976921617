<template>
  <div
    class="model"
    ref="model"
    :class="[
      { 'animate__animated animate__zoomOutLeft': isDeleting },
      { 'model--deleting': wasDeleted },
    ]"
    :style="{ maxHeight: `${modelHeight}px` }"
  >
    <div class="model__name">
      <span @click.self="showModelNamePopup = true">
        <transition name="fade" mode="in-out">
          <PopupEditer
            v-if="showModelNamePopup"
            @togglePopup="showModelNamePopup = $event"
            :selected="model.name"
            @submit="updateName($event)"
          /> </transition
        >{{ model.name }}</span
      >
      <div class="model__delete">
        <div class="icon icon__delete" @click="deleteModel()"></div>
      </div>
    </div>
    <div class="model__categories">
      <ModelCategory
        v-for="category in model.model_categories"
        :key="category.id"
        :category="category"
        :getModels="getModels"
      />
    </div>
  </div>
</template>

<script>
import PopupEditer from "@/partials/PopupEditer";
import ModelCategory from "@/components/Contacts/ModelCategory";
export default {
  name: "Model",

  components: {
    PopupEditer,
    ModelCategory,
  },

  props: {
    model: {
      required: true,
      type: Object,
    },

    getModels: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      isDeleting: false,
      wasDeleted: false,
      modelEl: null,
      showModelNamePopup: false,
    };
  },

  computed: {
    modelHeight() {
      return this.modelEl
        ? [...this.modelEl.children].reduce(
            (acc, child) => acc + child.clientHeight,
            0
          )
        : null;
    },
  },

  mounted() {
    this.modelEl = this.$refs.model;
  },

  methods: {
    async deleteModel() {
      this.isDeleting = true;
      await this.$store.dispatch("deleteModel", this.model.id);
      this.wasDeleted = true;
      setTimeout(async () => {
        await this.getModels();
      }, 300);
    },

    async updateName(name) {
      await this.$store.dispatch("updateModel", {
        name,
        modelId: this.model.id,
        serviceId: this.model.service_id,
        companyId: this.model.company_id,
      });
      await this.getModels();
    },
  },
};
</script>

<style scoped lang="scss">
.model,
::v-deep.category {
  color: var(--text-10);
  text-decoration: underline;
  transition: all 0.3s ease;

  &--deleting {
    overflow: hidden;
    max-height: 0 !important;
    padding: 0 !important;

    .model__name {
      padding: 0px 20px 0px 50px;
    }
  }

  &__name {
    transition: all 0.3s ease;
    padding: 10px 20px 10px 50px;
  }

  &__name {
    cursor: pointer;

    span {
      position: relative;
    }
  }

  &__name,
  &__categories .category {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__categories {
    background: var(--bg-17);

    .category {
      padding: 10px 20px 10px 80px;
      max-height: 44px;

      &__name {
        font-family: var(--muli-text-italic);
      }
    }
  }
}
</style>
