import store from "@/store";

const customerIsMember = (customer) => customer.parent_id;

const fetchGlobalCustomer = async (id) => {
  const previousSelectedCustomer = store.state.customers.customer;
  await store.dispatch("getGlobalCustomer", id);
  if (customerIsMember(previousSelectedCustomer)) {
    store.commit(
      "setCustomer",
      store.state.customers.customerMembers.find(
        (customer) => customer.id === previousSelectedCustomer.id
      )
    );
  }
};

export { fetchGlobalCustomer };
