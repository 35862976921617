<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel modal__panel--termination">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--notes"></div>
      <div class="modal__back" @click="goBack()"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form form--terminate">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">
              {{ $t(`addNoteModal.${"title"}`) }}
            </div>
          </div>
        </div>
        <div class="add__form add__form--terminate">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <div class="termination">
                <div class="termination__row">
                  <div class="termination__column">
                    <div class="form__field"> 
                      <div class="input input--default input--readonly">
                        {{ parseCustomerName(customerDetails) }} 
                      </div>
                    </div>
                    <div class="form__field">
                      <Datepicker
                        class="input input--default" 
                        :config="statics.datePickerConfig"
                        :placeholder="
                          $t(`addNoteModal.${'dateDropdown'}`)
                        "
                        v-model="deadline"
                      /> 
                    </div> 
                    <div class="form__field">
                     
                      <input class="input input--default input" v-model="title" :placeholder="
                          $t(`addNoteModal.${'subjectTitle'}`)
                        ">
                    </div> 
                  </div>
                  <div class="termination__column termination__column--middle"> 
                    <div class="form__field"> 
                      <VSelect
                        class="select select--default"
                        :options="priorities"
                        :class="{ 'select--error': priorityHasError }"
                        :reduce="(priority) => priority.id"
                        label="type"
                        :placeholder="
                          $t(`addNoteModal.${'priorityDropdown'}`)
                        "
                        v-model="priorityId"
                      />
                      <div class="form__error" v-if="priorityHasError">
                        Please select priority
                      </div>
                    </div>

                    <div class="form__field">
                      <VSelect
                        class="select select--default"
                        :class="{ 'select--error': agentHasError }"
                        :options="agents"
                        :reduce="(agent) => agent.id"
                        :getOptionLabel="
                          (agent) => `${agent.first_name} ${agent.last_name}`
                        "
                        :placeholder="
                          $t(`addNoteModal.${'beraterDropdown'}`)
                        "
                        v-model="agentId"
                      /> 
                      <div class="form__error" v-if="agentHasError">
                        Please select agent
                      </div>
                    </div>
                  </div>
                  <div class="termination__column">
                    <DropFile @input="files = $event" />
                  </div>
                </div>
                <div class="termination__row termination__textarea__row">
                  <div class="form__field form__notes__textarea">
                      <textarea
                        :placeholder="$t(`addNoteModal.${'text'}`)"
                        cols="30"
                        rows="10"
                        class="input input--default termination__textarea"
                        v-model="description"
                        style="max-width: initial !important;"
                      ></textarea>
                  </div>                  
                </div>
              </div> 
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>

      <div v-else>
        <button
          v-if="!isNoteEditing"
          class="modal__button modal__button--submit button button--default"
          @click="addNotes()"
        >
          <span>{{$t(`addNoteModal.${'saveBtn'}`)}}</span>
        </button>
        <button
          v-else
          class="modal__button modal__button--submit button button--default"
          @click="editNotes()"
        >
          <span>{{$t(`addNoteModal.${'editBtn'}`)}}</span>
        </button>
      </div> 
    </div>
  </div>
</template>

<script>
import DropFile from "@/components/DropFile";
import Loading from "@/partials/Loading";
import { parseCustomerName } from "@/helpers/entityParsers";
import { mapGetters } from "vuex";
import statics from "@/statics";
export default {
  name: "AddPotentialPolicyModal",

  components: {
    DropFile,
    Loading,
  },

  data() {
    return {
      parseCustomerName,
      formLoading: true,
      isRequesting: false,
      statics,

      policyId: "",


      customerId: "",
      deadline: "",
      priorityId : "",
      title: "",
      agentId: "", 
      description: "", 
     
       
      files: [],

      agentHasError: false,
      priorityHasError: false,


    };
  },

  watch: {
    agentId(value) {
      if (value) {
        this.agentHasError = false;
      } else {
        this.agentHasError = true;
      }
    },
    priorityId(value) {
      if (value) {
        this.priorityHasError = false;
      } else {
        this.priorityHasError = true;
      }
    },

  },
   
  computed: {
    ...mapGetters([
      "customerDetails",
      "notesForms",

      "isNoteEditing",
      "editingNoteData"
    ]),

    agents() {
      return this.notesForms.agents;
    },

    priorities() {
      return this.notesForms.priorities;
    },
  },


  async mounted(){
    if(this.isNoteEditing){ 
      this.agentId = this.editingNoteData.agent.id; 
      this.priorityId = this.editingNoteData.priority.id;
      this.title= this.editingNoteData.title;
      this.deadline = this.editingNoteData.deadline;
      this.description = this.editingNoteData.description;  
    }
  },

  async created() {
    this.formLoading = true;
    await this.$store.dispatch("getNotesForms");
 
    this.formLoading = false;
  },

  destroyed() {
    this.$store.commit("setShowAddNotesModal", false); 
    this.$store.commit("setIsNoteEditing", false);
    this.$store.commit("setEditingNoteData", null);
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddNotesModal", false);
    },

    goBack() {
      this.closeModal();
      this.$store.commit("setShowAddActivityModal", true);
    },

    validateFields(callback) {
      if (this.agentId) {
        this.agentHasError = false;
      } else {
        this.agentHasError = true;
      }
      if (this.priorityId) {
        this.priorityHasError = false;
      } else {
        this.priorityHasError = true;
      }

      if (!this.agentHasError && !this.priorityHasError) {
        callback(true);
      } else {
        callback(false);
      }
    },


    async addNotes() { 
      this.isRequesting = true;
      this.validateFields(async (isValid) => { 
        if (isValid) {  
          try {
            const noteId = await this.$store.dispatch('createNotes', { 
              agent_id: this.agentId,
              customer_id: this.customerDetails.id,
              priority_id: this.priorityId,
              title: this.title,
              deadline: this.deadline,
              description: this.description,  
            });

            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Note created successfully.",
            });
            this.closeModal();   

            if (this.files.length > 0) {  
              const files = this.files.map((file) => file.file);
              await this.$store.dispatch("addNotesFile", {
                noteId,
                files,
              });
            }
          } catch (error) {
            this.$store.commit("setToast", {
              display: true,
              type: "error",
              message: "Failed to add note: " + error,
            });
          } finally {
            this.isRequesting = false;
          }
        } else { 
          this.$store.commit("setToast", {
            display: true,
            type: "error",
            message: "Validation failed. Please fill all required fields.",
          });
          this.isRequesting = false;
        }
      });
    },

    async editNotes() { 
      this.isRequesting = true;
      this.validateFields(async (isValid) => { 
        if (isValid) {  
          try {
            await this.$store.dispatch('updateNotes', { 
              id: this.editingNoteData.id,
              agent_id: this.agentId,
              customer_id: this.customerDetails.id,
              priority_id: this.priorityId,
              title: this.title,
              deadline: this.deadline,
              description: this.description,  
            });

            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Note edited successfully.",
            });
            this.closeModal();   

            // if (this.files.length > 0) {  
            //   const files = this.files;
            //   await this.$store.dispatch("addNotesFile", {
            //     pendencyId,
            //     files,
            //   });
            // }
          } catch (error) {
            this.$store.commit("setToast", {
              display: true,
              type: "error",
              message: "Failed to edit the note: " + error,
            });
          } finally {
            this.isRequesting = false;
          }
        } else { 
          this.$store.commit("setToast", {
            display: true,
            type: "error",
            message: "Validation failed. Please fill all required fields.",
          });
          this.isRequesting = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/_termination-potential-policy.scss";

.termination__row {
  border: 0;
}

.termination__textarea__row{
  margin-top: -40px;
}

.form__notes__textarea{
  width: 66%;
}
</style>
