<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--team"></div>

      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">


        <form class="add__form">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading"/>
            <div v-else class="add__content">
              <transition name="fade" mode="out-in">
                <div class="details" key="0">


                  <div class="form__field">
                    <input
                        type="text"
                        class="input input--default"
                        placeholder="Team name *"
                        v-model="name"
                        :class="{
                        'input--error': nameHasError,
                      }"
                    />
                    <div class="form__error" v-if="nameHasError">
                      {{ nameErrorPlaceholder }}
                    </div>


                  </div>
                  <div class="form__field">
                    <VSelect
                        :options="owners"
                        :reduce="(owner) => owner.id"
                        placeholder="Owner *"
                        label="first_name"
                        v-model="owner.id"
                        class="select select--default"
                        :class="{ 'select--error': ownerHasError }"
                    />
                    <div class="form__error" v-if="ownerHasError">
                      Please select a owner
                    </div>

                  </div>

                  <div class="form__row">
                    <div class="form__field form__field--double">
                      <input
                          type="text"
                          class="input input--default"
                          placeholder="Strasse *"
                          v-model="street"
                          :class="{ 'input--error': streetHasError }"
                      />
                    </div>
                    <div
                        class="form__field form__field--double form__field--nr"
                    >
                      <input
                          type="text"
                          class="input input--default"
                          placeholder="Nr."
                          v-model="number"
                      />
                    </div>
                  </div>

                  <div class="form__row">
                    <div class="form__field form__field--double">
                      <input
                          type="text"
                          class="input input--default"
                          placeholder="PLZ *"
                          v-model="zip"
                          :class="{ 'input--error': zipHasError }"
                      />

                      <div class="form__error" v-if="zipHasError">
                        {{ zipCodePlaceholder }}
                      </div>
                    </div>
                    <div class="form__field form__field--double">
                      <VSelect
                          v-if="zipCodeData.length > 1"
                          :options="zipCodeData"
                          :reduce="(city) => city.city"
                          placeholder="ORT *"
                          label="city"
                          v-model="city"
                          class="select select--default"
                          :class="{
                          'select--error': cityHasError,
                        }"
                      />

                      <div
                          v-else-if="zipCodeData.length"
                          class="input input--default input--readonly"
                          :class="{ 'input--error': cityHasError }"
                      >
                        {{ city }}
                      </div>

                      <input
                          v-else-if="!zipCodeData.length"
                          type="text"
                          class="input input--default"
                          placeholder="ORT *"
                          v-model="city"
                          :class="{ 'input--error': cityHasError }"
                      />

                      <div class="form__error" v-if="cityHasError">
                        City should be filled
                      </div>
                    </div>
                  </div>



                  <div class="logo">
                    <div class="add__avatar">
                      <img
                          v-if="userImage"
                          :src="userImage"
                          alt=""
                          ref="userImage"
                      />
                      <div v-else class="add__avatar-empty">
                        <img
                            class="add__avatar-empty"
                            src="../assets/icons/default/Bold 2px-person-white.svg"
                            alt=""
                        />
                      </div>
                    </div>
                    <div class="add__upload-text">
                      Upload your Image / Logo
                    </div>
                    <div class="add__upload-wrapper">
                      <button
                          type="button"
                          class="button button--default button--upload"
                          @click="$refs.uploadFile.click()"
                      >
                        Upload Image
                      </button>
                      <input
                          ref="uploadFile"
                          type="file"
                          class="add__upload"
                          @change="uploadImage($event)"
                      />
                    </div>
                  </div>
                </div>

              </transition>

            </div>
          </transition>

        </form>
      </div>
      <div
          v-if="isRequesting"
          class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
          v-else
          type="button"
          class="modal__button modal__button--submit button button--default"
          @click="createTeam()"
      >
        <span>Save</span>

      </button>
    </div>
  </div>
</template>

<script>

import Loading from "@/partials/Loading";
import statics from "@/statics";
import parseFieldErrors from "@/helpers/parseFieldErrors";
import {mapGetters} from "vuex";

export default {
  name: "AddTeamModal",

  components: {
    Loading,
  },

  data() {
    return {
      statics,
      parseFieldErrors,


      name: "",
      street: "",
      number: "",
      zip: "",
      city: "",
      owner: {
        id: "",
        name: ""
      },
      file: "",
      userImage: "",
      cityHasError: false,
      addressHasError: false,
      formLoading: false,
      isRequesting: false,
      ownerHasError: false,
      nameHasError: false,
      zipHasError: false,
      specificErrors: [],
    };
  },

  watch: {
    "owner.id"(value) {
      if (value) {
        this.ownerHasError = false;
      } else {
        this.ownerHasError = true;
      }
    },
    'name'(value) {
      if (value) {
        this.nameHasError = false;
      } else {
        this.nameHasError = true;
      }
    },
    async zip(zip) {
      if (zip) {
        this.zipHasError = false;
        this.zipCodePlaceholder = "";

        if (Number(zip) >= 1000 && Number(zip) <= 9999) {
          await this.loadCities(zip);
        } else {
          this.zipCodeData = [];
          this.privateAddress[0].city = "";
          this.zipHasError = true;
          this.zipCodePlaceholder = "Invalid zip code";
        }
      } else {
        this.privateAddress[0].city = "";
        this.zipHasError = true;
        this.zipCodePlaceholder = "Please enter a zip code";
      }
    },
    city(value) {
      if (value) {
        this.cityHasError = false;
      } else {
        this.cityHasError = true;
      }
    },
    street(value) {
      if (value) {
        this.streetHasError = false;
      } else {
        this.streetHasError = true;
      }
    },
  },
  async mounted() {
    this.formLoading = true;

    await this.$store.dispatch("getTeamForms");

    if (this.teamIsEdit) {

      this.name = this.teamToEdit.name;
      this.owner.id = this.teamToEdit.owner.id;
      this.id = this.teamToEdit.id;
      this.street = this.teamToEdit.street;
      this.number = this.teamToEdit.number;
      this.zip = this.teamToEdit.zip;
      this.city = this.teamToEdit.city;
      this.userImage = this.teamToEdit.photo ? this.teamToEdit.photo.thumbnail : "";
    }

    this.formLoading = false;
  },

  computed: {
    ...mapGetters(["teamForms", "teamIsEdit", "teamToEdit"]),
    owners() {
      return this.teamForms.owners;
    },

    zipCodeData: {
      get() {
        return this.$store.state.zipCodes.zipCodeData;
      },
      set(value) {
        this.$store.commit("setZipCodeData", value);
      },
    },
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddTeamModal", false);
      this.$store.commit("setTeamIsEdit", false);
      this.zipCodeData = [];
    },
    validateForm() {
      if (!this.name) {
        this.nameHasError = true;
      }
      if (!this.owner.id) {
        this.ownerHasError = true;
      }

      if (!this.zip) {
        this.zipHasError = true;
        this.zipCodePlaceholder = "Please enter a zip code";
      }
      if (!this.city) {
        this.cityHasError = true;
      }
      if (!this.street) {
        this.streetHasError = true;
      }
    },
    async loadCities(value) {
      await this.$store.dispatch("getZipCodeData", value);
      this.zip = value;
      this.city = this.zipCodeData[0].city;
    },
    async createTeam() {
      this.validateForm();
      if (!this.nameHasError && !this.ownerHasError && !this.zipHasError && !this.cityHasError && !this.streetHasError) {
        this.isRequesting = true;


        try {
          const team = {
            owner: this.owner,
            name: this.name,
            id: this.id,
            zip: this.zip,
            street: this.street,
            city: this.city,
            number: this.number,
            file: this.file
          };
          if (this.teamIsEdit) {

            if (this.$route.name === "Teams" && this.file) {
              await this.$store.dispatch("addTeamPhoto", {
                teamId: this.teamToEdit.id,
                file: this.file,
              });
            }
            await this.$store.dispatch("updateTeam", {
              teamId: this.id,
              owner: this.owner,
              name: this.name,
              zip: this.zip,
              street: this.street,
              city: this.city,
              number: this.number,
              file: this.file
            });
            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Team updated successfully.",
            });
          } else {

            await this.$store.dispatch("createTeam", team);
            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Team added successfully.",
            });
          }
          this.closeModal();

        } catch (err) {
          console.log(err);
          const {errors} = err.response.data;
          this.specificErrors = this.parseFieldErrors(errors);
          this.isRequesting = false;
        }
      }
    },
    loadFieldsForEdit(teamToEdit) {
      const {
        name,
        owner_id,
        id,
        street,
        number,
        zip,
        city,
        photo

      } = teamToEdit;

      this.name = name || "",
          this.owner.id = owner_id || "",
          this.team.id = id || "",
          this.userImage = photo ? photo.preview : "",
          this.street = street,
          this.number = number,
          this.zip = zip,
          this.city = city,
           this.userImage = photo ? photo.thumbnail : ""
    },
    uploadImage(event) {
      this.file = event.target.files[0];
      this.userImage = URL.createObjectURL(this.file);
    },
  },

};
</script>
