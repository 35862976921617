var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"policy-accident",class:[
    ("policy-accident--" + _vm.statusColor),
    { 'animate__animated animate__zoomOutLeft': _vm.isDeleting },
    { deleting: _vm.wasDeleted } ]},[_c('div',{staticClass:"policy-accident__row"},[_c('div',{staticClass:"policy-accident__data policy-accident__data--large policy-accident__data--accident-date"},[_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.userName)+" ")]),_c('div',[_vm._v(_vm._s(_vm.formatDisplayDate(_vm.accident.date)))])]),_c('div',{staticClass:"policy-accident__contact"},[_c('a',{staticClass:"icon icon__phone",class:{ 'icon__phone--active': _vm.accident.user_id.phone },attrs:{"href":_vm.accident.user_id.phone
              ? ("tel:" + (_vm.accident.user_id.phone))
              : 'javascript:void(0)'}}),_c('a',{staticClass:"icon icon__mail",class:{ 'icon__mail--active': _vm.accident.user_id.email },attrs:{"href":_vm.accident.user_id.email
              ? ("mailto:" + (_vm.accident.user_id.email))
              : 'javascript:void(0)'}})])]),_c('div',{staticClass:"policy-accident__data policy-accident__data--large"},[_c('EditableComment',{attrs:{"comment":_vm.accident.comment,"readonly":true}})],1),_c('div',{staticClass:"policy-accident__data policy-accident__data--medium"},[_vm._v(" "+_vm._s(_vm.price)+" ")]),_c('div',{staticClass:"policy-accident__data policy-accident__data--medium"},[_c('div',{staticClass:"policy-accident__status-badge"}),_c('span',[_vm._v(_vm._s(_vm.accident.accident_status.name))])]),_c('div',{staticClass:"policy-accident__data policy-accident__data--large"},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"icon icon__pdf",class:{ 'icon__pdf--active': _vm.accident.accident_files.length },on:{"click":function($event){return _vm.openPolicyFilesModal(
            _vm.accident.id,
            _vm.accident.accident_files,
            'accidentChildren'
          )}}}),_c('div',{staticClass:"icon icon__edit",on:{"click":function($event){return _vm.openEditAccidentModal(_vm.accident)}}}),_c('div',{staticClass:"icon icon__delete",on:{"click":function($event){return _vm.removeAccident()}}}),_c('div',{staticClass:"icon"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }