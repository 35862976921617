<template>
  <div class="teams">
    <div class="page__header">
      <div class="page__title">Teams</div>
      <div class="page__right">

        <button
          v-if="$checkRolePermission('team_create')"
          class="users__button button button--default"
          @click="$store.commit('setShowAddTeamModal', true)"
        >
          Add team
        </button>
      </div>
    </div>

    <div class="table">
      <Loading v-if="teamsLoading" />
      <template v-else>
        <NoData v-if="!teams.length" :type="'teams'" />
        <template v-else>
          <div class="table__header box box--default">
            <div class="table__label table__label--small team__logo"></div>
            <div class="table__label table__label--large">
              {{ $t(`teams.${"tableHeader"}.${"name"}`) }}
            </div>
            <div class="table__label table__label--large">
              {{ $t(`teams.${"tableHeader"}.${"owner"}`) }}
            </div>

            <div
              class="table__label table__label--small"
              v-if="$checkRolePermission('team_delete')"
            ></div>
          </div>

          <div class="table__body">
            <Team
              v-for="team in teams"
              :key="team.id"
              :team="team"
              :deleteTeam="deleteTeam"
            />
          </div>
        </template>
      </template>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Team from "@/components/Teams/Team";
import Loading from "@/partials/Loading";
import NoData from "@/partials/NoData";
export default {
  name: "Teams",

  components: {
    Team,
    Loading,
    NoData,
  },

  data() {
    return { searchKeywords: "" };
  },

  computed: {
    ...mapGetters(["teamsLoading",  "teams"]),
  },

  async created() {
    this.$store.commit("setTeamsLoading", true);
    await this.$store.dispatch("getTeams");
    this.$store.commit("setTeamsLoading", false);
  },

  methods: {
    deleteTeam({ display, message, action, id }) {
      this.$store.commit("setDeleteModal", {
        display,
        message,
        action,
        id,
      });
    },


  },


};
</script>

<style scoped lang="scss">
.teams {
  height: 100%;

  &__button {
    width: 190px;
  }
  .table {
    margin-bottom: 20px;
    height: calc(100% - 40px - 20px - 20px - 30px);
   .table__label--small{
      max-width: 70px;
    }
    &--last {
      max-width: 45px;
      height: initial;
    }
   .team__logo {
     max-width: 70px;
   }
  }
  .thumbnail{

  }

}
</style>
