<template>
  <div
      class="notification"
      :class="{ __read: isRead }"
      @click="handleClick"
      :style="{ pointerEvents: isLoading ? 'none' : 'auto' }"
  >
    <div
        v-if="!isRead"
        class="notification__close"
        @click.stop="readNotification(notification.id)"
    ></div>
    <div class="notification__type"
         :class="'notification__type--'+type()"
    ></div>
    <div class="user">
      <div class="user__avatar"></div>
      <div class="user__name">

      </div>
    </div>
    <div class="notification__text">
      {{ notification.data.message }}
    </div>

    <div class="deadline" v-if="type() == 'pendency'">
      <div class="notification-date">
        <div class="icon icon__date"></div>
        <p>Deadline: {{ deadline }}</p>
      </div>
    </div>


    <div class="notification-date-time " >
      <div class="notification-date">
        <div class="icon icon__date"></div>
        <p>{{ date }}</p>
      </div>

      <div class="notification-time">
        <div class="icon icon__time"></div>
        <p>{{ time }}</p>
      </div>
    </div>

  </div>

</template>

<script>
import moment from "moment";
import statics from "@/statics";

function debounce(func, wait) {
  let timeout;
  return function(...args) {
    const later = () => {
      clearTimeout(timeout);
      func.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default {
  name: "Notification",
  data() {
    return {
      isLoading: false,  // Track loading state
    };
  },
  computed: {
    deadline() {
      if (this.notification.data.pendency) {
        return this.notification.data.pendency.deadline
            ? moment(this.notification.data.pendency.deadline).format("DD.MM.YYYY")
            : statics.empty;
      }
      return statics.empty;
    },
    date() {
      return this.notification.created_at
          ? moment(this.notification.created_at).format("DD.MM.YYYY")
          : statics.empty;
    },
    time() {
      return this.notification.created_at
          ? moment(this.notification.created_at).format("hh:mm")
          : statics.empty;
    },
    isRead() {
      return this.notification.read_at != null;
    },
  },
  methods: {
    type() {
      if (this.notification.type === "App\\Notifications\\CustomerBirthday") {
        return "birthday";
      } else if (this.notification.type === "App\\Notifications\\PendencyNotification") {
        return "pendency";
      } else {
        return "customer";
      }
    },

    async readNotification() {
      if (!this.isRead && !this.isLoading) {
        this.isLoading = true;
        await this.$store.dispatch("readNotification", this.notification.id);
        await this.$store.dispatch("getNotifications");
        this.isLoading = false;
      }
    },

    async notificationClicked() {
      if (this.isLoading) return;

      const currentRoute = this.$route;

      let targetEntity = null;


      if (this.notification.data.customer) {
        targetEntity = this.notification.data.customer;
      } else if (this.notification.data.pendency && this.notification.data.pendency.customer) {
        targetEntity = this.notification.data.pendency.customer;
      }


      if (!targetEntity) {

        return;
      }

      console.log("Pre if")
      if (currentRoute.name === "AccountDetails" && currentRoute.params.id === targetEntity.id) {
        this.$router.go();
      } else {


        const routeParams = {
          name: "AccountDetails",
          params: {
            entity: "customer",
            id: targetEntity.id,
          },
          query: { tab: 0},
        };

        if (this.type() === "pendency") {
          routeParams.query.tab = 4;
        }

        try {

          await this.$router.push(routeParams);
        } catch (error) {
          console.error("Failed to navigate:", error);
        }
      }
      this.toggleNotification(false);
    },


    handleClick: debounce(function () {
      if (!this.isRead) this.readNotification();
      this.notificationClicked();
    }, 300),
  },
  props: {
    notification: {
      required: true,
      type: Object,
    },
    toggleNotification: {
      required: true,
      type: Function,
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  padding: 50px 0px 60px 20px;
  background: var(--bg-16);
  border-radius: var(--radius-1);
  position: relative;
  margin-bottom: 30px;
  pointer-events: auto; /* Ensure it's interactive */
  &.__loading {
    pointer-events: none; /* Disable during loading */
    opacity: 0.5; /* Visual feedback (optional) */
  }
  &.__read{
    background: #fff;
    border: 1px solid #F2F4F6;
  }
  &:last-child {
    margin-bottom: 0;

  }
  &:hover{
    box-shadow: 0px 10px 15px #0000001A;
    cursor: pointer;
  }
  .user {
    top: -27px;
    left: 50%;
    transform: translateX(-50%);

    &__avatar {
      height: 46px;
      width: 46px;
      background: black;
      margin: 3px auto;
      border: 2px solid var(--border-16);
    }

  }

  &__close {
    top: -8px;
    right: -8px;
    height: 24px;
    width: 24px;
    background: url("../assets/icons/default/close_hover_icon.svg");
    cursor: pointer;
  }

  &__type {
    height: 34px;
    width: 34px;
    background-color: var(--bg-1);
    box-shadow: 0px 8px 10px var(--shadow-6);
    left: 15px;
    top: -17px;

    &--customer {
      background-image: url("../assets/icons/default/users_icon.svg");
    }

    &--contact {
      background-image: url("../assets/icons/default/contact_icon_left_menu.svg");
    }

    &--potential {
      background-image: url("../assets/icons/default/potentialcustomer_icon.svg");
    }

    &--user {
      background-image: url("../assets/icons/default/customers_icon.svg");
    }
    &--birthday {
      background-image: url("../assets/icons/default/cake_icon.svg");
    }

    &--pendency {
      background-image: url("../assets/icons/default/modal_pendencies_icon_colored.svg");
    }

    &--customer,
    &--contact,
    &--potential,
    &--user,
    &--birthday,
    &--pendency{
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;
    }

  }

  &__text {
    color: var(--text-9);
    line-height: 18px;
  }

  &__date {
    right: 13px;
    bottom: 6px;
    font-size: var(--x-smaller);
  }

  &__type,
  .user__avatar {
    border-radius: 50%
  }

  .user__avatar {
    background-image: url("../assets/icons/default/notification_icon_new.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #22DB8E;
  }
  &__date,
  .user__name {
    color: var(--text-2);
    font-family: var(--muli-text-semi-bold);
  }

  .user__name,
  &__text {
    font-size: var(--x-small);
  }

  .user,
  &__date,
  &__close,
  &__type {
    position: absolute;
  }
}

.deadline{
  position: absolute;
  bottom: 10px;
  left: 14px;
}

.notification-date-time{
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 14px;
  bottom: 10px;
  gap: 14px;
}

.notification-date, .notification-time{
  padding: 3px 13px;
  padding-left: 6px;
  padding-bottom: 1px;
  box-shadow: 0px 8px 10px #00000026;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
}

.notification-date .icon, .notification-time .icon{
  transform: scale(0.7);
  margin-bottom: 2px;
}

.notification-date p,
.notification-time p{
  color: var(--text-2);
  font-family: var(--muli-text-semi-bold);
  font-size: var(--x-smaller);
}
</style>
