<template>
  <div class="accidents">
    <div class="table">
      <div class="table__header box box--default">
        <div class="table__label table__label--medium table__label--fixed">
          Status
        </div>
        <div class="table__label table__label--medium">Policennummer</div>
        <div class="table__label table__label--large">Company</div>
        <div class="table__label table__label--medium">Branche</div>
        <div class="table__label table__label--medium">Schaden vom</div>
        <div class="table__label table__label--medium">Schaden bis</div>
        <div class="table__label table__label--medium">Summe</div>
        <div class="table__label table__label--medium">Schaden melden</div>
        <div
          class="table__label table__label--medium table__label--more-horizontal table__label--last"
        ></div>
      </div>

      <div class="table__body">
        <Loading v-if="detailsLoading" />
        <template v-else>
          <AccidentsPolicy
            v-for="policy in policies"
            :key="policy.id"
            :policy="policy"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AccidentsPolicy from "@/components/AccountDetails/AccidentsPolicy";
import Loading from "@/partials/Loading";
export default {
  name: "Accidents",

  components: {
    AccidentsPolicy,
    Loading,
  },

  props: {
    policies: {
      required: true,
      type: Array,
    },

    detailsLoading: {
      required: true,
      type: Boolean,
    },
  },
};
</script>