<template>
  <div
    class="policy"
    :class="[
      { 'animate__animated animate__zoomOutLeft': isDeleting },
      { deleting: wasDeleted },
      `policy--${policyTypeColor}`,
    ]"
  >
    <div class="row row--policy box box--default">
      <div class="row__data row__data--small">
        {{ date }}
      </div>
      <div class="row__data row__data--medium">{{ policy.category.code }}</div>
      <div class="row__data row__data--large">
        <EditableComment
          :comment="policy.text"
          :updateComment="updatePotentialPolicyText"
        />
      </div>
      <div
        class="row__data row__data--small row__data--pdf icon__pdf"
        :class="{ 'icon__pdf--active': policy.files.length }"
        @click="openPolicyFilesModal()"
      ></div>
      <div class="row__data row__data--medium row__data--underline">
        <span @click="showEditablePopup = true">
          <transition name="fade">
            <PopupEditer
              v-if="showEditablePopup"
              @togglePopup="showEditablePopup = $event"
              :items="agents"
              :selected="agent"
              :label="'first_name'"
              @submit="changePolicyAgent($event)"
            />
          </transition>
          {{ name }}
        </span>
      </div>
      <div
        class="row__data row__data--small row__data--contract icon__contract"
        @click="convertPotentialToPolicy()"
      ></div>
      <div
        class="row__data row__data--small row__data--edit icon__edit"
        @click="openPotentialPolicyModal()"
      ></div>
      <div
        class="row__data row__data--small row__data--delete icon__delete"
        @click="deletePotentialPolicy()"
      ></div>
    </div>
  </div>
</template>

<script>
import { formatDisplayDate } from "@/helpers/dateFormatters";
import statics from "@/statics";
import PopupEditer from "@/partials/PopupEditer";
import { mapGetters } from "vuex";
import EditableComment from "@/partials/EditableComment";
import { fetchGlobalCustomer } from "@/helpers/accountDetailHelpers";
export default {
  name: "PotentialPolicy",

  components: {
    PopupEditer,
    EditableComment,
  },

  props: {
    policy: {
      required: true,
      type: Object,
    },

    index: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      fetchGlobalCustomer,
      formatDisplayDate,
      statics,
      isDeleting: false,
      wasDeleted: false,
      showEditablePopup: false,
    };
  },

  computed: {
    ...mapGetters(["policyForms"]),
    date() {
      return this.policy.date
        ? this.formatDisplayDate(this.policy.date)
        : this.statics.empty;
    },

    policyTypeColor() {
      return this.policy.priority ? `type-${this.policy.priority.type}` : null;
    },

    agents() {
      return this.policyForms.agents;
    },

    agent() {
      return this.policy.agent
        ? this.policy.agent.first_name || statics.empty
        : statics.empty;
    },

    name() {
      return this.policy.agent
        ? `${this.policy.agent.first_name} ${this.policy.agent.last_name}`
        : statics.empty;
    },
  },

  methods: {
    async deletePotentialPolicy() {
      this.isDeleting = true;
      await this.$store.dispatch("deletePotentialPolicy", this.policy.id);
      this.wasDeleted = true;
      setTimeout(async () => {
        await this.fetchGlobalCustomer(this.$route.params.id);
      }, 300);
    },

    async changePolicyAgent(agentId) {
      await this.$store.dispatch("updatePotentialPolicyAgent", {
        potentialPolicyId: this.policy.id,
        agentId,
      });
      await this.fetchGlobalCustomer(this.$route.params.id);
    },

    async updatePotentialPolicyText(text) {
      await this.$store.dispatch("updatePotentialPolicyText", {
        potentialPolicyId: this.policy.id,
        text,
      });
      await this.fetchGlobalCustomer(this.$route.params.id);
    },

    openPolicyFilesModal() {
      this.$store.commit("setPolicyFileId", this.policy.id);
      this.$store.commit("setPolicyFiles", this.policy.files);
      this.$store.commit("setPolicyFilesModalIsPotential", true);
      this.$store.commit("setShowPolicyFilesModal", true);
    },

    convertPotentialToPolicy() {
      this.$store.commit("setPolicyIsConverting", true);
      this.$store.commit("setConvertingPolicyData", {
        id: this.policy.id,
        agentId: this.policy.agent.id,
        text: this.policy.text,
      });
      this.$store.commit("setShowAddPolicyModal", true);
    },

    openPotentialPolicyModal() {
      this.$store.commit("setPotentialPolicyToEdit", this.policy);
      this.$store.commit("setShowAddPotentialPolicyModal", true);
    },
  },
};
</script>
