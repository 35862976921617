<template>
  <div id="app">
    <div class="wrapper">
      <transition
        mode="out-in"
      >
        <router-view></router-view>
      </transition>
      <Toast />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",

  data() {
    return {
      isLock: false,
    };
  },

  computed: {
    ...mapGetters(["language"]),
  },

  mounted() {
    this.setInitialLanguage();

    document.addEventListener("keydown", (event) => {
      if (
        event.key.toLowerCase() === "l" &&
        event.ctrlKey &&
        this.$route.name !== "Lock"
      ) {
        event.preventDefault();
        this.$router.push({
          name: "Lock",
        });
      }
    });
  },

  methods: {
    setInitialLanguage() {
      if (!window.localStorage.getItem("language")) {
        window.localStorage.setItem(
          "language",
          process.env.VUE_APP_I18N_LOCALE
        );
      }
      this.$store.commit(
        "setLanguage",
        window.localStorage.getItem("language")
      );
      this.$root.$i18n.locale = this.language;
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  overflow-y: hidden;
}
</style>
