<template>
  <div class="service">
    <div class="termination__row">
      <div class="form__field">
        <VSelect
          class="select select--default select--shadow"
          :class="{ 'select--error': service.companyHasError }"
          :options="companies"
          :reduce="(company) => company.id"
          label="name"
          placeholder="Firma"
          @input="
            updateSpecificTerminationServiceField(index, 'companyId', $event)
          "
          v-model="terminationService.companyId"
        />

        <div class="form__error" v-if="service.companyHasError">
          Please select a company
        </div>
      </div>
      <div class="form__field">
        <div
          v-if="!services.length"
          class="select select--default select--readonly"
          :class="{ 'select--error': service.serviceHasError }"
        >
          Services
        </div>
        <VSelect
          v-else
          :reduce="(service) => service.id"
          class="select select--default select--shadow"
          :class="{ 'select--error': service.serviceHasError }"
          :options="services"
          :label="`long_${language}`"
          placeholder="Services"
          @input="
            updateSpecificTerminationServiceField(index, 'serviceId', $event)
          "
          v-model="terminationService.serviceId"
        />

        <div class="form__error" v-if="service.serviceHasError">
          Please select a service
        </div>
      </div>
      <div class="form__field">
        <Datepicker
          class="input input--default input--shadow"
          placeholder="Termination Date"
          :class="{ 'select--error': service.dateHasError }"
          :config="statics.datePickerConfig"
          @input="
            updateSpecificTerminationServiceField(
              index,
              'terminationDate',
              $event
            )
          "
          v-model="terminationService.terminationDate"
        />
        <div class="form__error" v-if="service.dateHasError">
          Please select a date
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import statics from "@/statics";
import { formatDisplayDate } from "@/helpers/dateFormatters";
import { mapGetters } from "vuex";
export default {
  name: "Service",

  props: {
    service: {
      required: true,
      type: Object,
    },

    companies: {
      required: true,
      type: Array,
    },

    index: {
      required: true,
      type: Number,
    },

    updateSpecificTerminationServiceField: {
      required: true,
      type: Function,
    },
  },

  watch: {
    async "terminationService.companyId"(companyId) {
      try {
        this.services = await this.$store.dispatch(
          "getCompanyServices",
          companyId
        );
      } catch (err) {
        console.error(err);
      }
    },
  },

  data() {
    return {
      formatDisplayDate,
      statics,
      services: [],

      terminationService: {
        companyId: "",
        terminationDate: "",
        serviceId: "",
      },
    };
  },

  computed: {
    ...mapGetters(["language"]),
  },
};
</script>

<style lang="scss" scoped>
.form__field {
  flex: 1;
  margin: 20px 10px 20px 0;

  &:last-child {
    margin-right: 0;
  }
}
</style>
