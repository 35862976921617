import api from "@/api";

export default {
  state: {
      teamTaxes: []
  },
  getters: {
      teamTaxes: (state) => state.teamTaxes,

  },
  mutations: {
    setTeamTaxes: (state, data) => {
      state.teamTaxes = data;
    },

  },
  actions: {
    getTeamTaxes: async ({ commit }, id) => {
      try {
        const response = await api({ requiresAuth: true }).get(`/v1/team-taxes/${id}`);
        const { data } = response.data;
        commit("setTeamTaxes", data);
      } catch (err) {
        console.error(err);
      }
    },
    updateTax: (
        { commit, dispatch},
        {
          id: id,
          rate,
          is_custom,
          is_fixed,
          team_id,
          group_name,
          name
        }
    ) =>
        new Promise((resolve, reject) => {
          api({ requiresAuth: true })
              .patch("/v1/taxes/" + id, {
                rate: rate,
                is_custom: is_custom,
                is_fixed: is_fixed,
                group_name: group_name,
                team_id: team_id,
                name: name
              })
              .then(
                  async (response) => {
                      const { data } = response.data;
                      commit("setTeamsLoading", true);
                      await dispatch("getTeamTaxes", data.team.id);
                      resolve(data);
                      commit("setTeamsLoading", false);
                  },
                  (err) => {
                    commit("setTeamTaxesLoading", false);
                    reject(err);
                  }
              );
        }),


    addTax: (
          { commit, dispatch},
          { 
            rate,
            is_custom,
            is_fixed,
            name,
            group_name,
            team_id,
          }
      ) =>
          new Promise((resolve, reject) => {
            api({ requiresAuth: true })
                .post("/v1/taxes", {
                  rate: rate,
                  is_custom: is_custom,
                  is_fixed: is_fixed,
                  name: name,
                  group_name: group_name,
                  team_id: team_id,
                })
                .then(
                    async (response) => {
                        const { data } = response.data;
                        commit("setTeamsLoading", true);
                        await dispatch("getTeamTaxes", data.team.id);
                        resolve(data);
                        commit("setTeamsLoading", false);
                    },
                    (err) => {
                      commit("setTeamTaxesLoading", false);
                      reject(err);
                    }
                );
          }),
  },


};
