<template>
  <div class="map" ref="map"></div>
</template>

<script>
import L from "leaflet";
import axios from "axios";
export default {
  name: "Map",

  props: {
    lan: {
      required: false,
      type: [Number, String],
    },

    lat: {
      required: false,
      type: [Number, String],
    },

    address: {
      required: false,
      type: String,
    },
  },

  data() {
    return {
      map: null,
    };
  },

  watch: {
    address() {
      this.initializeMap();
    },
  },

  mounted() {
    this.initializeMap();
  },

  methods: {
    async initializeMap() {
      if (this.map) {
        this.map.remove();
        this.map.off();
        this.map = null;
      }

      let lat, lan;
      if (this.lat && this.lan) {
        lat = this.lat;
        lan = this.lan;
      } else {
        const [city, zip, address, number] = this.address.split(" ");
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?format=json&q=${city}+${zip}+${address}+${number}`
        );
        const [info] = response.data;
        lat = info.lat;
        lan = info.lon;
      }
      this.map = L.map(this.$refs.map, {
        center: [lat, lan],
        zoom: 13,
      });
      const icon = L.icon({
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        iconSize: [25, 35],
      });
      L.marker([lat, lan], { icon }).addTo(this.map);
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmxvcmlpaWFubiIsImEiOiJja3JhcWI2ejcweXp1MnpwOHYwcXJsbGkzIn0.rvoKS0zxZSRcOEZkfNn4iQ",
        {
          maxZoom: 18,
          id: "mapbox/streets-v11",
          tileSize: 512,
          zoomOffset: -1,
          accessToken:
            "pk.eyJ1IjoiZmxvcmlpaWFubiIsImEiOiJja3JhcWI2ejcweXp1MnpwOHYwcXJsbGkzIn0.rvoKS0zxZSRcOEZkfNn4iQ",
        }
      ).addTo(this.map);
      this.map.invalidateSize();
    },
  },
};
</script>

<style scoped lang="scss">
.map {
  z-index: 1;
  border-radius: var(--radius-1);
  width: 100%;
  height: 100%;
}
</style>
