<template>
  <div class="rights">
    <div class="page__header">
      <div class="page__title">user rights</div>
      <div class="buttons">
        <button class="button button--default">Add New</button>
        <div class="dropdown">
          <div class="dropdown__container">
            <div
              class="dropdown__item"
              @click="$store.commit('setShowAddRoleModal', true)"
            >
              Create Role
            </div>
            <div
              class="dropdown__item"
              @click="$store.commit('setShowAddPermissionModal', true)"
            >
              Create Premission
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rights__container">
      <div class="roles box box--default">
        <div class="label label--gradient">Choose Role</div>
        <Loading v-if="rolesLoading" />
        <div v-else class="roles__body">
          <div
            class="roles__role"
            :class="{ 'roles__role--selected': selectedRole.id === role.id }"
            v-for="role in roles"
            :key="role.id"
            @click="selectRole(role)"
          >
            {{ role.title }}
          </div>
        </div>
      </div>
      <div class="permissions box box--default">
        <Loading v-if="rolesLoading" />

        <template v-else>
          <div class="label label--gradient">{{ selectedRole.title }}</div>
          <div class="page__header delete-role-btn-wrapper" style=""> 
              <div class="icon icon__delete delete-role-btn" @click="deleteReportModal(selectedRole.id)"></div> 
          </div>
          <Loading v-if="permissionsLoading" />
          
          <div
            class="permissions__body"
            :class="{ 'permissions__body--stretch': !showButtons }"
            ref="permissions"
          >
            <template v-if="!permissionsLoading">
              <div
                class="permissions__permission"
                v-for="permission in permissions"
                :key="permission.id"
              >
                <span>{{ permission.title }}</span>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    :id="permission.title + permission.id"
                    class="checkbox__checkbox"
                    :checked="permissionIds.includes(permission.id)"
                    @input="togglePermissions($event.target, permission.id)"
                  />
                  <label
                    :for="permission.title + permission.id"
                    class="checkbox__label"
                  ></label>
                </div>
              </div>
            </template>
          </div>
          <div class="permissions__buttons" v-if="showButtons">
            <button
              class="button button--default button--shadow"
              @click="applyPermissions()"
            >
              Save
            </button>
            <button class="button button--cancel" @click="resetPermissions()">
              Cancel
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/partials/Loading";
import api from "@/api";
export default {
  name: "UserRights",

  components: {
    Loading,
  },

  data() {
    return {
      rolesLoading: false,
      permissionsLoading: false,

      selectedRole: {},
      permissionIds: [],
      permissionIdsCopy: [],

      showButtons: false,
    };
  },

  watch: {
    permissionIds(permissions) {
      this.showButtons =
        this.permissionIdsCopy.some(
          (permission) => !permissions.includes(permission)
        ) || permissions.length > this.permissionIdsCopy.length;
    },
  },

  computed: {
    ...mapGetters(["roles", "role", "permissions", "selectedPermissions"]),
  },

  async mounted() {
    await this.getRoles();
    await this.getPermissions();
  },

  methods: {
    async getRoles() {
      this.rolesLoading = true;
      await this.$store.dispatch("getRoles");
      const [firstRole] = this.roles;
      this.selectedRole = firstRole;
      this.loadPermissionIds();
      this.rolesLoading = false;
    },

    async getPermissions() {
      this.permissionsLoading = true;
      await this.$store.dispatch("getPermissions");
      this.permissionsLoading = false;
    },

    togglePermissions({ checked }, permissionId) {
      const permissionsIndex = this.permissionIds.findIndex(
        (id) => id === permissionId
      );
      if (checked && permissionsIndex === -1) {
        this.permissionIds = [...this.permissionIds, permissionId];
      } else {
        this.permissionIds.splice(permissionsIndex, 1);
      }
    },

    selectRole(role) {
      this.selectedRole = role;
      this.loadPermissionIds();
      this.$refs.permissions.scrollTo(0, 0);
    },

    deleteReportModal(id) {
      this.$store.commit("setDeleteModal", {
        display: true,
        message: '',
        action: () => this.deleteRole(id),
        id: id,
      });
    },

    async deleteRole(id){
      try {
        await api({requiresAuth: true}).delete(`/v1/roles/${id}`);
        this.getRoles();
      } catch (err) {
        console.error(err);
      }
    },

    resetPermissions() {
      this.loadPermissionIds();
    },

    loadPermissionIds() {
      this.permissionIds = this.selectedRole.permissions.map(
        (permission) => permission.id
      );
      this.permissionIdsCopy = [...this.permissionIds];
    },

    async applyPermissions() {
      try {
        const { data } = await this.$store.dispatch("updateRole", {
          id: this.selectedRole.id,
          permissions: this.permissionIds,
        });
        const { data: role } = data;
        await this.$store.dispatch("getRoles");
        this.selectedRole = role;
        this.loadPermissionIds();
        await this.$store.dispatch(
          "updateLoggedUser",
          JSON.parse(localStorage.getItem("user")).id
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.delete-role-btn-wrapper{
  justify-content: flex-end
}
.delete-role-btn{
  margin-top: 20px;
  margin-right: 20px;
}

.rights {
  height: 100%;

  .page__header {
    .buttons {
      width: 180px;
      position: relative;
      cursor: pointer;

      .button {
        width: 100%;
      }

      .dropdown {
        position: absolute;
        width: 100%;
        display: none;
        padding: 5px 0;
        &__container {
          position: absolute;
          z-index: 3;
          width: 100%;
          background: var(--bg-2);
          border-radius: var(--radius-1);
          padding: 10px 0;
          top: calc(100% - 5px);

          &::after {
            content: "";
            position: absolute;
            top: -4px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 4px solid var(--border-2);
          }
        }

        &__item {
          padding: 10px 12px;
          color: var(--text-1);
          font-size: var(--medium);
          font-family: var(--muli-text-semi-bold);

          &:hover {
            background: var(--bg-19);
          }
        }
      }

      &:hover {
        .button {
          background-color: var(--hover-1);
          box-shadow: 0px -7px 18px var(--shadow-2);
        }
        .dropdown {
          display: block;
        }
      }
    }
  }

  &__container {
    height: calc(100% - 48px - 20px);
    display: flex;
  }

  .roles,
  .permissions {
    height: 100%;
    background: var(--bg-1);
    position: relative;
  }

  .roles__role,
  .permissions__permission {
    color: var(--text-9);
    font-size: var(--medium);
    padding: 10px 20px;
    cursor: pointer;

    &:hover,
    &--selected {
      background: var(--bg-17);
    } 
  }

  .roles,
  .permissions {
    &__body {
      margin-top: 65px;
      padding-top: 20px;
      overflow: auto;
    }
  }

  .roles {
    width: 400px;
    margin-right: 20px;

    &__body {
      height: calc(100% - 65px);
    }
  }

  .permissions {
    flex: 1;

    &__body {
      height: calc(100% - 65px - 88px);

      &--stretch {
        height: calc(100% - 65px);
      }
    }

    &__permission {
      border-bottom: 1px solid var(--border-15);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      padding: 20px 0;

      .button {
        height: 40px;
        width: 140px;

        &:first-child {
          margin-right: 80px;
        }
      }
    }
  }
}
</style>
