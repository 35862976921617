<template>
  <div class="policy-wrapper">
    <div
      class="policy"
      :class="[
        `policy--${policyStatusColor}`,
        { 'policy--expanded': expanded },
      ]"
    >
      <div
        class="row row--policy box box--default"
        :class="{ 'row--active': expanded }"
      >
        <div
          class="row__data row__data--medium row__data--underline row__data--fixed"
        >
          <span @click="showStatusPopup = true">
            <transition name="fade" mode="in-out">
              <PopupEditer
                v-if="showStatusPopup"
                @togglePopup="showStatusPopup = $event"
                :items="policyStatuses"
                :selected="policyStatus"
                :label="'name'"
                @submit="updatePolicyStatus($event)"
              />
            </transition>
            {{ policyStatus }}
          </span>
        </div>
        <div class="row__data row__data--medium">{{ policyNumber }}</div>
        <div class="row__data row__data--medium">{{ createdAt }}</div>
        <div class="row__data row__data--medium policy__company">
          <span>{{ company }}</span>
        </div>
        <div class="row__data row__data--large">
          {{ branch }}
        </div>
        <div class="row__data row__data--medium">
          {{ premiumInterval }}
        </div>
        <div
          class="row__data row__data--medium row__data--add-accident icon__add--active"
          @click="showInvoiceModal()"
        ></div>
        <div
          class="row__data row__data--medium row__data--half row__data--last"
        >
          <div
            class="row__data row__data--small"
            :class="[
              { 'row__data--rotate': expanded },
              { 'row__data--more': policy.invoices.length },
            ]"
            @click="policy.invoices.length ? expandInvoice(policy.id) : null"
          ></div>
        </div>
      </div>
      <div
        class="policy-wrapper__payments"
        ref="accidents"
        :style="{
          maxHeight: expanded ? `${containerMaxHeight}px` : '0px',
        }"
      >
        <InvoicesTable :invoices="policy.invoices" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import statics from "@/statics";
import PopupEditer from "@/partials/PopupEditer";
import { formatDisplayDate } from "@/helpers/dateFormatters";
import InvoicesTable from "@/components/AccountDetails/InvoicesTable";
import { fetchGlobalCustomer } from "@/helpers/accountDetailHelpers";
export default {
  name: "PolicyInvoice",

  components: {
    PopupEditer,
    InvoicesTable,
  },

  props: {
    policy: {
      required: true,
      type: Object,
    },
    expandInvoice: {
      required: true,
      type: Function,
    },
    expandedInvoices: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      fetchGlobalCustomer,
      showStatusPopup: false,
      headerOffset: 36,
    };
  },

  computed: {
    ...mapGetters(["policyForms", "language"]),
    expanded() {
      return this.expandedInvoices.includes(this.policy.id);
    },
    policyStatus() {
      return this.policy.policy_status
        ? this.policy.policy_status.name
        : statics.empty;
    },

    policyNumber() {
      return this.policy.policy_number || statics.empty;
    },
    branch() {
      return this.policy.policy_services.length
        ? [
            ...new Set(
              this.policy.policy_services.map((service) => {
                if (service.service) {
                  return service.service.branch_category
                    ? service.service.branch_category.long_de
                    : statics.empty;
                } else {
                  return "";
                }
              })
            ),
          ].join(" / ")
        : statics.empty;
    },

    company() {
      return this.policy.company
        ? this.policy.company.full_name || statics.empty
        : statics.empty;
    },

    createdAt() {
      return this.policy.created_at
        ? formatDisplayDate(this.policy.created_at)
        : statics.empty;
    },

    policyStatusColor() {
      return this.policy.policy_status
        ? statics.policyStatuses[
            statics.policyStatuses.findIndex(
              (status) => this.policy.policy_status.id === status.id
            )
          ].name
        : null;
    },

    policyStatuses() {
      return this.policyForms["policy-statuses"];
    },

    premiumInterval() {
      if (this.policy.policy_services.length) {
        const lastService = this.policy.policy_services[
          this.policy.policy_services.length - 1
        ];
        if (lastService.premium_interval) {
          return (
            lastService.premium_interval[`long_${this.language}`] ||
            statics.empty
          );
        }
      }
      return statics.empty;
    },

    containerMaxHeight() {
      return (
        this.policy.invoices.length * statics.paymentHeight + this.headerOffset
      );
    },
  },

  methods: {
    async updatePolicyStatus(policyStatusId) {
      await this.$store.dispatch("updatePolicyStatus", {
        policyId: this.policy.id,
        policyStatusId,
      });
      await this.fetchGlobalCustomer(this.$route.params.id);
      this.showStatusPopup = false;
    },

    showInvoiceModal() {
      this.$store.commit("setInvoicePolicyId", this.policy.id);
      this.$store.commit("setShowAddInvoiceModal", true);
    },
  },
};
</script>
