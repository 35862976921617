<template>
  <div class="teams">
    <div class="page__header">
      <div class="page__title">Team Taxes</div>
      <div class="page__right">  
        <button class="button button--default" @click="addTax">
          Add Taxes
        </button>
      </div>
    </div>

    <div class="table">
      <Loading v-if="teamTaxesLoading" />
      <template v-else>
        <NoData v-if="!teamTaxes.length" :type="'teams'" />
        <template v-else>
          <div class="table__header box box--default">
            <div class="table__label table__label--small team__logo"></div>
            <div class="table__label table__label--large">
              {{ $t(`teamTaxes.${"tableHeader"}.${"team"}`) }}
            </div>
            <div class="table__label table__label--large">
              {{ $t(`teamTaxes.${"tableHeader"}.${"tax_group"}`) }}
            </div>
            <div class="table__label table__label--large">
              {{ $t(`teamTaxes.${"tableHeader"}.${"name"}`) }}
            </div>
            <div class="table__label table__label--medium">
              {{ $t(`teamTaxes.${"tableHeader"}.${"rate"}`) }}
            </div>

            <div
              class="table__label table__label--small"
              v-if="$checkRolePermission('tax_edit')"
            ></div>
          </div>

          <div class="table__body"> 
            <TeamTax
              v-for="tax in teamTaxes"
              :key="tax.id"
              :tax="tax" 
            />
          </div>
        </template>
      </template>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/partials/Loading";
import NoData from "@/partials/NoData";
import TeamTax from "@/components/Teams/TeamTax";
export default {
  name: "TeamTaxes",

  components: {
    TeamTax,
    Loading,
    NoData,
  },

  data() {
    return {
      searchKeywords: "",

    };

  },

  computed: {
    ...mapGetters(["teamTaxesLoading",  "teamTaxes"]),
  },

  async created() {
    this.$store.commit("setTeamTaxesLoading", true);
    await this.$store.dispatch("getTeamTaxes",  this.$route.params.id);
    this.$store.commit("setTeamTaxesLoading", false);
  },

  methods: {

    addTax() { 
      this.$store.commit("setTeamTaxToEdit", null);
      this.$store.commit("setTeamTaxIsEdit", false);
      this.$store.commit("setShowAddTeamTaxModal", true);
    },

  },


};
</script>

<style scoped lang="scss">
.teams {
  height: 100%;

  &__button {
    width: 190px;
  }
  .table {
    margin-bottom: 20px;
    height: calc(100% - 40px - 20px - 20px - 30px);
   .table__label--small{
      max-width: 70px;
    }
    &--last {
      max-width: 45px;
      height: initial;
    }
   .team__logo {
     max-width: 70px;
   }
  }
  .thumbnail{

  }

}
</style>
