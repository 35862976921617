<template>
  <div class="loading">
    <div class="loading__loader" ref="loader"></div>
  </div>
</template>

<script>
import lottie from "lottie-web";

const animationData = () => import("../../public/loader_1.json");

export default {
  name: "Loading",

  data() {
    return {
      lottie,
    };
  },

  async mounted() {
    const animationJson = await animationData();
    lottie.loadAnimation({
      container: this.$refs.loader,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationJson,
    });
  },
};
</script>

<style scoped lang="scss">
.loading {
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(var(--modal-bg-1), 0.5);

  &__loader {
    width: 200px;
  }
}
</style>
