<template>
  <div class="payments">
    <div class="table">
      <div class="table__header box box--default">
        <div class="table__label table__label--medium table__label--fixed">
          Status
        </div>
        <div class="table__label table__label--medium">Policennummer</div>
        <div class="table__label table__label--medium">Erstellungsdatum</div>
        <div class="table__label table__label--medium">Versicherer</div>
        <div class="table__label table__label--large">Branche</div>
        <div class="table__label table__label--medium">Zahlung</div>
        <div class="table__label table__label--medium">Zahlung melden</div>
        <div class="table__label table__label--medium table__label--last"></div>
      </div>

      <div class="table__body">
        <Loading v-if="detailsLoading" />
        <template v-else>
          <PolicyInvoice
            v-for="policy in policies"
            :key="policy.id"
            :policy="policy"
            :expandInvoice="expandInvoice"
            :expandedInvoices="expandedInvoices"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PolicyInvoice from "@/components/AccountDetails/PolicyInvoice";
import Loading from "@/partials/Loading";
export default {
  name: "Invoices",

  components: {
    PolicyInvoice,
    Loading,
  },

  props: {
    policies: {
      required: true,
      type: Array,
    },

    detailsLoading: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      expandedInvoices: [],
    };
  },

  methods: {
    expandInvoice(id) {
      if (this.expandedInvoices.includes(id)) {
        this.expandedInvoices.splice(this.expandedInvoices.indexOf(id), 1);
      } else {
        this.expandedInvoices = [...this.expandedInvoices, id];
      }
    },
  },
};
</script>
