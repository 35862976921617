import api from "@/api";

export default {
  state: {
    salesCategoryPriceCompaniesData: [],
    saleCategoryPriceId: 0,
  },
  getters: {
    salesCategoryPriceCompaniesData: (state) => state.salesCategoryPriceCompaniesData.data,
    saleCategoryPriceId: (state) => state.saleCategoryPriceId,
  },
  mutations: {
    setSaleCategoryPriceCompanies: (state, data) => {
      state.salesCategoryPriceCompaniesData = data;
    },
    setSaleCategoryPriceId: (state, data) => {
      state.saleCategoryPriceId = data;
    }
  },
  actions: {
    getSaleCategoryCompanies: async ({commit}, saleCategoryPriceId) => {
      try {
        const response = await api({ requiresAuth: true }).get(
            `/v1/partials/sales-category-price-company-forms/${saleCategoryPriceId}`
        );
        const { data } = response;
        commit("setSaleCategoryPriceCompanies", data);
      } catch (err) {
        console.error(err);
      }
    },
    deleteSaleCategoryPrice: async (_, saleCategoryPriceId) => {
      try {   
        await api({ requiresAuth: true }).delete(
          `/v1/sales-category-prices/${saleCategoryPriceId}`
        );
      } catch (err) {
        console.error(err);
      }
    },

    updateSaleCategoryPrice: async (_, { saleCategoryPriceId, provision }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/sales-category-prices/${saleCategoryPriceId}`,
          {
            agent_provision: provision,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
};
