import api from "@/api";

export default {
  state: {
    policyFiles: [],
    policyFileId: "",
  },
  getters: {
    policyFiles: (state) => state.policyFiles,
    policyFileId: (state) => state.policyFileId,
  },
  mutations: {
    setPolicyFiles: (state, data) => {
      state.policyFiles = data;
    },

    setPolicyFileId: (state, data) => {
      state.policyFileId = data;
    },
  },
  actions: {
    addPolicy: (
      _,
      {
        customerId,
        companyId,
        agentId,
        policyStatusId,
        policyNumber,
        policyMandate,
        policyServices,
        submissionDate
      }
    ) =>
      api({ requiresAuth: true }).post("/v1/policies", {
        customer_id: customerId,
        company_id: companyId,
        agent_id: agentId,
        policy_number: policyNumber,
        policy_status_id: policyStatusId,
        policy_mandate: policyMandate,
        policy_services: policyServices,
        submit_date: submissionDate
      }),

    updatePolicyStatus: async (_, { policyId, policyStatusId }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/policies/${policyId}/statuses`,
          {
            policy_status_id: policyStatusId,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    updatePolicyAgent: async (_, { policyId, policyAgentId }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/policies/${policyId}/agents`,
          {
            agent_id: policyAgentId,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    updatePolicyNumber: async (_, { policyId, policyNumber }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/policies/${policyId}/policyNumbers`,
          {
            policy_number: policyNumber,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    updateSubmitDate: async (_, { policyId, submitDate }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/policies/${policyId}/submitDate`,
          {
            submit_date: submitDate,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    addPolicyFile: async (_, { policyId, files }) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("policy_files[]", file);
      });
      try {
        await api({ requiresAuth: true }).post(
          `/v1/policies/${policyId}/files`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
};
