<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--accident"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">Add New Schaden</div>
          </div>
        </div>
        <div class="add__form add__form--accident">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <div class="accident">
                <div class="accident__row">
                  <div class="accident__column">
                    <div class="form__row">
                      <div class="form__field form__field--statuses">
                        <VSelect
                          class="accident__statuses select select--default"
                          :class="{ 'select--error': accidentStatusHasError }"
                          :options="accidentStatuses"
                          :reduce="(status) => status.id"
                          label="name"
                          placeholder="Status"
                          v-model="accidentStatusId"
                        />
                        <div class="form__error" v-if="accidentStatusHasError">
                          Status is required
                        </div>
                      </div>
                      <div class="form__field">
                        <input
                          type="text"
                          class="input input--default"
                          :class="{ 'input--error': priceHasError }"
                          placeholder="Schadenssumme .- CHF"
                          v-model="price"
                        />
                        <div class="form__error" v-if="priceHasError">
                          {{ priceErrorPlaceholder }}
                        </div>
                      </div>
                    </div>
                    <div class="form__field">
                      <textarea
                        class="accident__textarea input input--default"
                        :class="{ 'input--error': commentHasError }"
                        cols="30"
                        rows="10"
                        placeholder="Text"
                        v-model="comment"
                      ></textarea>
                      <div class="form__error" v-if="commentHasError">
                        Comment is required
                      </div>
                    </div>
                  </div>
                  <div class="accident__column accident__column--dropfile">
                    <DropFile @input="files = $event" />
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        class="modal__button modal__button--submit button button--default"
        @click="createAccident()"
      >
        <span>Save</span>
      </button>
    </div>
  </div>
</template>

<script>
import DropFile from "@/components/DropFile";
import { mapGetters } from "vuex";
import statics from "@/statics";
import Loading from "@/partials/Loading";
export default {
  name: "AddAccidentModal",

  components: {
    DropFile,
    Loading,
  },

  data() {
    return {
      statics,
      isRequesting: false,
      formLoading: false,

      parentId: null,
      accidentId: "",
      policyId: "",
      accidentStatusId: "",
      price: "",
      comment: "",
      files: [],

      priceHasError: false,
      accidentStatusHasError: false,
      commentHasError: false,

      priceErrorPlaceholder: "",
    };
  },

  watch: {
    accidentStatusId() {
      this.validateAccidentStatusId();
    },

    price() {
      this.validatePrice();
    },

    comment() {
      this.validateComment();
    },
  },

  computed: {
    ...mapGetters([
      "accidentStatuses",
      "accidentPolicyId",
      "accidentToEdit",
      "accidentParentId",
    ]),
  },

  async created() {
    this.formLoading = true;
    await this.$store.dispatch("getAccidentStatuses");
    this.policyId = this.accidentPolicyId;
    if (Object.keys(this.accidentToEdit).length) {
      this.loadFieldsToEdit();
    } else if (this.accidentParentId) {
      this.parentId = this.accidentParentId;
    }
    this.formLoading = false;
  },

  destroyed() {
    this.$store.commit("setAccidentToEdit", {});
    this.$store.commit("setAccidentParentId", null);
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddAccidentModal", false);
    },

    loadFieldsToEdit() {
      const {
        id,
        policy_id,
        accident_status: { id: statusId },
        price,
        comment,
      } = this.accidentToEdit;
      this.accidentId = id;
      this.policyId = policy_id;
      this.accidentStatusId = statusId;
      this.comment = comment;
      if (price) {
        this.price = price;
      }
    },

    validateAccidentStatusId() {
      if (this.accidentStatusId) {
        this.accidentStatusHasError = false;
      } else {
        this.accidentStatusHasError = true;
      }
    },

    validatePrice() {
      if (
        statics.stringRegex.test(this.price) ||
        statics.symbolRegex.test(this.price)
      ) {
        this.priceHasError = true;
        this.priceErrorPlaceholder = "Invalid price";
      } else {
        this.priceHasError = false;
        this.priceErrorPlaceholder = "";
      }
    },

    validateComment() {
      if (this.comment) {
        this.commentHasError = false;
      } else {
        this.commentHasError = true;
      }
    },

    async createAccident() {
      this.validateAccidentStatusId();
      this.validatePrice();
      this.validateComment();

      if (
        !this.accidentStatusHasError &&
        !this.priceHasError &&
        !this.commentHasError
      ) {
        this.isRequesting = true;
        try {
          if (Object.keys(this.accidentToEdit).length) {
            await this.$store.dispatch("updateAccident", {
              accidentId: this.accidentId,
              policy_id: this.policyId,
              accident_status_id: this.accidentStatusId,
              price: this.price,
              comment: this.comment,
            });
          } else {
            const {
              data: {
                data: { id: accidentId },
              },
            } = await this.$store.dispatch("createAccident", {
              policy_id: this.policyId,
              accident_status_id: this.accidentStatusId,
              price: this.price,
              comment: this.comment,
              parent_id: this.parentId,
            });

            if (this.files.length) {
              await this.$store.dispatch("addAccidentFile", {
                accidentId,
                files: this.files.map((file) => file.file),
              });
            }
          }
          const { id } = this.$route.params;
          await this.$store.dispatch("getGlobalCustomer", id);
          this.isRequesting = false;
          this.$store.commit("setShowAddAccidentModal", false);
        } catch (err) {
          this.isRequesting = false;
          console.error(err);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accident {
  &__row {
    display: flex;

    .form__field {
      flex: 1;

      &--statuses {
        margin-right: 10px;
      }
    }
  }

  &__column {
    flex: 2;

    &--dropfile {
      flex: 1;
      margin-left: 10px;
    }
  }

  &__textarea {
    min-height: 103px;
    max-width: 100%;
    padding: 10px;
  }
}
</style>
