<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel modal__panel--termination">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--terminate"></div>
      <div class="modal__back" @click="goBack()"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form form--terminate">
        <div class="add__form add__form--terminate">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <div class="termination">
                <div class="termination__row">
                  <div class="termination__column">
                    <div class="form__field">
                      <VSelect
                        v-if="customerMembers.length"
                        class="select select--default"
                        :options="customerMembers"
                        :reduce="(member) => member.id"
                        :getOptionLabel="(member) => parseCustomerName(member)"
                        v-model="termination.customerId"
                      />
                      <div v-else class="input input--default input--readonly">
                        {{ parseCustomerName(customerDetails) }}
                      </div>
                    </div>
                    <div class="form__field">
                      <VSelect
                        class="select select--default"
                        :class="{ 'select--error': doubleHasError }"
                        :options="[
                          { label: 'Yes', value: true },
                          { label: 'No', value: false },
                        ]"
                        :reduce="(coverage) => coverage.value"
                        placeholder="Double Coverage"
                        v-model="termination.isDoubleCoverage"
                      />
                      <div class="form__error" v-if="doubleHasError">
                        Please select one coverage
                      </div>
                    </div>
                    <div class="form__field">
                      <textarea
                        placeholder="Text"
                        cols="30"
                        rows="10"
                        class="input input--default termination__textarea"
                        :class="{ 'input--error': textHasError }"
                        v-model="termination.text"
                      ></textarea>
                      <div class="form__error" v-if="textHasError">
                        Please enter some text
                      </div>
                    </div>
                  </div>
                  <div class="termination__column termination__column--middle">
                    <div class="form__field">
                      <VSelect
                        class="select select--default select--shadow"
                        :class="{ 'select--error': companyHasError }"
                        :options="companies"
                        :reduce="(company) => company.id"
                        placeholder="Firma"
                        label="name"
                        v-model="terminationService.companyId"
                      />
                      <div class="form__error" v-if="companyHasError">
                        Please select a company
                      </div>
                    </div>
                    <div class="form__field">
                      <Datepicker
                        class="input input--default input--shadow"
                        :class="{
                          'input--error': terminationDateHasError,
                        }"
                        :config="statics.datePickerConfig"
                        placeholder="Termination Date"
                        v-model="terminationService.terminationDate"
                      />
                      <div class="form__error" v-if="terminationDateHasError">
                        Please enter a termination date
                      </div>
                    </div>
                    <div class="form__field">
                      <div
                        v-if="!services.length"
                        class="select select--default select--readonly"
                        :class="{ 'select--error': serviceHasError }"
                      >
                        Services
                      </div>
                      <VSelect
                        v-else
                        class="select select--default select--shadow"
                        :class="{ 'select--error': serviceHasError }"
                        :options="services"
                        :reduce="(service) => service.id"
                        :label="`long_${language}`"
                        placeholder="Services"
                        v-model="terminationService.serviceId"
                      />
                      <div class="form__error" v-if="serviceHasError">
                        Please select a service
                      </div>
                    </div>

                    <div
                      v-if="enableMoreServices"
                      class="termination__add"
                      @click="addService()"
                    ></div>
                  </div>
                  <div class="termination__column">
                    <DropFile @input="files = $event" />
                  </div>
                </div>

                <div class="services">
                  <Service
                    v-for="(service, index) in termination.terminationServices"
                    :key="service.id"
                    :service="service"
                    :companies="companies"
                    :index="index"
                    :updateSpecificTerminationServiceField="
                      updateSpecificTerminationServiceField
                    "
                  />
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        class="modal__button modal__button--submit button button--default"
        @click="createTermination()"
      >
        <span>{{ $t(`addTerminationModal.${"saveButton"}`) }}</span>
      </button>
      <button
        class="modal__button modal__button--cancel button button--default"
        @click="closeModal()"
      >
        <span>{{ $t(`addTerminationModal.${"noTerminationButton"}`) }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import DropFile from "@/components/DropFile";
import Loading from "@/partials/Loading";
import Service from "@/components/TerminationModal/Service";
import { mapGetters } from "vuex";
import { parseCustomerName } from "@/helpers/entityParsers";
import statics from "@/statics";
export default {
  name: "TerminationModal",

  components: {
    DropFile,
    Loading,
    Service,
  },

  watch: {
    async "terminationService.companyId"(companyId) {
      if (companyId) {
        this.companyHasError = false;
      } else {
        this.companyHasError = true;
      }

      try {
        this.services = await this.$store.dispatch(
          "getCompanyServices",
          companyId
        );
      } catch (err) {
        console.error(err);
      }
    },

    "terminationService.terminationDate"(value) {
      if (value) {
        this.terminationDateHasError = false;
      } else {
        this.terminationDateHasError = true;
      }
    },

    "terminationService.serviceId"(value) {
      if (value) {
        this.serviceHasError = false;
      } else {
        this.serviceHasError = true;
      }
    },

    "termination.isDoubleCoverage"(value) {
      if (value) {
        this.enableMoreServices = true;
      } else {
        if (this.termination.terminationServices.length) {
          this.termination.terminationServices = [];
        }
        this.enableMoreServices = false;
      }

      if (value !== null) {
        this.doubleHasError = false;
      } else {
        this.doubleHasError = true;
      }
    },

    "termination.text"(value) {
      if (value) {
        this.textHasError = false;
      } else {
        this.textHasError = true;
      }
    },
  },

  data() {
    return {
      parseCustomerName,
      statics,
      formLoading: false,
      isRequesting: false,
      currentPage: 0,
      services: [],
      enableMoreServices: false,

      doubleHasError: false,
      textHasError: false,
      companyHasError: false,
      terminationDateHasError: false,
      serviceHasError: false,

      files: [],

      terminationService: {
        companyId: "",
        terminationDate: "",
        serviceId: "",
      },

      termination: {
        customerId: "",
        text: "",
        isDoubleCoverage: false,
        terminationServices: [],
      },
    };
  },

  computed: {
    ...mapGetters([
      "companies",
      "customerDetails",
      "customerMembers",
      "language",
    ]),
  },

  async created() {
    this.formLoading = true;
    await this.$store.dispatch("getCompanies");
    this.termination.customerId = this.customerDetails.id;
    this.formLoading = false;
  },

  methods: {
    closeModal() {
      this.formLoading = true;
      this.$store.commit("setShowTerminationModal", false);
      this.formLoading = false;
    },

    goBack() {
      this.currentPage--;
      if (this.currentPage < 0) {
        this.closeModal();
        this.$store.commit("setShowAddCustomerServicesModal", true);
      }
    },

    addService() {
      this.termination.terminationServices = [
        ...this.termination.terminationServices,
        {
          companyId: "",
          serviceId: "",
          terminationDate: "",
          companyHasError: false,
          serviceHasError: false,
          dateHasError: false,
        },
      ];
    },

    updateSpecificTerminationServiceField(index, key, data) {
      if (key === "companyId") {
        if (!data) {
          this.termination.terminationServices[index].companyHasError = true;
        } else {
          this.termination.terminationServices[index].companyHasError = false;
        }
      }
      if (key === "serviceId") {
        if (!data) {
          this.termination.terminationServices[index].serviceHasError = true;
        } else {
          this.termination.terminationServices[index].serviceHasError = false;
        }
      }
      if (key === "terminationDate") {
        if (!data) {
          this.termination.terminationServices[index].dateHasError = true;
        } else {
          this.termination.terminationServices[index].dateHasError = false;
        }
      }
      this.termination.terminationServices[index][key] = data;
    },

    validateTerminationFields(callback) {
      const { text, isDoubleCoverage } = this.termination;
      const { companyId, terminationDate, serviceId } = this.terminationService;

      if (!text) {
        this.textHasError = true;
      }

      if (isDoubleCoverage === null) {
        this.doubleHasError = true;
      }

      if (!companyId) {
        this.companyHasError = true;
      }

      if (!terminationDate) {
        this.terminationDateHasError = true;
      }

      if (!serviceId) {
        this.serviceHasError = true;
      }

      if (
        text &&
        isDoubleCoverage !== null &&
        companyId &&
        terminationDate &&
        serviceId
      ) {
        callback(true);
      } else {
        callback(false);
      }
    },

    validateServiceFields(callback) {
      this.termination.terminationServices.forEach((service) => {
        if (!service.companyId) {
          service.companyHasError = true;
        }
        if (!service.serviceId) {
          service.serviceHasError = true;
        }
        if (!service.terminationDate) {
          service.dateHasError = true;
        }
      });
      if (
        this.termination.terminationServices.some(
          (service) =>
            service.companyHasError ||
            service.serviceHasError ||
            service.dateHasError
        )
      ) {
        callback(false);
      } else {
        callback(true);
      }
    },

    async createTermination() {
      this.isRequesting = true;
      this.validateTerminationFields(async (terminationState) => {
        this.validateServiceFields(async (serviceState) => {
          if (terminationState && serviceState) {
            const termination = {
              ...this.termination,
              terminationServices: [
                { ...this.terminationService },
                ...this.termination.terminationServices,
              ].map((service) => ({
                company_id: service.companyId,
                service_id: service.serviceId,
                termination_date: service.terminationDate,
              })),
            };
            let terminationId = null;
            try {
              const { data } = await this.$store.dispatch(
                "terminatePolicy",
                termination
              );
              const {
                data: { id },
              } = data;
              terminationId = id;

              this.$store.commit("setToast", {
                display: true,
                type: "success",
                message: "Policy terminated successfully.",
              });
            } catch (err) {
              this.closeModal();
              console.error(err);
            }
            if (this.files) {
              const files = this.files.map((file) => file.file);
              await this.$store.dispatch("uploadTerminationFiles", {
                terminationId,
                files,
              });
            }
            await this.$store.dispatch(
              "getGlobalCustomer",
              this.customerDetails.id
            );
            this.closeModal();
          } else {
            this.isRequesting = false;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_termination-potential-policy.scss";
</style>
