<template>
  <div class="details">
    <div class="page__header">
      <div class="page__title">ACCOUNT DETAILS</div>
      <div class="page__right">
        <button
          class="button button--default button--animated"
          @click="$store.commit('setShowAddCustomerServicesModal', true)"
        >
          <div class="button__icon"></div>
        </button>
      </div>
    </div>
    <div class="details__content">
      <div class="detail box box--default">
        <Loading v-if="detailsLoading" />
        <template v-else>
          <div
            class="detail__edit icon icon__edit"
            @click="
              $route.params.entity === 'customer' ||
              $route.params.entity === 'potential-customer'
                ? editCustomer(customer.id)
                : editUser()
            "
          ></div>
          <div class="detail__info">
            <div class="detail__avatar">
              <img v-if="photo" :src="photo" alt="" />
              <div
                class="detail__avatar-company-icon"
                v-else-if="memberType === statics.memberTypes.company"
              >
                <img
                  src="../assets/icons/default/Bold 2px-building.svg"
                  alt=""
                />
              </div>
              <div
                v-else
                class="initials"
                :style="{ background: customer.color || '#7f8fa4' }"
              >
                {{ parseInitials(customer.first_name, customer.last_name) }}
              </div>
            </div>

            <div class="detail__splitter"></div>

            <div class="detail__profile">
              <div
                v-if="
                  $route.params.entity === 'customer' ||
                    $route.params.entity === 'potential-customer'
                "
                class="detail__name"
              >
                <span v-if="memberType === statics.memberTypes.company">{{
                  customer.company_name
                }}</span>
                <span v-else-if="memberType === statics.memberTypes.private">{{
                  `${customer.first_name} ${customer.last_name}`
                }}</span>
              </div>
              <div v-else class="detail__name">
                {{ user.first_name }} {{ user.last_name }}
              </div>

              <template
                v-if="
                  $route.params.entity === 'customer' ||
                    $route.params.entity === 'potential-customer'
                "
              >
                <div class="detail__emails" v-if="email.length">
                  <div class="detail__emails__wrapper">
                    <div class="detail__email__edit">
                      <div class="detail__email">
                        {{ email[0].email }}
                      </div>
                      <div class="email__edit__icon icon icon__edit"
                      @click="
                        $route.params.entity === 'customer'  
                          ? editEmail(customer.id, email[0].id)
                          : editEmail(customer.id, email[0].id)
                      "
                      ></div> 
                    </div> 
                    <div v-if="email[0].online_shipping " style="display: flex;">
                      <div class="icon icon__tick__color"></div>
                      <p class="detail__email">Onlineversand</p> 
                    </div> 
                  </div> 

                  <div
                    class="detail__email detail__email--secondary"
                    :style="
                      showDetails
                        ? { maxHeight: '1000px',marginTop: '10px', marginBottom: '10px' }
                        : { maxHeight: '0', margin: '0',  }
                    " 
                  >
                    <div v-for="(email, index) in email" :key="`email${email.id}`">
                      <div v-if="index !== 0" class="detail__emails__wrapper">
                        <div class="detail__email__edit">
                          <p style="height: 22px;">{{ email.email }}</p>
                          <div class="email__edit__icon icon icon__edit" style="height: 22px;"
                            @click="
                              $route.params.entity === 'customer'  
                                ? editEmail(customer.id, email.id)
                                : editEmail(customer.id, email.id)
                            "
                          ></div>
                        </div>
                        <div v-if="email.online_shipping" style="display: flex;">
                          <div class="icon icon__tick__color"></div>
                          <p class="detail__email">Onlineversand</p> 
                        </div> 
                      </div> 
                    </div> 
                  </div>
                </div>
                <div class="details__phones" v-if="phone.length">
                  <div class="detail__phone">
                    {{ phone[0].phone }}
                  </div>
                  <div
                    class="detail__phone detail__phone--secondary"
                    v-for="(phone, index) in phone"
                    :key="`phone${phone.id}`"
                    :style="
                      showDetails
                        ? { maxHeight: '16px' }
                        : { maxHeight: '0', margin: '0' }
                    "
                  >
                    <span v-if="index !== 0">{{ phone.phone }}</span>
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="detail__email">
                  {{ email }}
                </div>
                <div class="detail__phone">
                  {{ phone }}
                </div>
              </template>
            </div>

            <div class="detail__splitter"></div>

            <div class="det" @click="showDetails = !showDetails">
              <div class="det__header">
                <div class="det__title">
                  <span v-if="showDetails">Less details...</span>
                  <span v-else>More details...</span>
                </div>
                <div
                  class="det__chevron"
                  :class="{ 'det__chevron--more': showDetails }"
                ></div>
              </div>
            </div>

            <div class="detail__splitter"></div>
            <div class="detail__about">
              <template v-if="memberType === statics.memberTypes.private">
                <div class="detail__label">Birthday:</div>
                <div class="detail__detail">{{ birthday }}</div>
              </template>
              <div class="detail__label">Address:</div>
              <div class="detail__detail">
                <span>{{ street }} </span>
                <span>{{ number }}</span>
              </div>
              <div class="detail__label">Zip / City:</div>
              <div class="detail__detail">
                <span>{{ zip }}</span>
                <span> / </span>
                <span>{{ city }}</span>
              </div>
              <template v-if="memberType === statics.memberTypes.private">
                <div class="detail__label">Nationality:</div>
                <div class="detail__detail">
                  {{ country }}
                </div>
              </template> 
            </div>
            <div
              class="detail__about"
              ref="details"
              :style="
                showDetails
                  ? { maxHeight: `${detailsHeight()}px` }
                  : { maxHeight: '0px' }
              "
            >
              <template >


              <div class="detail__label">Sachbearbeiter: </div>
              <div class="detail__detail">
                {{sachbearbeiter}}
              </div>
              </template>
              <div class="detail__label">Language:</div>
              <div class="detail__detail">{{ language }}</div>
              <div class="detail__label">Job:</div>
              <div class="detail__detail">{{ job }}</div>
              <div class="detail__label">Employer:</div>
              <div class="detail__detail">{{ employer }}</div>
              <div class="detail__label">AHV Nr.:</div>
              <div class="detail__detail">
                {{ ahvNr }}
              </div>
              <div class="detail__label">IBAN:</div>
              <div class="detail__detail">
                {{ iBan }}
              </div>
              <div class="detail__label">Bankname:</div>
              <div class="detail__detail">
                {{ bankName }}
              </div>
            </div>
          </div>
          <div
            class="detail__members"
            v-if="
              $route.params.entity === 'customer' ||
                $route.params.entity === 'potential-customer'
            "
          >
            <div
              class="member"
              :class="[
                `member--${parseMemberGender(member.gender_id)}`,
                { 'member--active': customer.id === member.id },
              ]"
              v-for="(member, index) in customerMembers"
              :key="member.id"
              :style="{zIndex: `${99-index}`}"
              @click="selectMember(member.id)"
            >
              <div class="member__overlay"></div>
              <div class="member__icon" v-if="member.gender">
                <span v-if="member.gender.id == 1">
                  <img src="../assets/icons/default/male_user_icon.svg"> 
                </span>

                <span v-if="member.gender.id == 2"> 
                  <img src="../assets/icons/default/female_user_icon.svg"> 
                </span>
              </div>
              <div class="member__name" style="padding: 0;">
                {{ parseCustomerName(member) }}
              </div>
              <div
                v-if="member.parent_id"
                @click.stop="showActionsDropdown = member.id"
                class="table icon icon__dots"
              >

                <MembersActionDropdown
                  v-if="showActionsDropdown === member.id"
                  @toggleDropdown="showActionsDropdown = null"
                  :onEdit="function (){ selectMember(member.id); editCustomer(member.id)}"
                  :onDelete="function (){deleteMember(member.id)}"
                  :onSeparate="function (){separateMember(member.id)}"
              /></div>
            </div>

            <button
              class="button button--default"
              @click="openAddMemberModal()"
            >
              <div class="button__icon"></div>
              <div class="button__text">
                {{ $t(`accountDetails.${"details"}.${"addMemberButton"}`) }}
              </div>
            </button>
          </div>
          <button
            v-if="$route.params.entity === 'user'"
            type="button"
            class="button button--default detail__password-button"
            @click="$store.commit('setShowResetPasswordModal', true)"
          >
            Reset Password
          </button>
        </template>
      </div>
      <div class="container">
        <div class="tabs">
          <template
              v-if="
              $route.params.entity === 'customer' ||
                $route.params.entity === 'potential-customer'
            "
          >
            <div
                v-if="$checkRolePermission('policy_access')"
              class="tab tab--policy"
              @click="activeTab = 0"
              :class="{ 'tab--active': activeTab === 0 }"
            >
              <span

                >{{ $t(`accountDetails.${"tabs"}.${"policy"}`) }}</span
              >

            </div>
            <div
              class="tab tab--pendencies"
              @click="activeTab = 4"
              :class="{ 'tab--active': activeTab === 4 }"
            >
              <span

                >{{ $t(`accountDetails.${"tabs"}.${"pendencies"}`) }}</span
              >
            </div>
            <div
              class="tab tab--notes"
              @click="activeTab = 5"
              :class="{ 'tab--active': activeTab === 5 }"
            >
              <span

                >{{ $t(`accountDetails.${"tabs"}.${"notes"}`) }}</span
              >
            </div>
            <div
              class="tab tab--calls"
              @click="activeTab = 6"
              :class="{ 'tab--active': activeTab === 6 }"
            >
              <span

                >{{ $t(`accountDetails.${"tabs"}.${"calls"}`) }}</span
              >
            </div>
            <div
              class="tab tab--policy"
              @click="activeTab = 1"
              :class="{ 'tab--active': activeTab === 1 }"
              v-if="$checkRolePermission('potential_policy_access')"
            >
              <span
                v-if="
                  $route.params.entity === 'customer' ||
                    $route.params.entity === 'potential-customer'
                "
                >{{ $t(`accountDetails.${"tabs"}.${"potential"}`) }}</span
              >
              <span v-else>Activity Log</span>
            </div>
            <div
                v-if="$checkRolePermission('policy_invoice_access')"
              class="tab tab--payments"
              @click="activeTab = 2"
              :class="{ 'tab--active': activeTab === 2 }"
            >
              <span
                v-if="
                  $route.params.entity === 'customer' ||
                    $route.params.entity === 'potential-customer'
                "
                >{{ $t(`accountDetails.${"tabs"}.${"payment"}`) }}</span
              >
              <span v-else>Activity Log</span>
            </div>
            <div
                v-if="$checkRolePermission('policy_accident_access')"
              class="tab tab--accidents"
              @click="activeTab = 3"
              :class="{ 'tab--active': activeTab === 3 }"
            >
              <!-- <div class="tab__badge">1</div> -->
              <span
                v-if="
                  $route.params.entity === 'customer' ||
                    $route.params.entity === 'potential-customer'
                "
                >{{ $t(`accountDetails.${"tabs"}.${"accident"}`) }}</span
              >
              <span v-else>Working Group</span>
            </div>
            <div
              class="tab tab--archives"
              @click="activeTab = 7"
              :class="{ 'tab--active': activeTab === 7 }"
            >
              <span

                >{{ $t(`accountDetails.${"tabs"}.${"archives"}`) }}</span
              >
            </div>
          </template>
          <template v-else>
            <div

                class="tab tab--policy"
                @click="activeTab = 0"
                :class="{ 'tab--active': activeTab === 0 }"
            >

              <span >Notification</span>
            </div>
          </template>

        </div>

        <div class="content">
          <template
            v-if="
              $route.params.entity === 'customer' ||
                $route.params.entity === 'potential-customer'
            "
          >
            <transition name="fade" mode="in-out">
              <PolicyTable
                :policies="policies"
                :terminations="terminations"
                :detailsLoading="detailsLoading"
                v-if="activeTab === 0"
                :class="{ content__top: activeTab === 0 }"
              />
              <PendenciesTable 
                v-if="activeTab === 4"
                :class="{ content__top: activeTab === 4 }"
              />
              <NotesTable

                v-if="activeTab === 5"
                :class="{ content__top: activeTab === 5 }"
              />
              <CallsTable

                v-if="activeTab === 6"
                :class="{ content__top: activeTab === 6 }"
              />
              <PotentialPolicyTable
                v-if="activeTab === 1"
                :potentialPolicies="potentialPolicies"
                :detailsLoading="detailsLoading"
                :class="{ content__top: activeTab === 1 }"
              />
              <Invoices
                v-if="activeTab === 2"
                :class="{ content__top: activeTab === 2 }"
                :detailsLoading="detailsLoading"
                :policies="policies"
              />
              <Accidents
                v-if="activeTab === 3"
                :class="{ content__top: activeTab === 3 }"
                :detailsLoading="detailsLoading"
                :policies="policies"
              />
            </transition>
          </template>

          <template v-else>
            <transition name="fade" mode="in-out">
              <div v-if="activeTab === 0" class="notifications">
                <Notification
                  v-for="notification in notifications"
                  :key="notification.id"
                  :notification="notification"
                />
              </div>
            </transition>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import PolicyTable from "@/components/AccountDetails/PolicyTable";

import PendenciesTable from "@/components/AccountDetails/PendenciesTable";
import NotesTable from "@/components/AccountDetails/NotesTable";
import CallsTable from "@/components/AccountDetails/CallsTable";


import PotentialPolicyTable from "@/components/AccountDetails/PotentialPolicyTable";
import Accidents from "@/components/AccountDetails/Accidents";
import Loading from "@/partials/Loading";
import {
  parseBirthday,
  parseCustomerName,
  parseInitials,
} from "@/helpers/entityParsers";
import statics from "@/statics";
import Notification from "@/components/Notification";
import Invoices from "@/components/AccountDetails/Invoices";
import MembersActionDropdown from "@/components/MembersActionDropdown";
export default {
  name: "AccountDetails",

  components: {
    MembersActionDropdown,
    PolicyTable,

    PendenciesTable,
    NotesTable,
    CallsTable,

    PotentialPolicyTable,
    Accidents,
    Loading,
    Notification,
    Invoices,
  },

  watch: {
    '$route.query.tab': function(newTab) {
      this.activeTab = parseInt(newTab) || 0; // Default to tab 0 if not provided
    },
    '$route.params.id': function() {

      this.fetchEntity();
    }
  },

  data() {
    return {
      activeTab: this.getInitialTab(),
      showActionsDropdown: null,
      detailsLoading: false,
      showDetails: false,
      statics,
      parseCustomerName,
      parseInitials,
      members: [],
    };
  },

  async created() {
    await this.fetchEntity();
  },

  beforeRouteEnter(_, from, next) {
    next((vm) => {
      if (from.name == "PotentialCustomers") {
        vm.$router.replace({ params: { entity: "potential-customer" } });
        vm.activeTab = 1;
      }
    });
  },

  computed: {
    ...mapGetters(["customer", "customerMembers", "user", ]),

    photo() {
      if (
        (this.$route.params.entity === "customer" ||
          this.$route.params.entity === "potential-customer") &&
        this.customer.photo
      ) {
        return this.customer.photo.preview;
      } else if (this.$route.params.entity === "user" && this.user.photo) {
        return this.user.photo.preview;
      }
      return null;
    },

    memberType() {
      return this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
        ? this.customer.member_type.id
        : null;
    },

    birthday() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.birthday
          ? `${moment(this.customer.birthday).format(
              "MMMM D, YYYY"
            )} (${parseBirthday(this.customer.birthday)} years old)`
          : "";
      }
      return this.user.birthday
        ? `${moment(this.user.birthday).format(
            "MMMM D, YYYY"
          )} (${parseBirthday(this.user.birthday)} years old)`
        : "";
    },

    email() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.customer_emails.length
          ? this.customer.customer_emails
          : [];
      }
      return this.user.email || "-";
    },

    phone() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.customer_phones.length
          ? this.customer.customer_phones
          : [];
      }
      return this.user.phone || "-";
    },

    street() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.customer_addresses.length
          ? this.customer.customer_addresses[0].street
          : "-";
      }
      return this.user.user_addresses.length
        ? this.user.user_addresses[0].street
        : "-";
    },

    number() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.customer_addresses.length
          ? this.customer.customer_addresses[0].number
          : "-";
      }
      return this.user.user_addresses.length
        ? this.user.user_addresses[0].number
        : "-";
    },

    zip() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.customer_addresses.length
          ? this.customer.customer_addresses[0].zip
          : "-";
      }
      return this.user.user_addresses.length
        ? this.user.user_addresses[0].zip
        : "-";
    },

    city() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.customer_addresses.length
          ? this.customer.customer_addresses[0].city
          : "-";
      }
      return this.user.user_addresses.length
        ? this.user.user_addresses[0].city
        : "-";
    },

    language() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.language ? this.customer.language.code : "-";
      }
      return this.user.language ? this.user.language.code : "-";
    },

    job() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.occupation || "-";
      }
      return this.user.occupation || "-";
    },

    employer() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.employer || "-";
      }
      return this.user.employer || "-";
    },

    ahvNr() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.ahv_number || "-";
      }
      return this.user.ahv_number || "-";
    },

    iBan() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.banks.length
          ? this.customer.banks[0].account
          : "-";
      }
      return this.user.user_banks.length
        ? this.user.user_banks[0].account || "-"
        : "-";
    },

    bankName() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.banks.length ? this.customer.banks[0].name : "-";
      }
      return this.user.user_banks.length
        ? this.user.user_banks[0].name || "-"
        : "-";
    },

    country() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.country ? this.customer.country.name_en : "-";
      }
      return this.user.country ? this.user.country.name_en : "-";
    },


    sachbearbeiter() {
      if ( 
         this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer" 
      ) {
        if(this.customer.owner && this.customer.owner.company_name)
        {
          return  this.customer.owner.company_name
        }
        return this.customer.owner ? this.customer.owner.first_name + ' ' + this.customer.owner.last_name : "-";
      } else
      {
        return "-";
      } 
    },

    policies() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.policies
          ? this.customer.policies.filter(
              (police) => police.policy_services.length
            )
          : [];
      }
      return null;
    },

    potentialPolicies() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.potential_policies;
      }
      return null;
    },

    terminations() {
      if (
        this.$route.params.entity === "customer" ||
        this.$route.params.entity === "potential-customer"
      ) {
        return this.customer.terminations ? this.customer.terminations : [];
      }
      return null;
    },
    notifications(){
      if (
          this.$route.params.entity === "user"
      ) {
        return this.user.notifications ? this.user.notifications : [];
      }
      return null;
    }
  },

  methods: {
    getInitialTab() {
      if(parseInt(this.$route.query.tab))
        return parseInt(this.$route.query.tab);
      const tabMapping = {
        "customer": 0,
        "potential-customer": 1,
        "user": 0,

      };

      // Use the `tab` parameter from the route if available
      const tabParam = parseInt(this.$route.params.tab, 10);
      if (!isNaN(tabParam)) {
        return tabParam;
      }

      return tabMapping[this.$route.params.entity] || 0; // Default to tab 0
    },
    async fetchEntity() {
      this.detailsLoading = true;
      const { id, entity } = this.$route.params;
      if (entity === "customer" || entity === "potential-customer") {
        this.$store.commit("setCustomerMembers", []);
        await this.$store.dispatch("getGlobalCustomer", id);
        await this.$store.dispatch("getPolicyForms");
        this.$store.commit("setCustomerDetails", this.customer);
      } else if(entity === "user"){
        try {
          const user = await this.$store.dispatch("getUser", id);
          this.$store.commit("setUser", user);

        } catch (err) {
          console.error(err);
        }
      }else {
        const user = await this.$store.dispatch("getProfile");
        this.$store.commit("setUser", user);
        this.editProfile();
      }
      this.detailsLoading = false;
    },

    editEmail(customerId = null, emailId) {  
      if(customerId == null)
        customerId = this.$route.params.id;
      if (this.memberType === 1) {
        
        console.log(customerId)
        this.$store.commit("setCompanyToEdit", customerId);
        this.$store.commit("setCompanyIsEdit", true); 
        this.$store.commit("setEmailIdToEdit", emailId);
        this.$store.commit("setShowEditCompanyEmailModal", true);
      } else if (this.memberType === 2) {
        this.$store.commit("setPrivateToEdit", customerId);
        this.$store.commit("setPrivateIsEdit", true);
        this.$store.commit("setEmailIdToEdit", emailId);
        this.$store.commit("setshowEditPrivateEmailModal", true);
      }
    },

    editCustomer(customerId = null) {
      if(customerId == null)
        customerId = this.$route.params.id;
      if (this.memberType === 1) {
        this.$store.commit("setCompanyToEdit", customerId);
        this.$store.commit("setCompanyIsEdit", true);
        this.$store.commit("setShowAddCompanyModal", true);
      } else if (this.memberType === 2) {
        this.$store.commit("setPrivateToEdit", customerId);
        this.$store.commit("setPrivateIsEdit", true);
        this.$store.commit("setShowAddPrivateModal", true);
      }
    },

    editUser() {
      if(this.$route.params.entity === 'profile'){
        this.editProfile()
      }else{
        this.$store.commit("setUserToEdit", this.$route.params.id);
        this.$store.commit("setIsEdit", true);
        this.$store.commit("setShowAddUserModal", true);
      }

    },

    editProfile(){
      this.$store.commit("setIsProfileEdit", true);
      this.$store.commit("setShowAddUserModal", true);
    },

    detailsHeight() {
      return [...this.$refs.details.children].reduce((acc, child) => {
        const { marginTop, marginBottom } = window.getComputedStyle(child);
        return (acc +=
          child.clientHeight + parseInt(marginTop) + parseInt(marginBottom));
      }, 0);
    },

    openAddMemberModal() { 
      this.$store.commit("setCustomerToAddMember",this.$route.params.id);
      this.$store.commit("setShowAddFamilyMemberModal", true);
    },

    parseMemberGender(genderId) {
      let genderClass = "";
      if (genderId === 1) {
        genderClass = "male";
      } else if (genderId === 2) {
        genderClass = "female";
      } else {
        genderClass = "company";
      }
      return genderClass;
    },

    async deleteMember(memberId) {
      await this.$store.dispatch("deleteCustomer", memberId);
      await this.fetchEntity();
    },

    async separateMember(memberId){
      await this.$store.dispatch("separateMember", memberId);
      await this.fetchEntity();
    },
    selectMember(memberId) {
      const customer = this.customerMembers.find(
        (member) => member.id === memberId
      );
      this.$store.commit("setCustomerDetails", customer);
      this.$store.commit("setCustomer", customer);
    },
  },
};
</script>

<style scoped lang="scss">
.email__edit__icon{
  scale: 0.8;
  margin-top: -6px;
}

.icon__tick__color {
  background: url(../assets/icons/default/tick_icon_color.svg) no-repeat center;
} 


.details {
  &__content,
  .detail__avatar,
  .det__header,
  .container .tabs,
  .detail__avatar-company-icon {
    display: flex;
  }

  &__avatar,
  .det__header,
  .detail__avatar-company-icon {
    align-items: center;
  }

  &__avatar,
  .detail__avatar-company-icon {
    justify-content: center;
  }
}


 

.details {
  height: 100%;

  &__content {
    height: calc(100% - 48px - 15px);
  }

  .detail {
    width: 250px;
    margin-right: 20px;
    background: var(--bg-1);
    border-radius: var(--radius-3);
    position: relative;
    overflow: auto;

    &__info {
      padding: 0 10px;
    }

    &__splitter {
      width: 100%;
      height: 2px;
      background: var(--bg-3);
    }

    &__edit {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }

    &__avatar {
      width: 115px;
      height: 115px;
      border-radius: 50%;
      border: 4px solid var(--border-2);
      margin: 30px auto;
      overflow: hidden;

      img {
        width: initial;
      }

      &-company-icon {
        background: var(--bg-4);
        width: 100%;
        img {
          width: 70%;
        }
      }
    }

    &__profile {
      margin: 15px 0;
      color: var(--text-9);
    }

    &__name {
      font-size: var(--large);
    }

    &__email__edit{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

 
    

    &__email,
    &__phone {
      font-size: var(--x-small);
      margin: 5px 0;
      transition: all 0.3s ease;

      &--secondary {
        color: var(--text-2);
        overflow: hidden;
      }
    }

    &__emails {
      margin: 10px 0;
    }

    .det {
      margin: 5px 0;
      cursor: pointer;

      &__header {
        justify-content: space-between;
      }

      &__chevron {
        height: 20px;
        width: 20px;
        background: url("../assets/icons/default/Bold 2px-chevron_down.svg");

        &--more {
          transform: rotate(180deg);
        }
      }

      &__title {
        color: var(--text-2);
        font-size: var(--x-smaller);
      }
    }

    &__label {
      font-size: var(--x-smaller);
      color: var(--text-2);
      margin: 10px 0;
    }

    &__detail {
      color: var(--text-9);
      font-size: var(--x-small);
    }

    &__about {
      transition: all 0.3s ease;
      overflow: hidden;
    }

    .member {
      color: var(--text-5);
      background: var(--bg-6);
      margin: 20px 0;
      padding: 15px 0;
      text-align: center;
      position: relative;
      cursor: pointer;

      &__overlay,
      &__name {
        transition: all 0.2s ease-in-out;
      }

      &__overlay {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        background: var(--bg-2);
        z-index: 0;
      }

      &__name {
        font-family: var(--muli-text-semi-bold);
        font-size: var(--medium);
        position: relative;
        z-index: 1;
        padding: 0 35px 0 15px;
        word-break: break-all;
      }

      &--male {
        .member__icon {
          background: url("../assets/icons/default/male_user_icon.svg");
        }
      }

      &--female {
        .member__icon {
          background: url("../assets/icons/default/female_user_icon.svg");
        }
      }

      &:hover,
      &--active {
        .member__overlay {
          width: 100%;
        }

        .member__name {
          color: var(--text-1);
        }
      }
    }

    &__members {
      margin-top: 40px;

      .button {
        position: relative;

        &__icon {
          background: url("../assets/icons/default/Bold 2px-add.svg");
        }
      }
    }

    .button__icon,
    // .member__icon,
    .icon__delete, .icon__dots {
      height: 24px;
      width: 24px;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    .button__icon {
      left: 5px;
    }

    .button__text {
      width: 85%;
      margin-left: auto;
    }

    .icon__delete {
      right: 10px;
      left: initial;
      z-index: 1;
    }
    .icon__dots {

      right: 10px;
      left: initial;
      z-index: 1;

    }

    &__members .button,
    &__password-button {
      width: 180px;
      margin: auto;
    }

    &__password-button {
      margin-top: 60px;
    }
  }

  .member__icon{
    left: 8px;
    width: 22px;
    height: 22px;
    position: absolute; 
    top: 50%;
    transform: translateY(-50%);
  }

  .container {
    width: calc(100% - 250px - 20px);

    .tabs {
      padding-left: 7px;

      .tab {
        color: var(--text-2);
        font-size: var(--medium);
        background: var(--bg-7);
        padding: 12px 25px 18px 25px;
        border-top-right-radius: var(--radius-1);
        border-top-left-radius: var(--radius-1);
        box-shadow: 0px 3px 6px var(--shadow-3);
        position: relative;
        cursor: pointer;

        &__badge {
          position: absolute;
          height: 16px;
          width: 16px;
          top: -6px;
          right: 8px;
          background: var(--bg-8);
          border-radius: 50%;
          color: var(--text-1);
          font-size: 10px;
          text-align: center;
          line-height: 15px;
        }

        &:not(.tab--policy) {
          left: -4px;
        }

        &--payments {
          z-index: 6;
        }

        &--policy {
          z-index: 5;
        }
        &--accidents {
          z-index: 4;
        }
        &--return {
          z-index: 3;
        }
        &--payments {
          z-index: 2;
        }
        &--activities {
          z-index: 1;
        }

        &--active {
          background: var(--bg-1);
          z-index: 6;
        }
      }
    }

    .content {
      position: relative;
      border-radius: var(--radius-3);
      background: var(--bg-1);
      box-shadow: 0px 2px 4px var(--shadow-1);
      top: -3px;
      z-index: 6;
      height: calc(100% - 48px);

      &__top {
        position: relative;
        z-index: 1;
      }

      ::v-deep .policies,
      ::v-deep .accidents,
      ::v-deep .payments {
        height: 100%;
        background: inherit;
        border-radius: inherit;

        .table {
          &--inner {
            .table__body {
              padding-top: 0;
            }
          }

          &__header {
            box-shadow: 0px 5px 10px var(--shadow-1);
            margin-bottom: 0px;
            z-index: 2;
            position: relative;

            &--inner {
              box-shadow: inset 0px 3px 6px var(--shadow-3) !important;
              background: var(--bg-22);
              border-radius: 0;
              border-bottom: 1px solid var(--border-12);

              .table__label {
                border: 0;
                color: var(--text-13);

                &:first-of-type {
                  padding-left: 0 !important;
                }
              }
            }
          }

          &__label {
            &:first-of-type {
              padding-left: 20px;
            }
          }

          &__body {
            padding-top: 15px;
            height: calc(100% - 36px);
          }

          &__no-data {
            height: 100%;
          }

          &__policies {
            position: relative;
            z-index: 2;
          }
        }

        .policy-wrapper {
          &__accidents,
          &__payments {
            transition: all 0.3s ease;
            overflow: hidden;
          }

          .table__label {
            border: 0;
            text-align: left;
            padding-left: 40px;

            &:first-child {
              padding-left: 55px !important;
            }
          }
        }
      }

      .notifications {
        padding: 40px 20px;
        height: 100%;
        overflow: auto;
      }

      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.2s;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }

      .fade-enter-active {
        z-index: 1;
      }

      .fade-leave-active {
        z-index: -1;
      }

      .fade-enter,
      .fade-leave-to {
        opacity: 0;
      }
    }
  }
}
</style>
