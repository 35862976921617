import api from "@/api";

export default {
  state: {
    languages: [],
  },
  getters: {
    languages: (state) => state.languages,
  },
  mutations: {
    setLanguages: (state, data) => {
      state.languages = data;
    },
  },
  actions: {
    getLanguages: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get("/v1/languages");
        const { data } = response.data;
        commit("setLanguages", data);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
