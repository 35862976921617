<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--payment"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">Add new Zahlungen</div>
          </div>
        </div>
        <div class="add__form add__form--payment">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <div class="payment">
                <div class="payment__fields">
                  <div class="payment__row">
                    <div class="form__field">
                      <VSelect
                        class="select select--default"
                        :class="{ 'select--error': statusHasError }"
                        :options="policyInvoiceStatuses"
                        :reduce="(status) => status.id"
                        label="name"
                        placeholder="Status"
                        v-model="statusId"
                      />
                      <div class="form__error" v-if="statusHasError">
                        Status is required
                      </div>
                    </div>
                    <div class="form__field">
                      <input
                        type="text"
                        class="input input--default"
                        :class="{ 'input--error': priceHasError }"
                        v-model="price"
                        placeholder="Schadenssumme .- CHF"
                      />
                      <div class="form__error" v-if="priceHasError">
                        {{ priceErrorPlaceholder }}
                      </div>
                    </div>
                  </div>
                  <div class="payment__row">
                    <div class="form__field form__field--date">
                      <Datepicker
                        class="input input--default input--shadow"
                        :class="{
                          'input--error': dateHasError,
                        }"
                        placeholder="Date"
                        :config="statics.datePickerConfig"
                        :value="date"
                        @input="setDate($event)"
                      />
                      <div class="form__error" v-if="dateHasError">
                        Date is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form__field">
                  <DropFile @input="files = $event" />
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        class="modal__button modal__button--submit button button--default"
        @click="createPolicyInvoice()"
      >
        <span>Save</span>
      </button>
    </div>
  </div>
</template>
<script>
import DropFile from "@/components/DropFile";
import { mapGetters } from "vuex";
import statics from "@/statics";
import Loading from "@/partials/Loading";
import { fetchGlobalCustomer } from "@/helpers/accountDetailHelpers";
export default {
  name: "AddInvoiceModal",

  components: {
    DropFile,
    Loading,
  },

  data() {
    return {
      statics,
      fetchGlobalCustomer,
      formLoading: false,
      isRequesting: false,

      files: [],

      policyId: "",
      price: "",
      statusId: "",
      date: "",

      priceHasError: false,
      statusHasError: false,
      dateHasError: false,

      priceErrorPlaceholder: "",
    };
  },

  watch: {
    statusId() {
      this.validateStatus();
    },

    price() {
      this.validatePrice();
    },

    date() {
      this.validateDate();
    },
  },

  computed: {
    ...mapGetters(["policyInvoiceStatuses", "invoicePolicyId"]),
  },

  async created() {
    this.formLoading = true;
    await this.$store.dispatch("getPolicyInvoiceStatuses");
    this.policyId = this.invoicePolicyId;
    this.formLoading = false;
  },

  destroyed() {
    this.$store.commit("setInvoicePolicyId", null);
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddInvoiceModal", false);
    },

    setDate(date) {
      this.date = date;
    },

    validatePrice() {
      if (!this.price) {
        this.priceHasError = true;
        this.priceErrorPlaceholder = "Price is required";
      } else {
        this.priceHasError = false;
        this.priceErrorPlaceholder = "";
        if (
          statics.stringRegex.test(this.price) ||
          statics.symbolRegex.test(this.price)
        ) {
          this.priceHasError = true;
          this.priceErrorPlaceholder = "Invalid price";
        }
      }
    },

    validateStatus() {
      if (this.statusId) {
        this.statusHasError = false;
      } else {
        this.statusHasError = true;
      }
    },

    validateDate() {
      if (this.date) {
        this.dateHasError = false;
      } else {
        this.dateHasError = true;
      }
    },

    async createPolicyInvoice() {
      this.validatePrice();
      this.validateStatus();
      this.validateDate();

      if (!this.statusHasError && !this.priceHasError && !this.dateHasError) {
        this.isRequesting = true;
        try {
          const {
            data: {
              data: { id: invoiceId },
            },
          } = await this.$store.dispatch("createPolicyInvoice", {
            policy_id: this.policyId,
            price: this.price,
            status_id: this.statusId,
            date: this.date,
          });

          if (this.files.length) {
            await this.$store.dispatch("addPolicyInvoiceFile", {
              invoiceId,
              files: this.files.map((file) => file.file),
            });
          }
          await fetchGlobalCustomer(this.$route.params.id);
          this.isRequesting = false;
          this.closeModal();
        } catch (err) {
          this.isRequesting = false;
          console.error(err);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.payment,
.payment__row {
  display: flex;
}

.payment {
  align-items: flex-start;

  &__fields {
    flex: 2;
  }

  .form__field {
    flex: 1;
    margin-right: 10px;

    &--date {
      flex: initial;
      width: calc(50% - 10px);
    }
  }
}
</style>
