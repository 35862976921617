import api from "@/api";

export default {
  state: {
    titles: [],
  },
  getters: {
    titles: (state) => state.titles,
  },
  mutations: {
    setTitles: (state, data) => {
      state.titles = data;
    },
  },
  actions: {
    getTitles: async ({ commit }) => {
      try {
        const response = await api({ requiesAuth: true }).get("/v1/titles");
        const { data } = response.data;
        commit("setTitles", data);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
