<template>
  <div class="team row row--hover box box--default">
    <div
        class="row__data row__data--small row__data--image team__logo"

    >
      <img v-if="userThumbnail" :src="userThumbnail" alt="" />
      <div v-else class="thumbnail thumbnail--team" :style="color">
        {{ parseInitials(team.name) }}
      </div>
    </div>
    <div
      class="row__data row__data--large row__data--name"
     
    >
      {{ team.name }}
    </div>


    <div
      class="row__data row__data--large row__data--address"
     
    >
      {{ team.owner.first_name }}   {{ team.owner.last_name }}
    </div>

    <div
      class="row__data row__data--action row__data--small"
      @click.self="showActionsDropdown = !showActionsDropdown"
      v-if="$checkRolePermission('team_delete')"
    >
      <TeamsRowActionDropdown
        v-if="showActionsDropdown"
        @toggleDropdown="showActionsDropdown = $event"
        :onEdit="editTeam"
        :onDelete="removeTeam"
        :onTaxes="taxes"

      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";


import { parseInitials } from "@/helpers/entityParsers";
import TeamsRowActionDropdown from "@/components/TeamsActionDropdown";
export default
{
  name: "Team",

  components: {
    TeamsRowActionDropdown,

  },

  props: {
    team: {
      required: true,
      type: Object,
    },

    deleteTeam: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      showActionsDropdown: false,
      parseInitials,
    };
  },

  computed: {
    ...mapGetters(["users", "saleCategories"]),

    userThumbnail() {
      return this.team.photo ? this.team.photo.thumbnail : null;
    },

    color() {
      return this.team.color
          ? { background: this.team.color }
          : { background: "#7f8fa4" };
    },
  },

  methods: {


    editTeam() {
      this.$store.commit("setTeamToEdit", this.team);
      this.$store.commit("setTeamIsEdit", true);
      this.$store.commit("setShowAddTeamModal", true);
    },

    removeTeam() {

      this.deleteTeam({
        display: true,
        message: `'${this.team.name}'` ,
        action: this.$store._actions.deleteTeam,
        id: this.team.id,
      });


    },

    taxes(){
      this.$router.push({
        name: "TeamTaxes",
        params: {
          id: this.team.id,
        },
      });
    }



  },
};
</script>
<style scoped lang="scss">
.row__data--action {
  max-width: 70px;
}

.team__logo{
  max-width: 70px;
}
</style>