import api from "@/api";

export default {
  state: {
    accidents: [],
    accidentStatuses: [],
    accidentPolicyId: null,
    accidentToEdit: {},
    accidentParentId: null,
  },
  getters: {
    accidents: (state) => state.accidents,
    accidentStatuses: (state) => state.accidentStatuses,
    accidentPolicyId: (state) => state.accidentPolicyId,
    accidentToEdit: (state) => state.accidentToEdit,
    accidentParentId: (state) => state.accidentParentId,
  },
  mutations: {
    setAccidents: (state, data) => {
      state.accidents = data;
    },

    setAccidentStatuses: (state, data) => {
      state.accidentStatuses = data;
    },

    setAccidentPolicyId: (state, data) => {
      state.accidentPolicyId = data;
    },

    setAccidentToEdit: (state, data) => {
      state.accidentToEdit = data;
    },

    setAccidentParentId: (state, data) => {
      state.accidentParentId = data;
    },
  },
  actions: {
    getAccidents: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/policy-accidents"
        );
        const { data } = response.data;
        commit("setAccidents", data);
      } catch (err) {
        console.error(err);
      }
    },

    getAccidentStatuses: async ({ commit }) => {
      try {
        const { data } = await api({ requiresAuth: true }).get(
          "/v1/policy-accident-statuses"
        );
        commit("setAccidentStatuses", data.data);
      } catch (err) {
        console.error(err);
      }
    },

    createAccident: (_, accident) =>
      api({ rquiresAuth: true }).post("/v1/policy-accidents", { ...accident }),

    updateAccident: (_, accident) => {
      const { accidentId, ...accidentFields } = accident;
      return api({ requiresAuth: true }).patch(
        `/v1/policy-accidents/${accidentId}`,
        {
          ...accidentFields,
        }
      );
    },

    removeAccident: (_, accidentId) =>
      api({ requiresAuth: true }).delete(`/v1/policy-accidents/${accidentId}`),

    addAccidentFile: (_, { accidentId, files }) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("policy_accident_files[]", file);
      });
      return api({ requiresAuth: true }).post(
        `/v1/policy-accidents/${accidentId}/files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  },
};
