<template>
  <div
    class="file"
    :class="[
      { 'animate__animated animate__zoomOutLeft': isDeleting },
      { 'deleting': wasDeleted },
    ]"
  >
    <Loading v-if="isLoading" />
    <template v-else> 

      <div
        class="file__data file__data--large file__data--editable file__data--name"
      >
        <span @click.self="showEditablePopup = true">
          <transition name="fade">
            <PopupEditer
              v-if="showEditablePopup"
              :selected="file.name"
              @togglePopup="showEditablePopup = $event"
              @submit="renamePolicyFile($event)"
            />
          </transition>
          {{ file.file_name }}
        </span>
      </div>
      <div
        class="file__data file__data--small icon icon__pdf"
        @click="openPolicyFile()"
      ></div>
      <div class="file__data file__data--medium">
        {{ createdAt }}
      </div>  

      
      <div
        class="file__data file__data--small"
        v-if="!policyFilesModalIsTermination" 
      >
        <div class="checkbox"> 
          <input
            type="checkbox"
            :id="`appCheckbox${file.id}`"
            class="checkbox__checkbox"
            v-model="isApp"
            @input="toggleFileProperty('app', $event.target.checked)"
          />
          <label :for="`appCheckbox${file.id}`" class="checkbox__label"></label>
        </div>
      </div>
      <!-- <div class="file__data file__data--small">
      <div class="checkbox">
        <input
          type="checkbox"
          :id="`privateCheckbox${file.id}`"
          class="checkbox__checkbox"
          v-model="isPrivate"
          @input="toggleFileProperty('private', $event.target.checked)"
        />
        <label
          :for="`privateCheckbox${file.id}`"
          class="checkbox__label"
        ></label>
      </div>
    </div> -->
      <div
        class="file__data file__data--small icon icon__delete"
        :class="{ 'icon__delete--active': showDeletePopup }"
        tabindex="-1"
        @focus="showDeletePopup = true"
        @focusout="showDeletePopup = false"
        ref="delete"
      >
        <transition name="fade">
          <div class="delete" v-if="showDeletePopup">
            <div class="delete__text">
              Are you sure you want to delete this File?
            </div>
            <div class="delete__buttons">
              <div
                class="button button--confirm"
                @click="deletePolicyFile()"
              ></div>
              <div
                class="button button--cancel"
                @click="closeDeletePopup()"
              ></div>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PopupEditer from "@/partials/PopupEditer";
import moment from "moment";
import Loading from "@/partials/Loading";
export default {
  name: "File",

  components: {
    PopupEditer,
    Loading,
  },

  props: {
    file: {
      required: true,
      type: Object,
    },
    updateFiles: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      showEditablePopup: false,
      isPrivate: false,
      isApp: false,
      isDeleting: false,
      wasDeleted: false,
      isLoading: false,
      showDeletePopup: false,
    };
  },

  computed: {
    ...mapGetters(["policyFilesModalIsTermination", "pendenciesFiles"]),
    createdAt() {
      return moment(this.file.created_at).format("DD.MM.YYYY");
    },
  },

  mounted() {
    this.isPrivate = this.file.custom_properties.is_private;
    this.isApp = this.file.custom_properties.is_app;
  },

  methods: {
    async deletePolicyFile() {
      this.closeDeletePopup();
      this.isDeleting = true;

      await this.$store.dispatch("deleteFile", this.file.uuid);
 
      this.wasDeleted = true;
      setTimeout(async () => {
        await this.updateFiles();
      }, 300);
    },

    async renamePolicyFile(name) {
      this.isLoading = true;
      const mimeTypeParts = this.file.file_name.split(".");
      const mimeType = mimeTypeParts[mimeTypeParts.length - 1];

      await this.$store.dispatch("renameFile", {
        uuid: this.file.uuid,
        name: `${name}.${mimeType}`,
      });

      await this.updateFiles();
      this.isLoading = false;
    },

    async toggleFileProperty(type, state) {
      if (type === "private") {
        await this.$store.dispatch("changeFileProperties", {
          uuid: this.file.uuid,
          isApp: this.isApp,
          isPrivate: state,
        });
      } else if (type === "app") {
        await this.$store.dispatch("changeFileProperties", {
          uuid: this.file.uuid,
          isApp: state,
          isPrivate: this.isPrivate,
        });
      }
      await this.updateFiles();
    },

    async openPolicyFile() {
      await this.$store.dispatch("openFile", this.file);
    },

    closeDeletePopup() {
      this.$refs.delete.blur();
      this.showDeletePopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.file__data {
  position: relative;
  &--editable {
    span {
      position: relative;
      cursor: pointer;
    }
  }

  &--name {
    word-break: break-all;
  }
}

.delete {
  position: absolute;
  z-index: 3;
  background: var(--bg-1);
  right: calc(100% + 5px);
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  display: flex;
  align-items: center;
  border-radius: var(--radius-1);
  border: 1px solid var(--border-2);
  padding: 10px 20px;
  box-shadow: 0px 3px 6px var(--shadow-7);

  &__text {
    line-height: 20px;
  }

  &__buttons {
    display: flex;
  }

  .button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: all 0.3s ease;

    &--confirm {
      background: url("../../assets/icons/default/confirm_icon.svg");
      margin: 0 15px;

      &:hover {
        background: url("../../assets/icons/default/confirm_hover_icon.svg");
      }
    }

    &--cancel {
      background: url("../../assets/icons/default/close_icon.svg");
      box-shadow: none;

      &:hover {
        background: url("../../assets/icons/default/close_hover_icon.svg");
      }
    }
  }
}
</style>
