<template>
  <div class="service">
    <Loading v-if="serviceLoading" />
    <template v-else>
      <div class="service__title">
        <div class="service__name">{{ service[`long_${language}`] }} </div>
        <div class="icon icon__delete" @click="removeService()"></div>
      </div>
      <div class="service__fields" v-if="!dropdownModels.length">

        <div class="service__field">
          <Datepicker
            class="input input--default input--shadow"
            :class="{
              'input--error':
                singletonFormModels[singletonModelIndex].startDateHasError,
            }"
            placeholder="Start Date"
            :config="statics.datePickerConfig"
            :value="singletonFormModels[singletonModelIndex].startDate"
            @input="
              $store.commit('setSpecificSingletonFormModel', {
                index: singletonModelIndex,
                key: 'startDate',
                data: $event,
              })
            "
          />
          <div
            class="form__error"
            v-if="singletonFormModels[singletonModelIndex].startDateHasError"
          >
            Invalid date
          </div>
        </div>
        <template v-if="service.code != 'MM'" >


        <div class="service__field">
          <Datepicker
            class="input input--default input--shadow"
            placeholder="End Date"
            :config="statics.datePickerConfig"
            :value="singletonFormModels[singletonModelIndex].endDate"
            @input="
              $store.commit('setSpecificSingletonFormModel', {
                index: singletonModelIndex,
                key: 'endDate',
                data: $event,
              })
            "
          />
        </div>
        <div class="service__field">
          <input
            type="text"
            class="input input--default input--shadow"
            :class="{
              'input--error':
                singletonFormModels[singletonModelIndex].priceHasError,
            }"
            placeholder="Price"
            @input="
              $store.commit('setSpecificSingletonFormModel', {
                index: singletonModelIndex,
                key: 'price',
                data: $event.target.value,
              })
            "
          />
          <div
            class="form__error"
            v-if="singletonFormModels[singletonModelIndex].priceHasError"
          >
            Invalid price value
          </div>
        </div>
        <div class="service__field">
          <VSelect
            class="select select--default select--shadow"
            :options="intervals"
            :reduce="(interval) => interval.id"
            label="name"
            :appendToBody="true"
            :calculatePosition="withPopper"
            :placeholder="intervals[3].name"
            @input="
              $store.commit('setSpecificSingletonFormModel', {
                index: singletonModelIndex,
                key: 'interval',
                data: $event,
              })
            "
          />
        </div>
        </template>
      </div>

      <template v-else>
        <template v-if="service.id !== KVG">
          <div class="policy__service-toggler">
            <span>Models</span>
            <div
              class="policy__service-adder"
              :class="{
                'policy__service-adder--active': showModelsDropdown,
              }"
              @click="showModelsDropdown = !showModelsDropdown"
            ></div>

            <transition name="fade">
              <div class="service-dropdown" v-if="showModelsDropdown">
                <div class="service-dropdown__content">
                  <div class="service-dropdown__title">
                    Choose Models
                  </div>

                  <div class="service-dropdown__body">
                    <div
                      v-if="!dropdownModels.length"
                      class="service-dropdown__no-data"
                    >
                      No models found
                    </div>

                    <template v-else>
                      <div
                        class="service-dropdown__item"
                        v-for="model in dropdownModels"
                        :key="model.id"
                      >
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            class="checkbox__checkbox"
                            :checked="models.some((m) => m.id === model.id)"
                            :id="model.id"
                            @input="addOrRemoveModels($event, model)"
                          />

                          <label
                            :for="model.id"
                            class="checkbox__label checkbox__label--smaller"
                          ></label>
                        </div>
                        <label
                          :for="model.id"
                          class="service-dropdown__item-title"
                        >
                          {{ model.name }}
                        </label>
                      </div>
                    </template>
                  </div>

                  <div class="service-dropdown__buttons">
                    <button
                      type="button"
                      class="service-dropdown__button service-dropdown__button--submit"
                      @click="applyModels()"
                    ></button>
                    <button
                      type="button"
                      class="service-dropdown__button service-dropdown__button--cancel"
                      @click="showModelsDropdown = false"
                    ></button>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </template>

        <template v-else>
          <div class="model">
            <div class="model__field">
              <VSelect
                class="select select--default select--model-dropdown select--shadow"
                :options="dropdownModels"
                :reduce="(model) => model.id"
                label="name"
                :appendToBody="true"
                :calculatePosition="withPopper"
                :value="singleModel.id"
                @input="
                  $store.commit('setSpecificSingleModel', {
                    key: 'id',
                    data: $event,
                  })
                "
              />
            </div>
            <div class="model__fields">
              <div class="model__field">
                <Datepicker
                  class="input input--default input--shadow"
                  :class="{ 'input--error': singleModel.startDateHasError }"
                  placeholder="Start Date"
                  :config="statics.datePickerConfig"
                  :value="singleModel.startDate"
                  @input="
                    $store.commit('setSpecificSingleModel', {
                      key: 'startDate',
                      data: $event,
                    })
                  "
                />
                <div class="form__error" v-if="singleModel.startDateHasError">
                  Invalid date
                </div>
              </div>
              <div class="model__field">
                <div class="input input--default input--readonly">
                  {{ formatDisplayDate(singleModel.endDate) }}
                </div>
              </div>
              <div class="model__field">
                <VSelect
                  class="select select--default select--shadow"
                  :options="dropdownFranchises"
                  :reduce="(franchise) => franchise.id"
                  label="franchise"
                  :appendToBody="true"
                  :calculatePosition="withPopper"
                  placeholder="Franchise"
                  @input="
                    $store.commit('setSpecificSingleModel', {
                      key: 'franchise',
                      data: $event,
                    })
                  "
                />
              </div>
              <div class="model__field">
                <VSelect
                  class="select select--default select--shadow"
                  :options="accidents"
                  :reduce="(accident) => accident.value"
                  label="title"
                  :appendToBody="true"
                  :calculatePosition="withPopper"
                  placeholder="Accident"
                  @input="
                    $store.commit('setSpecificSingleModel', {
                      key: 'accident',
                      data: $event,
                    })
                  "
                />
              </div>
              <div class="model__field">
                <input
                  type="text"
                  class="input input--default input--shadow"
                  placeholder="Doctor name"
                  @input="
                    $store.commit('setSpecificSingleModel', {
                      key: 'doctorName',
                      data: $event.target.value,
                    })
                  "
                />
              </div>
              <div class="model__field">
                <input
                  type="text"
                  class="input input--default input--shadow"
                  :class="{ 'input--error': singleModel.priceHasError }"
                  placeholder="Price"
                  @input="
                    $store.commit('setSpecificSingleModel', {
                      key: 'price',
                      data: $event.target.value,
                    })
                  "
                />
                <div class="form__error" v-if="singleModel.priceHasError">
                  Invalid price value
                </div>
              </div>
              <div class="model__field">
                <VSelect
                  class="select select--default select--shadow"
                  :options="intervals"
                  :reduce="(interval) => interval.id"
                  label="name"
                  :appendToBody="true"
                  :calculatePosition="withPopper"
                  :placeholder="intervals[3].name"
                  @input="
                    $store.commit('setSpecificSingleModel', {
                      key: 'interval',
                      data: $event,
                    })
                  "
                />
              </div>
            </div>
          </div>
        </template>

        <div class="models">
          <div
            class="model model--multiple"
            v-for="(model, index) in models"
            :key="model.id"
          >
            <div class="model__name">{{ model.name }}</div>

            <div class="model__fields">
              <div class="model__field">
                <Datepicker
                  class="input input--default input--shadow"
                  :class="{
                    'input--error': formModels[index].startDateHasError,
                  }"
                  placeholder="Start Date"
                  :config="statics.datePickerConfig"
                  :value="formModels[index].startDate"
                  @input="
                    $store.commit('setSpecificFormModel', {
                      index,
                      key: 'startDate',
                      data: $event,
                    })
                  "
                />
                <div
                  class="form__error"
                  v-if="formModels[index].startDateHasError"
                >
                  Invalid date
                </div>
              </div>
              <div class="model__field">
                <Datepicker
                  class="input input--default input--shadow"
                  placeholder="End Date"
                  :config="statics.datePickerConfig"
                  :value="formModels[index].endDate"
                  @input="
                    $store.commit('setSpecificFormModel', {
                      index,
                      key: 'endDate',
                      data: $event,
                    })
                  "
                />
              </div>
              <div class="model__field">
                <input
                  type="text"
                  class="input input--default input--shadow"
                  :class="{ 'input--error': formModels[index].priceHasError }"
                  placeholder="Price"
                  @input="
                    $store.commit('setSpecificFormModel', {
                      index,
                      key: 'price',
                      data: $event.target.value,
                    })
                  "
                />
                <div class="form__error" v-if="formModels[index].priceHasError">
                  Invalid price value
                </div>
              </div>
              <div class="model__field">
                <VSelect
                  class="select select--default select--shadow"
                  :options="intervals"
                  :reduce="(interval) => interval.id"
                  label="name"
                  :appendToBody="true"
                  :calculatePosition="withPopper"
                  :placeholder="intervals[3].name"
                  @input="
                    $store.commit('setSpecificFormModel', {
                      index,
                      key: 'interval',
                      data: $event,
                    })
                  "
                />
              </div>
              <div
                class="model__field model__field--economy"
                v-if="model.model_categories.length"
              >
                <VSelect
                  class="select select--default select--shadow"
                  :options="model.model_categories"
                  :reduce="(category) => category.id"
                  label="name"
                  :appendToBody="true"
                  :calculatePosition="withPopper"
                  placeholder="Economy"
                  @input="
                    $store.commit('setSpecificFormModel', {
                      index,
                      key: 'economy',
                      data: $event,
                    })
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import statics from "@/statics";
import Loading from "@/partials/Loading";
import { parseBirthday } from "@/helpers/entityParsers";
import { formatDisplayDate, formatDate } from "@/helpers/dateFormatters";
import withPopper from "@/helpers/withPopper";

export default {
  name: "Service",

  components: {
    Loading,
  },

  props: {
    service: {
      required: true,
      type: Object,
    },

    companyId: {
      required: true,
      type: Number,
    },

    deleteService: {
      required: true,
      type: Function,
    },

    servicesToDelete: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      formatDisplayDate,
      statics,
      KVG: statics.KVG,
      maxUnderAgedIdFranchise: 7,
      teenage: 18,
      serviceLoading: false,
      showModelsDropdown: false,
      dropdownModels: [],
      preModels: [],
      models: [],
      singletonModelIndex: "",
      withPopper,
    };
  },

  watch: {
    servicesToDelete(services) {
      if (services.length) {
        const serviceToDelete = services.find(
          (service) => service.id === this.service.id
        );
        if (serviceToDelete) {
          const serviceExists = this.servicesToRemove.some(
            (service) => service.id === serviceToDelete.id
          );
          if (!serviceExists) {
            const { id } = serviceToDelete;
            if (id === this.KVG) {
              this.$store.commit("setServicesToRemove", [
                ...this.servicesToRemove,
                { id, type: "KVG" },
              ]);
            } else if (this.dropdownModels.length) {
              this.$store.commit("setServicesToRemove", [
                ...this.servicesToRemove,
                { id, type: "multiple" },
              ]);
            } else {
              this.$store.commit("setServicesToRemove", [
                ...this.servicesToRemove,
                { id, type: "singleton" },
              ]);
            }
          }
        }
      }
    },
  },

  computed: {
    ...mapGetters([
      "policyForms",
      "customerDetails",
      "formModels",
      "singleModel",
      "singletonFormModels",
      "servicesToRemove",
      "language",
    ]),

    intervals() {
      return this.policyForms.intervals;
    },

    accidents() {
      return [
        { title: "Yes", value: true },
        { title: "No", value: false },
      ];
    },

    franchises() {
      return this.policyForms.franchises;
    },

    // Returns franchises based on the customers age
    dropdownFranchises() {
      return parseBirthday(this.customerDetails.birthday) <= this.teenage
        ? this.underAgedFranchises
        : this.agedFranchises;
    },

    // Get all franchises below 18 years old
    underAgedFranchises() {
      return this.franchises.filter(
        (franchise) => franchise.id <= this.maxUnderAgedIdFranchise
      );
    },

    // Get all franchises above 18 years old
    agedFranchises() {
      return this.franchises.filter(
        (franchise) => franchise.id > this.maxUnderAgedIdFranchise
      );
    },
  },

  async created() {
    this.serviceLoading = true;
    this.dropdownModels = await this.$store.dispatch(
      "getModelsByCompanyServiceAndCustomer",
      {
        serviceId: this.service.id,
        companyId: this.companyId,
        customerId: this.customerDetails.id,
      }
    );

    if (this.service.id === this.KVG) {
      const [firstModel] = this.dropdownModels;
      const { id } = firstModel;
      this.$store.commit("setSingleModel", {
        ...this.singleModel,
        id,
        serviceId: this.service.id,
      });
    } else if (!this.dropdownModels.length) {
      this.$store.commit("setSingletonFormModels", [
        ...this.singletonFormModels,
        {
          startDate: "",
          endDate: formatDate(statics.lastDateOfCurrentYear),
          price: "",
          interval: "",
          serviceId: this.service.id,
          startDateHasError: false,
          priceHasError: false,
        },
      ]);
      this.singletonModelIndex = this.singletonFormModels.findIndex(
        (model) => model.serviceId === this.service.id
      );
    }

    this.serviceLoading = false;
  },

  methods: {
    /* Main goal of this method is to add or remove items from the
     "preModel" and "formModel" arrays, depending whether or not the checkbox
     is checked and the item does not exist in the array*/
    addOrRemoveModels(event, model) {
      const { checked } = event.target;
      const existingModel = this.preModels.findIndex((m) => m.id === model.id);
      if (checked && existingModel === -1) {
        this.preModels = [...this.preModels, model];
        if (!this.formModels.some((m) => m.id === model.id)) {
          this.$store.commit("setFormModels", [
            ...this.formModels,
            {
              id: model.id,
              serviceId: this.service.id,
              startDate: "",
              endDate: formatDate(statics.lastDateOfCurrentYear),
              startDateHasError: false,
              priceHasError: false,
            },
          ]);
        }
      } else {
        this.preModels.splice(existingModel, 1);
      }
    },

    /*This method renders the models which come from the "preModel" array.
      Then it checks if there has been any items missing or added.
      If there has been any items removed, it updates the "formModel"
      array in vuex without repeating it*/
    applyModels() {
      this.models = [...this.preModels];
      const formModelIds = this.models.map((model) => model.id);
      const modelsToRemove = this.formModels.filter(
        (model) => !formModelIds.includes(model.id)
      );
      if (modelsToRemove.length) {
        modelsToRemove.forEach((id) => {
          this.$store.commit(
            "setFormModels",
            this.models
              .filter((model) => model.id !== id)
              .map((model) => this.formModels.find((m) => m.id === model.id))
          );
        });
      }

      this.showModelsDropdown = false;
    },

    removeService() {
      if (this.service.id === this.KVG) {
        this.$store.commit("setSingleModel", {
          ...this.singleModel,
          id: "",
          serviceId: "",
          startDate: "",
          franchise: "",
          accident: "",
          doctorName: "",
          price: "",
          interval: "",
          startDateHasError: false,
          priceHasError: false,
        });
      } else if (!this.dropdownModels.length) {
        const singletonModels = [...this.singletonFormModels];
        singletonModels.splice(this.singletonModelIndex, 1);
        this.$store.commit("setSingletonFormModels", singletonModels);
      } else {
        this.$store.commit("setFormModels", []);
      }
      this.deleteService(this.service.id);
    },
  },
};
</script>

<style scoped lang="scss">
.service,
.model {
  position: relative;
  min-height: 80px;

  &--multiple {
    padding: 20px 50px;
    border-bottom: 1px solid var(--border-10);

    .model__name {
      color: var(--text-2);
      font-family: var(--muli-text-semi-bold);
      font-size: var(--x-small);
      margin-bottom: 15px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  &__name {
    font-size: var(--x-small);
    color: var(--text-5);
    margin-right: 15px;
  }

  &__fields {
    display: flex;
  }

  &__field {
    width: 110px;
    margin-right: 5px;
    position: relative;

    &--economy {
      width: 180px;
    }
  }

  .policy__service-toggler {
    margin: 0;
  }
}

.models {
  border-top: 1px solid var(--border-10);
  margin-top: 15px;
}

.select--model-dropdown {
  width: 280px !important;
  margin-bottom: 20px;
}
</style>
