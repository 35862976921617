<template>
  <div class="payments">
    <div class="table table--inner">
      <div class="table__header table__header--inner">
        <div class="table__label table__label--medium">Prämie</div>
        <div class="table__label table__label--medium">Status</div>
        <div class="table__label table__label--medium">Dokument</div>
        <div class="table__label table__label--medium">Zahlungsstatus</div>
        <div class="table__label table__label--large">Betrag</div>
      </div>

      <div class="table__body">
        <Invoice
          v-for="(invoice, index) in invoices"
          :key="invoice.id"
          :invoice="invoice"
          :isLast="index === invoices.length - 1"
          :priceSum="priceSum"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Invoice from "@/components/AccountDetails/Invoice";
export default {
  name: "InovicesTable",

  components: {
    Invoice,
  },

  props: {
    invoices: {
      required: true,
      type: Array,
    },
  },

  computed: {
    priceSum() {
      return this.invoices.reduce(
        (acc, invoice) => (acc += Number(invoice.price)),
        0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  &__label {
    padding-left: 40px;
    padding-right: 0;

    &:last-of-type {
      padding-right: 70px;
      text-align: center !important;
    }
  }
}
</style>
