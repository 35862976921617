import api from "@/api";

export default {
  state: {
    intervals: [],
  },
  getters: {
    intervals: (state) => state.intervals,
  },
  mutations: {
    setIntervals: (state, data) => {
      state.intervals = data;
    },
  },
  actions: {
    getIntervals: async ({ commit }) => {
      try {
        const response = await api({ requiredAuth: true }).get("/v1/intervals");
        const { data } = response.data;
        commit("setIntervals", data);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
