<template>
  <div class="add modal modal--default">
    <div
      class="modal__panel"
      :class="showAddFamilyMemberModal ? 'modal__panel--in' : 'modal__panel--out'"
    >
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="add__new">{{ $t(`addFamilyMemberModal.${"title"}`) }}</div>

      <div class="add__title">{{ $t(`addFamilyMemberModal.${"subTitle"}`) }}</div>

      <div class="customers">
        <div class="customer" @click="showSearchMemberModal()">
          <div class="customer__icon customer__icon--search"></div>
          <button class="add__button button button--default">
            {{ $t(`addFamilyMemberModal.${"searchButton"}`) }}
          </button>
        </div>
        <div class="customer" @click="showAddCustomerModal()">
          <div class="customer__icon customer__icon--add"></div>
          <button class="add__button button button--default">
            {{ $t(`addFamilyMemberModal.${"addButton"}`) }}
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AddFamilyMember",

  components: {},

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["showAddFamilyMemberModal", "isAddingCustomer"]),
  },

  methods: {
    closeModal() {
      this.$store.commit("setIsAddingCustomer", false);
      this.$store.commit("setIsAddingPotentialCustomer", false);
      this.$store.commit("setShowAddCustomerModal", false);
      this.$store.commit("setShowAddFamilyMemberModal", false);
    },
    showAddCustomerModal() {
      this.$store.commit("setIsAddingCustomer", true);
      this.$store.commit("setIsAddingMember", true);
      this.$store.commit("setShowAddFamilyMemberModal", false);
      this.$store.commit("setShowAddCustomerModal", true);
    },
    showSearchMemberModal() {

      this.$store.commit("setShowAddFamilyMemberModal", false);
      this.$store.commit("setShowAddSearchFamilyMemberModal", true);
    },

  },


};
</script>

<style scoped lang="scss">
@import "../assets/scss/_add-panel-modal.scss";
</style>
