<template>
  <div class="accidents">
    <div class="table table--inner">
      <div class="table__header table__header--inner">
        <div class="table__label table__label--large">Sachbearbeiter</div>
        <div class="table__label table__label--large">Beschreibung</div>
        <div class="table__label table__label--medium">Summe</div>
        <div class="table__label table__label--medium">Status</div>
        <div class="table__label table__label--large">Tools</div>
      </div>

      <div class="table__body">
        <Accident
          v-for="accident in accidents"
          :key="accident.id"
          :accident="accident"
          :openPolicyFilesModal="openPolicyFilesModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Accident from "@/components/AccountDetails/Accident";
export default {
  name: "AccidentsTable",

  components: {
    Accident,
  },

  props: {
    accidents: {
      required: true,
      type: Array,
    },

    openPolicyFilesModal: {
      required: true,
      type: Function,
    },
  },
};
</script>
