import api from "@/api";

export default {
  actions: {
    saveSettings: (_, { userId, locale }) =>
      api({ requiresAuth: true }).patch(`/v1/users/${userId}/settings`, {
        locale,
      }),
  },
};
