<template>
  <div
    class="category"
    :class="[
      { 'animate__animated animate__zoomOutLeft': isDeleting },
      { 'category--deleting': wasDeleted },
    ]"
  >
    <span class="category__name">
      <span @click.self="showCategoryNamePopup = true">
        <transition name="fade" mode="in-out">
          <PopupEditer
            v-if="showCategoryNamePopup"
            @togglePopup="showCategoryNamePopup = $event"
            :selected="category.name"
            @submit="updateModelCategoryName($event)"
          />
        </transition>
        {{ category.name }}
      </span>
    </span>
    <div class="icon icon__delete" @click="deleteModelCategory()"></div>
  </div>
</template>

<script>
import PopupEditer from "@/partials/PopupEditer";
export default {
  name: "ModelCategory",

  components: {
    PopupEditer,
  },

  props: {
    category: {
      required: true,
      type: Object,
    },

    getModels: {
      rquired: true,
      type: Function,
    },
  },

  data() {
    return {
      isDeleting: false,
      wasDeleted: false,
      showCategoryNamePopup: false,
    };
  },

  methods: {
    async deleteModelCategory() {
      this.isDeleting = true;
      await this.$store.dispatch("deleteModelCategory", this.category.id);
      this.wasDeleted = true;
      setTimeout(async () => {
        await this.getModels();
      }, 300);
    },

    async updateModelCategoryName(name) {
      await this.$store.dispatch("updateModelCategory", {
        name,
        modelId: this.category.model_id,
        categoryId: this.category.id,
      });
      await this.getModels();
    },
  },
};
</script>
