import api from "@/api";

export default {
  state: {
    policyInvoiceStatuses: [],
    invoicePolicyId: null,
  },
  getters: {
    policyInvoiceStatuses: (state) => state.policyInvoiceStatuses,
    invoicePolicyId: (state) => state.invoicePolicyId,
  },
  mutations: {
    setPolicyInvoiceStatuses: (state, data) => {
      state.policyInvoiceStatuses = data;
    },

    setInvoicePolicyId: (state, data) => {
      state.invoicePolicyId = data;
    },
  },
  actions: {
    getPolicyInvoiceStatuses: async ({ commit }) => {
      try {
        const {
          data: { data },
        } = await api({ requiresAuth: true }).get(
          "/v1/policy-invoice-statuses"
        );
        commit("setPolicyInvoiceStatuses", data);
      } catch (err) {
        console.error(err);
      }
    },
    createPolicyInvoice: (_, invoice) =>
      api({ requiresAuth: true }).post("/v1/policy-invoices", { ...invoice }),

    addPolicyInvoiceFile: (_, { invoiceId, files }) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("policy_invoice_files[]", file);
      });
      return api({ requiresAuth: true }).post(
        `/v1/policy-invoices/${invoiceId}/files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },

    updatePaidField: (_, { invoiceId, paid }) => {
      try {
        return api({
          requiresAuth: true,
        }).patch(`/v1/policy-invoices/${invoiceId}/paid`, { paid });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
