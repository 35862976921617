import api from "@/api";

export default {
  state: {
    teams: []
  },
  getters: {
    teams: (state) => state.teams,
  },
  mutations: {
    setTeams: (state, data) => {
      state.teams = data;
    },

  },
  actions: {
    getTeams: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get("/v1/teams");
        const { data } = response.data;
        commit("setTeams", data);
      } catch (err) {
        console.error(err);
      }
    },
      deleteTeam: async ({ commit, dispatch }, teamId) => {
          commit("setUsersLoading", true);
          try {
              await api({ requiresAuth: true }).delete(`/v1/teams/${teamId}`);
              await dispatch("getTeams");

          } catch (err) {
              console.error(err);
          }
          commit("setTeamsLoading", false);
      },
    createTeam: (
        { commit, dispatch },
        {
          name,
          owner,
          zip,
          city,
          street,
          number,
            file

        }
    ) =>
        new Promise((resolve, reject) => {
          api({ requiresAuth: true })
              .post("/v1/teams", {
                name: name,
                owner_id: owner.id,
                zip,
                city,
                street,
                number,
                  file

              })
              .then(
                  async (response) => {


                    const { data } = response.data;
                    commit("setTeamsLoading", true);
                    await dispatch("getTeams");
                    resolve(data);
                    commit("setTeamsLoading", false);

                      if (file) {
                          await dispatch("addTeamPhoto", {
                              teamId: data.id,
                              file,
                          });
                      }
                  },
                  (err) => {
                    commit("setTeamsLoading", false);
                    reject(err);
                  }
              );
        }),
      updateTeam: (
        { commit, dispatch },
        {
          teamId,
          name,
          owner,
         street,
         number,
         zip,
         city

        }
    ) =>
        new Promise((resolve, reject) => {
          api({ requiresAuth: true })
              .patch("/v1/teams/" + teamId, {
                name: name,
                owner_id: owner.id,
                  teamId: teamId,
                  street,
                  number,
                  city,
                  zip

              })
              .then(
                  async (response) => {


                    const { data } = response.data;
                    commit("setTeamsLoading", true);
                    await dispatch("getTeams");
                    resolve(data);
                    commit("setTeamsLoading", false);
                  },
                  (err) => {
                    commit("setTeamsLoading", false);
                    reject(err);
                  }
              );
        }),
      addTeamPhoto: async (_, { teamId, file }) => {
          const formData = new FormData();
          formData.append("photo", file);
          return api({ requiresAuth: true }).post(
              `/v1/teams/${teamId}/files`,
              formData,
              {
                  headers: {
                      "Content-Type": "multipart/form-data",
                  },
              }
          );
      },
  },


};
