<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--setting"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">Add New Role</div>
          </div>
        </div>
        <div class="add__form">
          <transition name="fade" mode="out-in">
            <div class="add__content">
              <div class="form__field">
                <input
                  type="text"
                  class="input input--default"
                  :class="{ 'input--error': roleNameHasError }"
                  placeholder="Role name"
                  v-model="roleName"
                />
                <div class="form__error" v-if="roleNameHasError">
                  Please enter a role name
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        class="modal__button modal__button--submit button button--default"
        @click="addRole()"
      >
        <span>Add Role</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddRoleModal",

  watch: {
    roleName(value) {
      if (value) {
        this.roleNameHasError = false;
      } else {
        this.roleNameHasError = true;
      }
    },
  },

  data() {
    return {
      roleName: "",
      roleNameHasError: false,
      isRequesting: false,
    };
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddRoleModal", false);
    },

    async addRole() {
      this.isRequesting = true;
      if (this.roleName) {
        await this.$store.dispatch("createRole", { title: this.roleName });
        await this.$store.dispatch("getRoles");
        this.closeModal();
      } else {
        this.roleNameHasError = true;
      }
      this.isRequesting = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form__field {
  margin-top: 160px;
}

.modal__button {
  left: 50%;
  transform: translateX(-50%);
  bottom: 200px;
}
</style>
