export default () => {
    const rolePermissions = JSON.parse(localStorage.getItem("user"))
    .roles.map((role) => role.permissions)
    .flat();

  const filteredPermissions = [
    ...new Set(
      rolePermissions.map((permission) => permission.id)
    )
  ].map((id) =>
      rolePermissions.find((permission) => permission.id === id)
    );
    return filteredPermissions
}