export default (errorObj) => {
  const errors = Object.keys(errorObj);
  return errors.map((error) => {
    const errorParts = error.split(".");
    const errorKey = errorParts[errorParts.length - 1];
    const errorPos = errorParts[1];
    return {
      errorKey,
      errorValue: Array.isArray(errorObj[error])
        ? errorObj[error][0]
        : errorObj[error],
      errorPos,
    };
  });
};
