<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--model"></div>
      <div class="modal__back" @click="goBack()"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">Add New Model</div>
          </div>
        </div>
        <div class="add__form">
          <transition name="fade" mode="out-in">
            <div class="add__content">
              <div class="form__field">
                <div
                  class="input input--default input--readonly"
                  :class="{ 'select--error': companyHasError }"
                >
                  {{ selectedCompany.name }}
                </div>
                <div class="form__error" v-if="companyHasError">
                  Please select a company
                </div>
              </div>
              <div class="form__field">
                <div
                  class="input input--default input--readonly"
                  :class="{ 'select--error': serviceHasError }"
                >
                  {{ selectedService[`long_${language}`] }}
                </div>
                <div class="form__error" v-if="serviceHasError">
                  Please select a service
                </div>
              </div>
              <div class="form__field">
                <input
                  type="text"
                  class="input input--default"
                  :class="{ 'input--error': nameHasError }"
                  placeholder="Name"
                  v-model="model.name"
                />
                <div class="form__error" v-if="nameHasError">
                  Please enter a name
                </div>
              </div>
              <div class="form__field">
                <input
                  type="text"
                  class="input input--default"
                  placeholder="Canton"
                  v-model="model.canton"
                />
              </div>
              <div class="form__field">
                <input
                  type="text"
                  class="input input--default"
                  placeholder="Region"
                  v-model="model.region"
                />
              </div>
              <div
                class="form__field"
                v-for="(category, index) in model.categories"
                :key="index"
              >
                <input
                  type="text"
                  class="input input--default"
                  placeholder="Category Name"
                  v-model="category.name"
                />
                <div
                  class="form__add"
                  v-if="index === 0 && category.name"
                  @click="addCategory()"
                ></div>
                <div
                  class="form__remove"
                  v-else-if="index !== 0"
                  @click="removeCategory(index)"
                ></div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        class="modal__button modal__button--submit button button--default"
        @click="createModel()"
      >
        <span>Save</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AddModelModal",

  data() {
    return {
      currentPage: 0,
      isRequesting: false,

      model: {
        companyId: "",
        serviceId: "",
        name: "",
        canton: "",
        region: "",
        categories: [{ name: "" }],
      },

      companyHasError: false,
      serviceHasError: false,
      nameHasError: false,
    };
  },

  watch: {
    "model.name"(value) {
      if (value) {
        this.nameHasError = false;
      } else {
        this.nameHasError = true;
      }
    },

    async "model.companyId"(companyId) {
      if (companyId) {
        this.companyHasError = false;
      } else {
        this.companyHasError = true;
      }
    },

    "model.serviceId"(serviceId) {
      if (serviceId) {
        this.serviceHasError = false;
      } else {
        this.serviceHasError = true;
      }
    },
  },

  computed: {
    ...mapGetters(["selectedCompany", "selectedService", "language"]),
  },

  async mounted() {
    this.model.companyId = this.selectedCompany.id;
    this.model.serviceId = this.selectedService.id;
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddModelModal", false);
    },

    goBack() {
      this.currentPage--;
      if (this.currentPage < 0) {
        this.closeModal();
        this.$store.commit("setShowAddCustomerServicesModal", true);
      }
    },

    addCategory() {
      this.model.categories = [...this.model.categories, { name: "" }];
    },

    removeCategory(index) {
      this.model.categories.splice(index, 1);
    },

    validateFields() {
      const { companyId, serviceId, name } = this.model;

      if (!companyId) {
        this.companyHasError = true;
      }

      if (!serviceId) {
        this.serviceHasError = true;
      }

      if (!name) {
        this.nameHasError = true;
      }

      if (!this.companyHasError && !this.serviceHasError && !this.nameHasError)
        return true;
      else return false;
    },

    async createModel() {
      this.isRequesting = true;
      if (this.validateFields()) {
        const {
          companyId,
          serviceId,
          name,
          canton,
          region,
          categories,
        } = this.model;
        const modelCategories = categories.filter((category) => category.name);
        if (modelCategories.length) {
          await this.$store.dispatch("addModel", {
            companyId,
            serviceId,
            name,
            canton,
            region,
            categories: modelCategories,
          });
        } else {
          await this.$store.dispatch("addModel", {
            companyId,
            serviceId,
            name,
            canton,
            region,
          });
        }
        const models = await this.$store.dispatch(
          "getModelsByCompanyAndServices",
          { companyId, serviceId }
        );
        this.$store.commit("setModels", models);
        this.closeModal();
      }
      this.isRequesting = false;
    },
  },
};
</script>
