export default {
  memberTypes: {
    company: 1,
    private: 2,
  },
  policyStatuses: [
    {
      id: 1,
      name: "new",
    },
    {
      id: 2,
      name: "active",
    },
    {
      id: 3,
      name: "processing",
    },
    {
      id: 4,
      name: "inactive",
    },
    {
      id: 5,
      name: "returned",
    },
    {
      id: 6,
      name: "prenatal",
    },
    {
      id: 7,
      name: "withdrawal",
    },
    {
      id: 8,
      name: "rejected",
    },
    {
      id: 9,
      name: "archived",
    },
    {
      id: 10,
      name: "cancelled",
    },
    {
      id: 11,
      name: "partial",
    },
    {
      id: 12,
      name: "accepted",
    },
  ],
  accidentStatuses: [
    { id: 1, name: "processing" },
    { id: 2, name: "accepted" },
    { id: 3, name: "declined" },
  ],
  invoiceStatuses: [
    { id: 1, name: "operation" },
    { id: 2, name: "warning" },
    { id: 3, name: "reminder" },
  ],
  KVG: 1,
  MM: 32,
  empty: "----------",
  lastDateOfCurrentYear: new Date(new Date().getFullYear(), 11, 31),
  datePickerConfig: {
    altInput: true,
    altFormat: process.env.VUE_APP_FLATPICKR_DISPLAY_DATE_FORMAT,
    allowInput: true,
    dateFormat: process.env.VUE_APP_FLATPICKR_SERVER_DATE_FORMAT,
    altInputClass: "input input--default",
  },
  stringRegex: /[a-zA-Z]/i,
  symbolRegex: /[!@#$%^&*()_+\-=[\]{};':"\\|,<>/?]+/,
  onlyStringRegex: /^[a-zA-Z ]+$/,
  // eslint-disable-next-line
  phoneRegex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
  fetchNotificationTime: 300000000000000,
  paymentHeight: 80,
};
