import api from "@/api";

export default {
  state: {
    servicesToRemove: [],
    selectedService: {},
  },
  getters: {
    servicesToRemove: (state) => state.servicesToRemove,
    selectedService: (state) => state.selectedService,
  },
  mutations: {
    setServicesToRemove: (state, data) => {
      state.servicesToRemove = data;
    },
    setSelectedService: (state, data) => {
      state.selectedService = data;
    },
  },
  actions: {
    getCompanyServices: (_, companyId) => {
      return new Promise((resolve, reject) => {
        api({ requiresAuth: true })
          .get(`/v1/companies/${companyId}/services`)
          .then(
            (response) => {
              const { data } = response.data;
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
  },
};
