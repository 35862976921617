<template>
  <div class="dropdown" ref="dropdown">
    <div
      class="dropdown__toggler"
      @click="showDropdown = true"
      ref="toggler"
      :class="{
        'dropdown__toggler--active': showDropdown,
      }"
    ></div>

    <div
      class="dropdown__dropdown"
      v-show="showDropdown"
      ref="dropdownEl"
      :style="{ left: `${x}px`, top: `${y}px` }"
    >
      <template v-if="showDropdown">
        <Loading v-if="servicesLoading" />
        <div v-else class="dropdown__content">
          <div class="dropdown__title">Choose Services</div>

          <div class="dropdown__body">
            <div v-if="!dropdownServices.length" class="dropdown__no-data">
              No services found
            </div>

            <template v-else>
              <div
                class="dropdown__item"
                v-for="service in dropdownServices"
                :key="service.id"
              >
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox__checkbox"
                    :checked="services.some((s) => s.id === service.id)"
                    :id="service.id"
                    @input="addOrRemoveServices($event, service)"
                  />
                  <label
                    :for="service.id"
                    class="checkbox__label checkbox__label--smaller"
                  ></label>
                </div>
                <label :for="service.id" class="dropdown__item-title">
                  {{ service[`long_${language}`] }}
                </label>
              </div>
            </template>
          </div>

          <div class="dropdown__buttons">
            <button
              class="dropdown__button dropdown__button--submit"
              @click="applyServices()"
            ></button>
            <button
              class="dropdown__button dropdown__button--cancel"
              @click="close()"
            ></button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Loading from "@/partials/Loading";
import { mapGetters } from "vuex";
export default {
  name: "DropdownServices",

  components: {
    Loading,
  },

  props: {
    dropdownServices: {
      required: true,
      type: Array,
    },

    servicesLoading: {
      required: false,
      type: Boolean,
    },

    services: {
      required: false,
      type: Array,
    },

    addOrRemoveServices: {
      required: true,
      type: Function,
    },

    applyServices: {
      required: true,
      type: Function,
    },

    closeServicesDropdown: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      showDropdown: false,
      x: 0,
      y: 0,
      toggler: null,
      rect: null,
      dropdownEl: null,
      dropdown: null,
      isOutside: false,
    };
  },

  computed: {
    ...mapGetters(["language"]),
  },

  watch: {
    showDropdown() {
      this.$nextTick(() => {
        this.computeDropdownLocation();
      });
    },
  },

  mounted() {
    this.toggler = this.$refs.toggler;
    this.dropdownEl = this.$refs.dropdown;
    this.dropdown = this.$refs.dropdownEl;
    document.addEventListener("click", this.event);
    document.body.append(this.dropdown);
    this.computeDropdownLocation();
  },

  destroyed() {
    document.removeEventListener("click", this.event);
    if (document.body.contains(this.dropdown)) {
      document.body.removeChild(this.dropdown);
    }
  },

  methods: {
    computeDropdownLocation() {
      this.rect = this.toggler.getBoundingClientRect();
      this.x = this.rect.left + 36;
      this.y = this.rect.top;

      this.$nextTick(() => {
        const dropdownRect = this.dropdown.getBoundingClientRect();

        this.isOutside = dropdownRect.right > window.innerWidth;

        if (this.isOutside) {
          this.x = this.rect.left - dropdownRect.width - 16;
        }
      });
    },

    event(event) {
      const { target } = event;
      if (
        [...this.dropdownEl.querySelectorAll("*:not(button)")].includes(
          target
        ) ||
        [...this.dropdown.querySelectorAll("*:not(button)")].includes(target)
      ) {
        this.showDropdown = true;
      } else {
        this.close();
      }
    },

    close() {
      this.closeServicesDropdown();
      this.showDropdown = false;
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown {
  &__toggler {
    width: 24px;
    height: 24px;
    background: var(--bg-2);
    border-radius: var(--radius-3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background: url("../assets/icons/default/add_icon.svg") no-repeat center;
      background-size: 15px;
      transition: all 0.3s ease;
    }

    &--active:before,
    &:hover:before {
      transform: rotate(180deg);
    }
  }

  &__dropdown {
    position: fixed;
    z-index: 2;
    min-height: 100px;
    background: var(--bg-1);
    padding: 10px 20px;
    border: 1px solid var(--border-2);
    border-radius: var(--radius-1);
    box-shadow: 0px 3px 30px var(--shadow-4);
    width: 300px;
  }
  &__title {
    color: var(--text-5);
    margin-bottom: 15px;
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: var(--x-small);
    color: var(--text-2);
    padding: 6px 0;
  }

  &__item-title {
    margin-left: 10px;
  }

  &__content {
    position: relative;
  }

  &__body {
    max-height: 150px;
    overflow: auto;
    margin-bottom: 10px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    width: 44px;
    height: 27px;
    border-radius: var(--radius-1);

    &--submit {
      background-color: var(--bg-2);
      margin-right: 20px;
      background-image: url("../assets/icons/default/tick_icon.svg");
    }

    &--cancel {
      background: var(--bg-12);
      background-image: url("../assets/icons/default/x_icon_white.svg");
    }

    &--submit,
    &--cancel {
      background-repeat: no-repeat;
      background-size: 15px;
      background-position: center;
    }
  }
}
</style>
