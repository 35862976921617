import api from "@/api";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addCustomerBank: async (_, { customerId, name, account, address }) => {
      try {
        api({ requiresAuth: true }).post("/v1/banks", {
          customer_id: customerId,
          name,
          account,
          address,
        });
      } catch (err) {
        console.error(err);
      }
    },

    addUserBank: async (_, { userId, name, account }) => {
      try {
        api({ requiresAuth: true }).post("/v1/user-banks", {
          user_id: userId,
          name,
          account,
        });
      } catch (err) {
        console.error(err);
      }
    },

    updateCustomerBank: async (
      _,
      { bankId, customerId, name, account, address }
    ) => {
      try {
        await api({ requiresAuth: true }).patch(`/v1/banks/${bankId}`, {
          customer_id: customerId,
          name,
          account,
          address,
        });
      } catch (err) {
        console.error(err);
      }
    },

    updateUserBank: async (_, { bankId, userId, name, account }) => {
      try {
        await api({ requiresAuth: true }).patch(`/v1/user-banks/${bankId}`, {
          user_id: userId,
          name,
          account,
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
