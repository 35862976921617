<template>
  <div class="policies">
    <div class="table">
      <div class="table__header box box--default">
        <div class="table__label table__label--small">
          {{
            $t(`accountDetails.${"potentialPolicyTable"}.${"header"}.${"date"}`)
          }}
        </div>
        <div class="table__label table__label--medium">
          {{
            $t(
              `accountDetails.${"potentialPolicyTable"}.${"header"}.${"category"}`
            )
          }}
        </div>
        <div class="table__label table__label--large">
          {{
            $t(`accountDetails.${"potentialPolicyTable"}.${"header"}.${"text"}`)
          }}
        </div>
        <div class="table__label table__label--small">
          {{
            $t(`accountDetails.${"potentialPolicyTable"}.${"header"}.${"file"}`)
          }}
        </div>
        <div class="table__label table__label--medium">
          {{
            $t(
              `accountDetails.${"potentialPolicyTable"}.${"header"}.${"agent"}`
            )
          }}
        </div>
        <div class="table__label table__label--small">
          {{
            $t(
              `accountDetails.${"potentialPolicyTable"}.${"header"}.${"policy"}`
            )
          }}
        </div>
        <div class="table__label table__label--small table__label--last"></div>
      </div>

      <div class="table__body">
        <Loading v-if="detailsLoading" />
        <template v-else>
          <div v-if="!potentialPolicies.length" class="table__no-data">
            <NoData :type="'potential-policies'" />
          </div>
          <template v-else>
            <PotentialPolicy
              v-for="(policy, index) in potentialPolicies"
              :key="policy.id"
              :policy="policy"
              :index="index"
            />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import NoData from "@/partials/NoData";
import Loading from "@/partials/Loading";
import PotentialPolicy from "@/components/AccountDetails/PotentialPolicy";
export default {
  name: "PotentialPolicyTable",

  components: {
    NoData,
    Loading,
    PotentialPolicy,
  },

  props: {
    potentialPolicies: {
      required: true,
      type: Array,
    },

    detailsLoading: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss"></style>
