<template>
  <div
    class="price"
    :class="[
      { 'animate__animated animate__zoomOutLeft': isDeleting },
      { 'price--deleting': wasDeleted },
    ]"
  >
    <div class="column column--branch">
      <span>{{ priceCompany.company.name }}</span>
    </div>
    <div class="column column--agent">
      <span @click="showPopupEditer = true"
        ><transition name="fade">
          <PopupEditer
            v-if="showPopupEditer"
            :selected="priceCompany.agent_provision * 100"
            @togglePopup="showPopupEditer = $event"
            @submit="changeAgentProvision($event)"
          />
        </transition>
        {{ (agentProvision * 100).toFixed(2) }}%</span
      >
    </div>
    <div class="column column--company">
      <span>{{ (priceCompany.company_provision * 100).toFixed(2) }}%</span>
    </div>

    <div class="icon icon__delete" @click="deletePrice()"></div>
  </div>
</template>

<script>
import PopupEditer from "@/partials/PopupEditer";
import { mapGetters } from "vuex";
export default {
  name: "PriceCompanyRow",

  components: {
    PopupEditer,
  },

  props: {
    priceCompany: {
      required: true,
      type: Object,
    },
    salesCategoryId: {
      required: true,
      type: Number
    },
    selectSale: {
      required: true,
      type: Function,
    },
    teamId: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      isDeleting: false,
      wasDeleted: false,
      showPopupEditer: false,
      agentProvision: "",
      agentProvisionCopy: "",
    };
  },

  computed: {
    ...mapGetters(["language"]),
  },

  async mounted() {
    this.agentProvision = this.priceCompany.agent_provision;
    this.agentProvisionCopy = this.priceCompany.agent_provision;
    await this.$root.$on('priceCompanyAdded', () => {
      // your code goes here
      this.reloadData();
    });
  },

  methods: {
    async deletePrice() {
      this.isDeleting = true;
      await this.$store.dispatch("deleteSaleCategoryPriceCompany", this.priceCompany.id);
      this.wasDeleted = true;

      setTimeout(async () => {
        await this.$store.dispatch("getSaleCategories");
        this.selectSale(this.price.sales_category_id);
      }, 300);
    },
    async reloadData() {
      await this.$store.dispatch("getSaleCategories");
      this.selectSale(this.salesCategoryId);
    },
    async changeAgentProvision(provision) {
      if (
        provision <= 100 &&
        provision > 0 &&
        provision !== this.agentProvisionCopy
      ) {
        this.agentProvision = provision / 100;
        await this.$store.dispatch("updateSaleCategoryPriceCompany", {
          saleCategoryPriceCompanyId: this.priceCompany.id,
          provision,
        });
        this.reloadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.price {
  border-bottom: 1px solid var(--border-15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  position: relative;
  max-height: 47px;
  transition: all 0.3s ease;
background: #F8FAFB;
  &--deleting {
    padding: 0px 20px;
    max-height: 0px;
    overflow: hidden;
    border: 0;
  }

  .icon {
    position: absolute;
    right: 25px;
  }
}
</style>
