<template>
<div class="search-filter" ref="searchFilter" v-clickOutside="toggleSearchFilter">
    <div class="search-filter__content">
        <form class="search-filter__form" @submit.prevent="search()">
            <div class="search-filter__title">Apply Filter</div>
            <div class="search-filter__row">
                <div class="search-filter__column">
                    <MultiSelectDropdown :items="resource.companyTypes" placeholder="Type" label="type" @input="companyTypes = $event" :reduce="(type) => type.id" :selected="companyTypes" />
                </div>
                <div class="search-filter__column" v-if="$route.name === 'Customers'">
                    <MultiSelectDropdown :items="resource.companies" placeholder="Company" label="name" @input="companies = $event" :reduce="(company) => company.id" :selected="companies" v-if="$route.name === 'Customers'" />
                </div>
                <div class="search-filter__column">
                    <MultiSelectDropdown :items="resource.branchCategories" placeholder="Category" :label="`long_${language}`" @input="branchCategories = $event" :reduce="(category) => category.id" :selected="branchCategories" />
                </div>
            </div>
            <div class="search-filter__row">
                <div class="search-filter__column" v-if="$route.name === 'Customers'">
                    <MultiSelectDropdown :items="resource.policyStatuses" placeholder="Status" label="name" @input="policyStatuses = $event" :reduce="(status) => status.id" :selected="policyStatuses" v-if="$route.name === 'Customers'" />
                </div>
                <div class="search-filter__column">
                    <MultiSelectDropdown :items="resource.agents" placeholder="Agent" @input="agents = $event" :customLabel="(agent) => `${agent.first_name} ${agent.last_name}`" :reduce="(agent) => agent.id" :selected="agents" />
                </div>
                <div class="search-filter__column" v-if="$route.name === 'PotentialCustomers'">
                    <MultiSelectDropdown :items="resource.priorities" placeholder="Priorities" @input="priorities = $event" label="type" :reduce="(priority) => priority.id" :selected="priorities" />
                </div>

                <div class="search-filter__column " v-if="$route.name === 'Customers'">
                    <VSelect :options="terminationsArr" :reduce="(termination) => termination.value" placeholder="Kündigungen" label="name" v-model="selectedTermination" class="select select--default select-column" />
                </div>
            </div>

            <div class="divider">
                <div class="line-divider"></div>
                <div class="text-divider" @click="moreFilters = !moreFilters">
                    <p v-if="moreFilters">Less Filters</p>
                    <p v-else>More Filters</p>
                    <img src="../assets/icons/default/Bold 2px-chevron_down.svg" :style="textDividerStyle">
                </div>
            </div>

            <transition name="slide-fade-in">
                <div v-if="moreFilters">
                    <div class="search-filter__row" style="margin-bottom: 10px !important;">
                        <div class="search-filter__column search-filter__column--inputs"></div>
                        <div class="search-filter__column search-filter__column--inputs">
                            <label class="date-label">Policy start date</label>
                        </div>
                        <div class="search-filter__column search-filter__column--inputs">
                            <label class="date-label">Policy eingereicht  date</label>
                        </div>
                    </div>

                    <div class="search-filter__row">
                        <div class="search-filter__column search-filter__column--inputs">
                            <div class="search-filter__column">  
                                <v-select
                                    :options="paymentStatusArr"
                                    :reduce="(status) => status.value"
                                    placeholder="Payment Status"
                                    label="name"
                                    v-model="paymentStatus"
                                    class="select select--default select-column"
                                >
                                <template #option="option">
                                    <div class="custom-option">
                                        <div class="icon custom-option-icon" :class="option.icon"></div>
                                        <div class="option-name">{{ option.name }}</div> 
                                    </div>
                                </template>
                                </v-select> 
                            </div>
                        </div>

                        <div class="search-filter__column search-filter__column--inputs">
                            <div class="form__field">
                                <div class="report__choose-details-datepickers">
                                    <Datepicker class="input input--default" :config="statics.datePickerConfig" placeholder="Von" id="startDateFromInput" ref="startDateFromInput" v-model="startDateFrom" />

                                    <div class="input input--default report__choose-details-seperator">-</div>

                                    <Datepicker class="input input--default" :config="statics.datePickerConfig" placeholder="Bis" id="startDateToInput" ref="startDateToInput" v-model="startDateTo" />
                                </div>
                            </div>
                        </div>

                        <div class="search-filter__column search-filter__column--inputs">
                            <div class="form__field">
                                <div class="report__choose-details-datepickers">
                                    <Datepicker class="input input--default" :config="statics.datePickerConfig" placeholder="Von" v-model="submitDateFrom" id="submitDateInput" ref="submitDateInput" />

                                    <div class="input input--default report__choose-details-seperator">-</div>

                                    <Datepicker class="input input--default" :config="statics.datePickerConfig" placeholder="Bis" v-model="submitDateTo" id="submitDateToInput" ref="submitDateToInput" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </transition>

            <transition name="slide-fade-in">
                <div v-if="moreFilters" class="search-filter__row" style="margin-bottom: 10px !important;">
                    <div class="search-filter__column search-filter__column--inputs"></div>
                    <div class="search-filter__column search-filter__column--inputs">
                        <label class="date-label">Policy end date</label>
                    </div>
                    <div class="search-filter__column search-filter__column--inputs"></div>
                </div>
            </transition>

            <div class="search-filter__row">
                <div class="search-filter__column search-filter__column--inputs">
                    <transition name="slide-fade-in">
                        <div class="search-filter__column" v-if="moreFilters">
                            <div class="birthday-zip-cont">
                                <Datepicker class="input input--default birthday" :config="statics.datePickerConfig" placeholder="Birthdate" id="birthday" v-model="birthday" />
                                <input type="text" class="input input--default" placeholder="Zip" v-model="zip" style="width: 45% !important;" />
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="search-filter__column search-filter__column--inputs">
                    <div class="search-filter__column">
                        <transition name="slide-fade-in">
                            <div class="form__field" v-if="moreFilters">
                                <div class="report__choose-details-datepickers">
                                    <Datepicker class="input input--default" :config="statics.datePickerConfig" placeholder="Von" v-model="endDateFrom" id="endDateFromInput" ref="endDateFromInput" />

                                    <div class="input input--default report__choose-details-seperator">-</div>

                                    <Datepicker class="input input--default" :config="statics.datePickerConfig" placeholder="Bis" v-model="endDateTo" id="endDateToInput" ref="endDateToInput" />
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="search-filter__column search-filter__column--inputs">
                    <transition name="slide-fade-in">
                        <div class="search-filter__column" v-if="$checkRolePermission('customer_export')">
                            <a v-show="!isRequesting" type="button" @click="xlsExport()" class="search-export__button button button--default">
                                Export
                                <img src="../assets/icons/default/export_files_icon.svg" alt="" />
                            </a>
                        </div>
                    </transition>
                </div>
            </div>
 
            <div v-show="isRequesting" ref="loadingButton" class="search-filter__button button button--default button--loading"></div>
            <button v-show="!isRequesting" type="button" @click="search()" class="search-filter__button button button--default">
                Apply Filter
            </button>

        </form>
    </div>
</div>
</template>

<script>
import statics from "@/statics";
import {
    mapGetters
} from "vuex";
import withPopper from "@/helpers/withPopper";
import MultiSelectDropdown from "@/partials/MultiSelectDropdown";
export default {
    name: "SearchFilter",

    components: {
        MultiSelectDropdown
    },

    props: {
        toggleSearchFilter: {
            required: true,
            type: Function,
        },
        filterToggler: {
            required: true,
            type: null,
        },
    },

    // directives: {
    //   clickOutside: {
    //     bind: (el, binding, vnode) => {
    //       el.clickEvent = (event) => { 
    //         if (
    //           event.target === vnode.context.filterToggler ||
    //           event.target === vnode.context.$refs.searchFilter || 
    //           [...el.getElementsByTagName("*")].includes(event.target)  ||
    //           event.target.closest('.flatpickr-calendar')  
    //         ) {
    //           vnode.context[binding.expression](true);
    //         } else {
    //           vnode.context[binding.expression](false);
    //         }
    //       };
    //       document.addEventListener("click", el.clickEvent);
    //     },
    //     unbind: (el) => {
    //       document.removeEventListener("click", el.clickEvent);
    //     },
    //   },
    // },

    directives: {
        clickOutside: {
            bind(el, binding, vnode) {
                el.mouseDown = false;

                el.handleMouseDown = () => {
                    el.mouseDown = true;
                };

                el.handleMouseUp = (event) => {
                    if (!el.mouseDown) return;

                    el.mouseDown = false;

                    const isClickInsideElement = (
                        event.target === vnode.context.filterToggler ||
                        event.target === vnode.context.$refs.searchFilter || [...el.getElementsByTagName("*")].includes(event.target) ||
                        event.target.closest('.flatpickr-calendar')
                    );

                    if (isClickInsideElement) {
                        vnode.context[binding.expression](true);
                    } else {
                        vnode.context[binding.expression](false);
                    }
                };

                document.addEventListener("mousedown", el.handleMouseDown);
                document.addEventListener("mouseup", el.handleMouseUp);
            },
            unbind(el) {
                document.removeEventListener("mousedown", el.handleMouseDown);
                document.removeEventListener("mouseup", el.handleMouseUp);
            },
        },
    },

    data() {
        return {
            moreFilters: false,

            statics,
            withPopper,
            isRequesting: false,
            birthday: "",
            zip: "",
            agents: [],
            companyTypes: [],
            companies: [],
            branchCategories: [],
            policyStatuses: [],
            priorities: [],
            searchKeywords: "",
            resource: {},
            export: false,

            selectedTermination: '',

            startDateFrom: "",
            startDateTo: "",

            endDateFrom: "",
            endDateTo: "",

            submitDateFrom: "",
            submitDateTo: "",

            paymentStatus: '',

            terminationsArr: [{
                    id: 1,
                    value: 'true',
                    name: 'Ja'
                },
                {
                    id: 2,
                    value: 'false',
                    name: 'Nien'
                }
            ],

            paymentStatusArr: [
                {
                    id: 1,
                    value: 'unpaid',
                    name: 'Unbezahlt',
                    icon: 'icon__payment'
                },
                {
                    id: 2,
                    value: 'pending',
                    name: 'Zahlung hinzugefügt (ausstehend)',
                    icon: 'icon__payment--pending'
                },
                {
                    id: 3,
                    value: 'paid',
                    name: 'Bezahlt',
                    icon: 'icon__payment--paid'
                }
            ]

 
        };
    },
    async created() {
        if (this.$route.name === "PotentialCustomers") {
            this.resource = this.searchPotentialCustomerForms;
        } else {
            this.resource = this.searchCustomerForms;
        }
        const {
            search,
            agents,
            companyTypes,
            companies,
            branchCategories,
            policyStatuses,
            priorities,
            birthday,
            zip,
            startDateFrom,
            startDateTo,
            endDateFrom,
            endDateTo,
            submitDateFrom,
            submitDateTo,
            has_terminations,
            payment_status
        } = this.$route.query;
        this.searchKeywords = search || "";
        this.agents = agents ? agents.split(",").map((i) => i) : [];
        this.companyTypes = companyTypes ?
            companyTypes.split(",").map((i) => i) :
            [];
        this.companies =
            companies && this.$route.name === "Customers" ?
            companies.split(",").map((i) => i) :
            [];
        this.branchCategories = branchCategories ?
            branchCategories.split(",").map((i) => i) :
            [];
        this.policyStatuses =
            policyStatuses && this.$route.name === "Customers" ?
            policyStatuses.split(",").map((i) => i) :
            [];
        this.priorities =
            priorities && this.$route.name === "PotentialCustomers" ?
            priorities.split(",").map((i) => i) :
            [];
        this.birthday = birthday || "";
        this.zip = zip || "";

        this.startDateFrom = startDateFrom || "";
        this.startDateTo = startDateTo || "";
        this.endDateFrom = endDateFrom || "";
        this.endDateTo = endDateTo || "";
        this.submitDateFrom = submitDateFrom || "";
        this.submitDateTo = submitDateTo || "";
        this.selectedTermination = has_terminations || '';

        this.paymentStatus = payment_status || '';
    },
    computed: {
        ...mapGetters([
            "searchCustomerForms",
            "searchPotentialCustomerForms",
            "language",
        ]),

        terminationValue() {
            if (this.selectedTermination === 'true') {
                return true;
            } else if (this.selectedTermination === 'false') {
                return false;
            }
            return ''
        },

        textDividerStyle() {
            return {
                transform: this.moreFilters ? 'scaleY(-1)' : 'scaleY(1)',
                transition: 'transform 0.3s ease'
            };
        }

    },
    watch: {
        startDateFrom(newDate) {
            if ((newDate > this.startDateTo) || !this.startDateTo) {
                this.startDateTo = newDate;
            }
            this.$nextTick(() => {
                const dateToInput = this.$refs.startDateToInput.fp;
                if (dateToInput) {
                    dateToInput.open();
                }
            });
        },
        startDateTo(newDate) {
            if ((newDate < this.startDateFrom) || !this.startDateFrom) {
                this.startDateFrom = newDate;
            }
        },

        endDateFrom(newDate) {
            if ((newDate > this.endDateTo) || !this.endDateTo) {
                this.endDateTo = newDate;
            }
            this.$nextTick(() => {
                const dateToInput = this.$refs.endDateToInput.fp;
                if (dateToInput) {
                    dateToInput.open();
                }
            });
        },
        endDateTo(newDate) {
            if ((newDate < this.endDateFrom) || !this.endDateFrom) {
                this.endDateFrom = newDate;
            }
        },

        submitDateFrom(newDate) {
            if ((newDate > this.submitDateTo) || !this.submitDateTo) {
                this.submitDateTo = newDate;
            }
            this.$nextTick(() => {
                const dateToInput = this.$refs.submitDateToInput.fp;
                if (dateToInput) {
                    dateToInput.open();
                }
            });
        },
        submitDateTo(newDate) {
            if ((newDate < this.submitDateFrom) || !this.submitDateFrom) {
                this.submitDateFrom = newDate;
            }
        }
    },
    methods: {
        openStartDateToPicker() {
            this.$nextTick(() => {
                const dateToInput = this.$refs.startDateToInput.fp;
                if (dateToInput) {
                    dateToInput.open();
                }
            });
        },

        async search() {
            this.isRequesting = true;

            if (this.$route.name === "Customers") {
                await this.$store.dispatch("searchCustomers", {
                    search: this.searchKeywords,
                    agents: this.agents,
                    companyTypes: this.companyTypes,
                    companies: this.companies,
                    branchCategories: this.branchCategories,
                    policyStatuses: this.policyStatuses,
                    birthday: this.birthday,
                    zip: this.zip,
                    has_terminations: this.terminationValue,

                    startDateFrom: this.startDateFrom,
                    startDateTo: this.startDateTo,
                    endDateFrom: this.endDateFrom,
                    endDateTo: this.endDateTo,
                    submitDateFrom: this.submitDateFrom,
                    submitDateTo: this.submitDateTo,

                    payment_status: this.paymentStatus
                });
                this.$router.replace({
                    query: {
                        search: this.searchKeywords,
                        agents: this.agents.join(","),
                        companyTypes: this.companyTypes.join(","),
                        companies: this.companies.join(","),
                        branchCategories: this.branchCategories.join(","),
                        policyStatuses: this.policyStatuses.join(","),
                        birthday: this.birthday,
                        zip: this.zip,
                        has_terminations: this.terminationValue,

                        startDateFrom: this.startDateFrom,
                        startDateTo: this.startDateTo,
                        endDateFrom: this.endDateFrom,
                        endDateTo: this.endDateTo,
                        submitDateFrom: this.submitDateFrom,
                        submitDateTo: this.submitDateTo,

                        payment_status: this.paymentStatus
                    },
                });
            } else if (this.$route.name === "Dashboard") {
                await this.$store.dispatch("searchCustomers", {
                    search: this.searchKeywords,
                    agents: this.agents,
                    companyTypes: this.companyTypes,
                    companies: this.companies,
                    branchCategories: this.branchCategories,
                    policyStatuses: this.policyStatuses,
                    birthday: this.birthday,
                    zip: this.zip,
                    has_terminations: this.terminationValue,

                    startDateFrom: this.startDateFrom,
                    startDateTo: this.startDateTo,
                    endDateFrom: this.endDateFrom,
                    endDateTo: this.endDateTo,
                    submitDateFrom: this.submitDateFrom,
                    submitDateTo: this.submitDateTo,

                    payment_status: this.paymentStatus
                });
                this.$router.replace({
                    path: "/customers",
                    query: {
                        page: 1,
                        search: this.searchKeywords,
                        agents: this.agents.join(","),
                        companyTypes: this.companyTypes.join(","),
                        companies: this.companies.join(","),
                        branchCategories: this.branchCategories.join(","),
                        policyStatuses: this.policyStatuses.join(","),
                        birthday: this.birthday,
                        zip: this.zip,
                        has_terminations: this.terminationValue,

                        startDateFrom: this.startDateFrom,
                        startDateTo: this.startDateTo,
                        endDateFrom: this.endDateFrom,
                        endDateTo: this.endDateTo,
                        submitDateFrom: this.submitDateFrom,
                        submitDateTo: this.submitDateTo,

                        payment_status: this.paymentStatus
                    },
                });
            }else if (this.$route.name === "PotentialCustomers") {
                await this.$store.dispatch("searchPotentialCustomers", {
                    search: this.searchKeywords,
                    agents: this.agents,
                    companyTypes: this.companyTypes,
                    branchCategories: this.branchCategories,
                    priorities: this.priorities,
                    birthday: this.birthday,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    zip: this.zip,
                });
                this.$router.replace({
                    query: {
                        search: this.searchKeywords,
                        agents: this.agents.join(","),
                        companyTypes: this.companyTypes.join(","),
                        branchCategories: this.branchCategories.join(","),
                        priorities: this.priorities.join(","),
                        birthday: this.birthday,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        zip: this.zip,
                    },
                });
            }
            this.isRequesting = false;
        },
        async xlsExport() {
            this.isRequesting = true;
            if (this.$route.name === "Customers") {
                await this.$store.dispatch("searchCustomers", {
                    search: this.searchKeywords,
                    agents: this.agents,
                    companyTypes: this.companyTypes,
                    companies: this.companies,
                    branchCategories: this.branchCategories,
                    policyStatuses: this.policyStatuses,
                    birthday: this.birthday,
                    zip: this.zip,
                    has_terminations: this.terminationValue,

                    startDateFrom: this.startDateFrom,
                    startDateTo: this.startDateTo,
                    endDateFrom: this.endDateFrom,
                    endDateTo: this.endDateTo,
                    submitDateFrom: this.submitDateFrom,
                    submitDateTo: this.submitDateTo,

                    payment_status: this.paymentStatus,
                    export: true
                });
            }
            this.isRequesting = false;

        }
    },
};
</script>

<style lang="scss" scoped> 

.custom-option{
    display: flex;
    align-items: center;
    gap:1em;
    width: 100%;
}

.icon__payment--pending{
    margin-right: 4px !important;
    margin-left: 2px !important;
}

.custom-option-icon{
     transform: scale(0.7);
}

.slide-fade-in-enter-active {
    transition-duration: 0.4s;
    transition-timing-function: ease-in;
}

.slide-fade-in-leave-active {
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-fade-in-enter-to,
.slide-fade-in-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-fade-in-enter,
.slide-fade-in-leave-to {
    overflow: hidden;
    max-height: 0;
}

.form__field {
    margin-bottom: 0 !important;
}

.divider {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px
}

.line-divider {
    flex-grow: 1;
    height: 1px;
    background-color: #B9C2CD;
}

.text-divider {
    color: var(--text-5);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
    }
}

.select-column {
    border: 1px solid transparent;
    background-color: var(--bg-1);
    box-shadow: 0px 5px 30px var(--shadow-4);
    color: var(--color-9) !important;
}

.birthday-zip-cont {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.date-label {
    color: var(--text-5);
    font-family: var(--muli-text-semi-bold);
    font-size: 14px;
    font-style: italic;
}

.search-filter {
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--bg-1);
    width: 900px;
    padding: 60px;
    z-index: 2;
    box-shadow: 0px 5px 30px var(--shadow-4);
    border-radius: var(--radius-3);

    .search-export__button {
        box-shadow: none;
        width: 100% !important;
        background-color: #B9C2CD;
        height: 40px;
        display: flex;
        padding: 0 10px;
        font-size: 16px;
        margin: 0 !important;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-family: var(--muli-text-semi-bold);
        color: #FFFFFF;
        border-radius: 10px;
        transition: 0.3s ease;

        &:hover {
            background: var(--text-5);
        }

        img {
            width: 27px;
            transition: 0.3s ease;
        }
    }

    &__title {
        color: var(--text-5);
        font-family: var(--muli-text-semi-bold);
        font-size: var(--medium);
        margin-bottom: 50px;
    }

    &__row,
    &__column--inputs {
        display: flex;
    }

    &__row {
        margin-bottom: 40px;
    }

    &__column {
        flex: 1;
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__button {
        margin: 60px auto 0 auto !important;
    }
}

.report {
    &__choose-details {
        height: calc(100% - 220px - 40px);
        padding: 45px 15px 0 15px;

        &-datepickers,
        &-seperator {
            display: flex;
        }

        &-datepickers {
            background: var(--bg-3);
            border-radius: var(--radius-1);
            border: 1px solid transparent;

            &--error {
                background-color: var(--highlight-2);
                border-color: var(--border-9);
            }
        }

        &-month {
            flex: 1;
            margin-right: 15px;
        }

        &-year {
            width: 80px;
        }

        &-seperator {

            border-radius: 0;
            align-items: center;
            justify-content: center;
        }

        &-input {
            width: 55px;
            height: 28px;
            background: var(--bg-16);
            border-radius: var(--radius-1);
            text-align: center;
            color: var(--text-2);
        }
    }
}
</style>
