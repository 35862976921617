<template>
  <div class="modal confirmation-modal">
    <div class="confirmation-modal__panel">
      <div class="modal__main-icon modal__main-icon--reset-password"></div>
      <div class="confirmation-modal__body">
        Are you sure you want to Reset The User Password?
      </div>
      <div class="confirmation-modal__footer">
        <button
          type="button"
          class="confirmation-modal__button confirmation-modal__button--cancel"
          @click="$store.commit('setShowResetPasswordModal', false)"
        >
          Cancel
        </button>
        <div
          v-if="isRequesting"
          class="button button--default button--loading confirmation-modal__button confirmation-modal__button--submit"
        ></div>
        <button
          v-else
          @click="resetPassword()"
          type="button"
          class="button button--default confirmation-modal__button confirmation-modal__button--submit"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPasswordModal",

  data() {
    return {
      isRequesting: false,
    };
  },

  methods: {
    async resetPassword() {
      try {
        this.isRequesting = true;
        const {
          data: { message },
        } = await this.$store.dispatch(
          "resetUserPassword",
          this.$route.params.id
        );
        this.$store.commit("setToast", {
          display: true,
          type: "success",
          message,
        });
        this.isRequesting = false;
        this.$store.commit("setShowResetPasswordModal", false);
      } catch (err) {
        this.isRequesting = false;
        console.log(err);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
