<template>
  <div
    class="toast"
    :class="[`toast--${toast.type}`, { 'toast--show': toast.display }]"
  >
    <div class="toast__icon"></div>
    <div class="toast__badge"></div>
    <div class="toast__content">
      <div class="toast__text">
        {{ toast.message }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Toast",

  watch: {
    toast(value) {
      if (value.display) {
        setTimeout(() => {
          this.$store.commit("setToast", {
            display: false,
            type: value.type,
            message: value.message,
          });
        }, 3000);
      }
    },
  },

  computed: {
    ...mapGetters(["toast"]),
  },
};
</script>

<style scoped lang="scss">
$typeColors: (
  "success": #18a275,
  "error": #cd1010,
);

.toast {
  position: absolute;
  z-index: 99;
  left: 50%;
  top: 0;
  transform: translate(-50%, -150%);
  border-radius: var(--radius-1);
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  padding: 10px 40px 10px 14px;
  height: 70px;

  &--show {
    transform: translate(-50%, 70px);
  }

  &__badge {
    height: 100%;
    width: 2px;
    border-radius: 10px;
    margin: 0 10px;
    background: var(--bg-1);
  }

  &__icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
  }

  &__text {
    color: var(--text-1);
    font-size: var(--small);
    word-break: break-all;
    line-height: 20px;
  }

  &--success {
    background: map-get($typeColors, "success");

    .toast__icon {
      background: url("../assets/icons/default/succes_icon.svg");
    }
  }

  &--error {
    background: map-get($typeColors, "error");

    .toast__icon {
      background: url("../assets/icons/default/error_icon.svg");
    }
  }
}
</style>
