<template>
  <div
    class="policy-wrapper"
    :class="[
      `policy-wrapper--${policyStatusColor}`,
      { 'policy-wrapper--expanded': showMoreDetails },
    ]"
  >
    <div class="policy" :class="{ 'policy--expanded': showMoreDetails }">
      <div
        class="row row--policy box box--default"
        :class="{ 'row--active': showMoreDetails }"
      >
        <div
          class="row__data row__data--medium row__data--underline row__data--fixed"
        >
          <span @click="showStatusPopup = true">
            <transition name="fade" mode="in-out">
              <PopupEditer
                v-if="showStatusPopup"
                @togglePopup="showStatusPopup = $event"
                :items="policyStatuses"
                :selected="policyStatus"
                :label="'name'"
                @submit="updatePolicyStatus($event)"
              />
            </transition>
            {{ policyStatus }}
          </span>
        </div>
        <div class="row__data row__data--medium">{{ policyNumber }}</div>
        <div class="row__data row__data--large policy__company">
          <span>{{ company }}</span>
        </div>
        <div class="row__data row__data--medium">
          {{ branch }}
        </div>
        <div class="row__data row__data--medium">{{ damageFrom }}</div>
        <div class="row__data row__data--medium">{{ damageTo }}</div>
        <div class="row__data row__data--medium">{{ price }}</div>
        <div
          class="row__data row__data--medium row__data--add-accident icon__add--active"
          @click="showAccidentModal()"
        ></div>
        <div
          class="row__data row__data--medium row__data--half row__data--last"
        >
          <div
            class="row__data row__data--small"
            :class="[
              { 'row__data--rotate': showMoreDetails },
              { 'row__data--more': policy.accidents.length },
            ]"
            @click="toggleMoreDetails()"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="policy-wrapper__accidents"
      ref="accidents"
      :style="{
        maxHeight: showMoreDetails ? `${containerMaxHeight}px` : '0px',
      }"
    >
      <AccidentsTable
        :accidents="accidents"
        :openPolicyFilesModal="openPolicyFilesModal"
      />
    </div>
  </div>
</template>

<script>
import statics from "@/statics";
import PopupEditer from "@/partials/PopupEditer";
import { mapGetters } from "vuex";
import AccidentsTable from "@/components/AccountDetails/AccidentsTable";
import moment from "moment";
import { formatDisplayDate } from "@/helpers/dateFormatters";
import { fetchGlobalCustomer } from "@/helpers/accountDetailHelpers";
export default {
  name: "AccidentsPolicy",

  components: {
    PopupEditer,
    AccidentsTable,
  },

  props: {
    policy: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      statics,
      moment,
      formatDisplayDate,
      fetchGlobalCustomer,
      showMoreDetails: false,
      showStatusPopup: false,
      acceptedId: 2,
      declinedId: 3,
      containerMaxHeight: 0,
      bottomOffsetMargin: 10,
      accidentHeight: 66,
    };
  },

  watch: {
    customer() {
      this.calculateContainerMaxHeight();

      if (!this.policy.accidents.length) {
        this.showMoreDetails = false;
      }
    },
  },

  computed: {
    ...mapGetters(["policyForms", "customer"]),
    policyStatus() {
      return this.policy.policy_status
        ? this.policy.policy_status.name
        : statics.empty;
    },

    policyNumber() {
      return this.policy.policy_number || statics.empty;
    },

    company() {
      return this.policy.company
        ? this.policy.company.full_name || statics.empty
        : statics.empty;
    },

    branch() {
      return this.policy.policy_services.length
        ? [
            ...new Set(
              this.policy.policy_services.map((service) => {
                if (service.service) {
                  return service.service.branch_category
                    ? service.service.branch_category.long_de
                    : statics.empty;
                } else {
                  return "";
                }
              })
            ),
          ].join(" / ")
        : statics.empty;
    },

    policyStatusColor() {
      return this.policy.policy_status
        ? statics.policyStatuses[
            statics.policyStatuses.findIndex(
              (status) => this.policy.policy_status.id === status.id
            )
          ].name
        : null;
    },
    policyStatuses() {
      return this.policyForms["policy-statuses"];
    },

    // The date must be validated on a specific case
    damageFrom() {
      return this.policy.accidents.length
        ? this.formatDisplayDate(this.policy.accidents[0].date)
        : statics.empty;
    },

    // The date must be validated on a specific case
    damageTo() {
      if (this.policy.accidents.length) {
        const { accidents } = this.policy;
        const lastAccident = accidents[accidents.length - 1];
        if (
          lastAccident.accident_status.id === this.acceptedId ||
          lastAccident.accident_status.id === this.declinedId
        ) {
          return this.formatDisplayDate(lastAccident.date);
        }
      }
      return statics.empty;
    },

    price() {
      if (this.policy.accidents.length) {
        const { accidents } = this.policy;
        const lastAccident = accidents[accidents.length - 1];
        if (
          lastAccident.accident_status.id === this.acceptedId ||
          lastAccident.accident_status.id === this.declinedId
        ) {
          return lastAccident.price;
        }
      }
      return statics.empty;
    },

    accidents() {
      if (this.policy.accidents.length) {
        return [...this.policy.accidents].sort((acc, accident) =>
          acc.accident_status.id < accident.accident_status.id ? -1 : 1
        );
      }
      return [];
    },
  },

  mounted() {
    this.calculateContainerMaxHeight();
  },

  methods: {
    calculateContainerMaxHeight() {
      this.containerMaxHeight =
        [...this.$refs.accidents.children].reduce(
          (acc, child) => acc + child.clientHeight,
          0
        ) +
        this.policy.accidents.length * this.accidentHeight +
        this.policy.accidents.map((accident) => accident.childrens).flat()
          .length *
          this.accidentHeight +
        this.bottomOffsetMargin;
    },

    async updatePolicyStatus(policyStatusId) {
      this.policyLoading = true;
      await this.$store.dispatch("updatePolicyStatus", {
        policyId: this.policy.id,
        policyStatusId,
      });
      await this.fetchGlobalCustomer(this.$route.params.id);
      this.showStatusPopup = false;
      this.policyLoading = false;
    },

    toggleMoreDetails() {
      if (this.policy.accidents.length) {
        this.showMoreDetails = !this.showMoreDetails;
      }
    },

    showAccidentModal() {
      this.$store.commit("setAccidentPolicyId", this.policy.id);
      this.$store.commit("setShowAddAccidentModal", true);
    },

    openPolicyFilesModal(id, files, type) {
      this.$store.commit("setPolicyFileId", id);
      this.$store.commit("setPolicyFiles", files);
      if (type === "accident") {
        this.$store.commit("setPolicyFilesModalIsAccident", true);
      } else if (type === "accidentChildren") {
        this.$store.commit("setPolicyFilesModalIsAccidentChildren", true);
      }
      this.$store.commit("setShowPolicyFilesModal", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-wrapper {
  border-radius: var(--radius-1);
  margin-bottom: 25px;
}
.policy {
  margin-bottom: 0;
}
</style>
