import api from "@/api";

export default {
  state: {
    zipCodes: [],
    zipCodeData: [],
  },
  getters: {
    zipCodes: (state) => state.zipCodes,
    zipCodeData: (state) => state.zipCodeData,
  },
  mutations: {
    setZipCodes: (state, data) => {
      state.zipCodes = data;
    },
    setZipCodeData: (state, data) => {
      state.zipCodeData = data;
    },
  },
  actions: {
    getZipCodes: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get("/v1/zip-codes");
        const { data } = response.data;
        commit("setZipCodes", data);
      } catch (err) {
        console.error(err);
      }
    },

    getZipCodeData: async ({ commit }, zipCode) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          `/v1/zip-codes/${zipCode}`
        );
        const { data } = response.data;
        commit("setZipCodeData", data);
      } catch (err) {
        commit("setZipCodeData", []);
        console.error(err);
      }
    },
  },
};
