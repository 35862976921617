<template>
  <div class="policy-invoice">
    <div class="policy-invoice__row">
      <div
        class="policy-invoice__data row__data--medium policy-invoice__data--column"
      >
        <div class="policy-invoice__label">{{ status }}</div>
        <div>{{ `${invoice.price} CHF` }}</div>
      </div>
      <div
        class="policy-invoice__data row__data--medium policy-invoice__data--column"
      >
        <div class="policy-invoice__label">{{ status }}</div>
        <div>{{ formatDisplayDate(invoice.date) }}</div>
      </div>
      <div class="policy-invoice__data row__data--medium policy-invoice__pdf">
        <div
          class="icon icon__pdf"
          :class="{ 'icon__pdf--active': invoice.policy_invoice_files.length }"
        ></div>
        {{ status }}
      </div>
      <div class="policy-invoice__data row__data--medium row__data--underline">
        <div
          class="policy-invoice__badge"
          :class="`policy-invoice__badge--${statusColor}`"
        ></div>
        <span @click="showPaidStatusPopup = true">
          <transition name="fade" mode="in-out">
            <PopupEditer
              v-if="showPaidStatusPopup"
              @togglePopup="showPaidStatusPopup = $event"
              :items="paidStatuses"
              :selected="paidStatus"
              :label="'title'"
              @submit="updatePaidField($event)"
            />
          </transition>
          {{ paidStatus }}
        </span>
      </div>
      <div
        class="policy-invoice__data row__data--large policy-invoice__price policy-invoice__data--column"
      >
        <div>{{ `${invoice.price} CHF` }}</div>
        <div class="policy-invoice__sum" v-if="isLast">
          Total Offen: {{ priceSum }} CHF
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopupEditer from "@/partials/PopupEditer";
import { formatDisplayDate } from "@/helpers/dateFormatters";
import { fetchGlobalCustomer } from "@/helpers/accountDetailHelpers";
import statics from "@/statics";
export default {
  name: "Invoice",

  components: {
    PopupEditer,
  },

  props: {
    invoice: {
      required: true,
      type: Object,
    },

    isLast: {
      required: true,
      type: Boolean,
    },

    priceSum: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      fetchGlobalCustomer,
      statics,
      formatDisplayDate,
      showPaidStatusPopup: false,
    };
  },

  computed: {
    status() {
      return this.invoice.status.name;
    },

    statusColor() {
      return this.statics.invoiceStatuses.find(
        (status) => status.id === this.invoice.status.id
      ).name;
    },

    paidStatuses() {
      return [
        { title: "Paid", id: 1 },
        { title: "Unpaid", id: 2 },
      ];
    },

    paidStatus() {
      return this.invoice.paid ? "Paid" : "Unpaid";
    },
  },

  methods: {
    async updatePaidField(paid) {
      await this.$store.dispatch("updatePaidField", {
        invoiceId: this.invoice.id,
        paid: paid === 1 ? true : false,
      });
      await fetchGlobalCustomer(this.$route.params.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-invoice {
  border-bottom: 1px solid var(--border-7) !important;

  &:last-child {
    border: 0 !important;
  }

  &__data {
    margin-left: 40px;

    &--column {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .icon__pdf {
      margin-right: 10px;
    }
  }

  &__price {
    align-items: flex-end;
    padding-right: 70px;
  }

  &__label,
  &__price,
  &__sum,
  &__pdf {
    color: var(--text-9);
  }

  &__label {
    font-family: var(--muli-text-bold);
    margin-bottom: 5px;
  }

  &__sum {
    font-family: var(--muli-text-bold);
    margin-top: 7px;
    padding-top: 7px;
    border-top: 1px solid var(--border-12);
  }

  &__badge {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    margin-right: 10px;

    &--operation {
      background: #db2222;
    }

    &--warning {
      background: #ff9e0f;
    }

    &--reminder {
      background: #ffeb3b;
    }
  }
}
</style>
