import api from "@/api";
import router from "@/router";

export default {
  state: {
    reports: [],
  },
  getters: {
    reports: (state) => state.reports,
  },
  mutations: {
    setReports: (state, data) => {
      state.reports = data;
    },
  },
  actions: {
    getReports: async ({ commit }) => {
      try {
        const {
          userId,
          paidStatus,
          month,
          year,
          ahv,
          bvg,
          bvgValue,
          startDate,
          endDate,
        } = router.history.current.query;

        let payload = {
          paid_status: paidStatus === "true",
          user_id: userId,
          month: month,
          year: year,
          ahv: ahv === "true",
          bvg: bvg === "true",
          bvg_value: bvg === "true" ? bvgValue : 0,
        };

        if (startDate && endDate) {
          payload = {
            ...payload,
            start_date: startDate,
            end_date: endDate,
          };
        }

        const {
          data: { data },
        } = await api({ requiresAuth: true }).post("/v1/reports", {
          ...payload,
        });

        commit(
          "setReports",
          data.map((period) => {
            for (let p in period) {
              return { ...period[p], period: p };
            }
          })
        );
      } catch (err) {
        console.error(err);
      }
    },
    getPaymentReport: async ({ commit }) => {
      try {
        const {
          userId,
          paidStatus,
          month,
          year,
          ahv,
          bvg,
          bvgValue,
          startDate,
          endDate,
        } = router.history.current.query;

        let payload = {
          paid_status: paidStatus === "true",
          user_id: userId,
          month: month,
          year: year,
          ahv: ahv === "true",
          bvg: bvg === "true",
          bvg_value: bvg === "true" ? bvgValue : 0,
        };

        if (startDate && endDate) {
          payload = {
            ...payload,
            start_date: startDate,
            end_date: endDate,
          };
        }

        const {
          data: { data },
        } = await api({ requiresAuth: true }).post("/v1/payment-report", {
          ...payload,
        });

        commit(
          "setReports",
          data.map((period) => {
            for (let p in period) {
              return { ...period[p], period: p };
            }
          })
        );
      } catch (err) {
        console.error(err);
      }
    },

    makePayment: (_, data) =>
      api({ requiresAuth: true }).patch("/v1/reports/payments", {
        ...data,
      }),
  },
};
