<template>
  <div class="row__dropdown" ref="dropdown">
    <div
      class="row__dropdown-item row__dropdown-item--edit icon__edit"
      @click="onEdit()"
      ref="dropdownEdit"
    >
      Edit
    </div>
    <div
      class="row__dropdown-item row__dropdown-item--delete icon__delete"
      @click="onDelete()"
      ref="dropdownDelete"
    >
      Delete
    </div>
  </div>
</template>

<script>
export default {
  name: "RowActionDropdown",

  props: {
    onEdit: {
      required: true,
      type: Function,
    },
    onDelete: {
      required: true,
      type: Function,
    },
  },

  mounted() {
    document.addEventListener("click", this.checkIfClickedInside);
  },

  destroyed() {
    document.removeEventListener("click", this.checkIfClickedInside);
  },

  methods: {
    checkIfClickedInside(event) {
      const { target } = event;
      const { dropdown, dropdownEdit, dropdownDelete } = this.$refs;
      if (
        target === dropdown.parentElement ||
        target === dropdownEdit ||
        target === dropdownDelete
      ) {
        this.$emit("toggleDropdown", true);
      } else {
        this.$emit("toggleDropdown", false);
      }
    },
  },
};
</script>

<style></style>
