<template>
  <div
    class="drop"
    :class="{ 'drop--over': isDragOver }"
    @dragenter="dragging($event)"
    @dragleave="dragLeave($event)"
    @dragover.prevent
    @drop="dropFile($event)"
  >
    <div class="drop__icon" v-if="!files.length"></div>
    <div class="files" v-else>
      <div class="file" v-for="(file, index) in files" :key="index">
        <div class="file__type" :class="`file__type--${file.type}`"></div>
        <div class="file__name">
          {{ file.name }}
        </div>
        <div
          v-if="deletable"
          class="file__delete"
          @click="deleteFile(index)"
        ></div>
      </div>
    </div>
    <div class="drop__title">
      {{ $t(`dropFile.${"title"}`) }}
    </div>
    <button
      type="button"
      class="drop__button button button--default button--shadow"
      @click="openSingleFileDialog()"
    >
      {{ $t(`dropFile.${"button"}`) }}
      <input
        type="file"
        class="drop__input"
        ref="inputFile"
        @input="selectSingleFile($event)"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: "DropFile",

  props: {
    deletable: {
      required: false,
      type: Boolean,
      default: true,
    },

    bus: {
      required: false,
      type: Object,
    },
  },

  data() {
    return {
      files: [],
      isDragOver: false,
    };
  },

  mounted() {
    if (this.bus) {
      this.bus.$on("delete", () => {
        this.files = [];
      });
    }
  },

  methods: {
    dropFile(event) {
      this.isDragOver = false;
      event.preventDefault();
      const files = [...event.dataTransfer.items].map((f) => {
        const file = f.getAsFile();
        const { name } = file;
        const mimeType = this.parseFileExtension(name);
        return {
          name,
          type: mimeType,
          file,
        };
      });
      this.files = [...this.files, ...files];
      this.$emit("input", this.files);
    },

    openSingleFileDialog() {
      const { inputFile } = this.$refs;
      inputFile.click();
    },

    selectSingleFile(event) {
      const { files } = event.target;
      const file = [...files][0];
      const { name } = file;
      const mimeType = this.parseFileExtension(name);
      this.files = [
        ...this.files,
        {
          name,
          type: mimeType,
          file,
        },
      ];
      this.$emit("input", this.files);
    },

    parseFileExtension(name) {
      const mimeTypeParts = name.split(".");
      let mimeType = mimeTypeParts[mimeTypeParts.length - 1].toLowerCase();
      if (mimeType === "jpg" || mimeType === "png" || mimeType === "svg") {
        mimeType = "image";
      }
      return mimeType;
    },

    deleteFile(index) {
      this.files.splice(index, 1);
    },

    dragging(event) {
      event.preventDefault();
      this.isDragOver = true;
    },

    dragLeave(event) {
      event.preventDefault();
      this.isDragOver = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.drop {
  background: var(--bg-3);
  border-radius: var(--radius-1);
  min-width: 228px;
  max-width: 228px;
  min-height: 220px;
  max-height: 220px;
  position: relative;
  padding: 8px;

  &--over {
    background: var(--bg-1);
    box-shadow: 0px 7px 20px var(--shadow-3);

    * {
      pointer-events: none;
    }
  }

  &__icon {
    width: 76px;
    height: 76px;
    position: relative;
    margin: 35px auto;
    pointer-events: none;

    &::after,
    &::before {
      position: absolute;
      content: "";
      display: block;
      height: 100%;
      width: 100%;
    }

    &::after {
      background: url("../assets/icons/default/Bold 2px-arrow_up_down.svg")
        no-repeat center;
    }

    &::before {
      background: url("../assets/icons/default/Bold 2px-file_add.svg") no-repeat
        center;
    }
  }

  .files {
    height: 138px;
    max-height: 138px;
    overflow: auto;
    width: 100%;
    background: var(--bg-13);
    border-radius: var(--radius-1);
  }

  .file {
    padding: 10px;
    display: flex;
    align-items: center;
    &__name {
      color: var(--text-2);
      font-size: var(--x-smaller);
      margin: 0 10px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__delete {
      background: url("../assets/icons/default/x_delete_icon.svg") no-repeat;
      min-width: 12px;
      min-height: 12px;
      cursor: pointer;
    }

    &__type {
      min-height: 24px;
      min-width: 24px;
      &--pdf {
        background: url("../assets/icons/file-types/pdf_icon.svg") no-repeat;
      }
      &--image {
        background: url("../assets/icons/file-types/image_icon.svg") no-repeat;
      }
      &--file {
        background: url("../assets/icons/file-types/file_icon.svg") no-repeat;
      }
      &--doc {
        background: url("../assets/icons/file-types/doc_icon.svg") no-repeat;
      }
    }
  }

  &__title {
    color: var(--text-2);
    font-family: var(--muli-text-semi-bold);
    text-align: center;
    margin: 10px 0;
  }

  &__button {
    height: 30px;
    width: 100px;
    font-family: var(--muli-text-regular);
    margin: auto;
  }

  &__title,
  &__button {
    font-size: var(--x-smaller);
  }

  &__input {
    opacity: 0;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: url("../assets/icons/default/dragndrop_border.svg") no-repeat;
    background-position: center;
    background-size: 100% 100%;
    pointer-events: none;
  }
}
</style>
