import api from "@/api";

export default {
  state: {
    companies: [],
    brokerCompanies: [],
    selectedCompany: {},
  },
  getters: {
    companies: (state) => state.companies,
    brokerCompanies: (state) => state.brokerCompanies,
    selectedCompany: (state) => state.selectedCompany,
  },
  mutations: {
      setCompanies: (state, data) => {
          state.companies = data;
      },
      setBrokerCompanies: (state, data) => {
          state.brokerCompanies = data;
      },

      setSelectedCompany: (state, data) => {
          state.selectedCompany = data;
      },

  },
  actions: {
    getCompanies: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get("/v1/companies");
        const {
          data: { data },
        } = response;
        commit("setCompanies", data);
      } catch (err) {
        console.error(err);
      }
    },

    getBrokerCompanies: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/broker-companies"
        );
        const { data } = response.data;
        commit("setBrokerCompanies", data);
      } catch (err) {
        console.error(err);
      }
    },

    getCompany: (_, companyId) =>
      new Promise((resolve, reject) => {
        api({ requiresAuth: true })
          .get(`/v1/companies/${companyId}`)
          .then(
            (response) => {
              const { data } = response.data;
              resolve(data);
            },
            (err) => {
              reject(err);
            }
          );
      }),

    addCompanyLogo: async (_, { companyId, file }) => {
      try {
        const formData = new FormData();
        formData.append("logo", file);
        await api({ requiresAuth: true }).post(
          `/v1/companies/${companyId}/files`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    addCompany: async (
      { dispatch, commit },
      {
        companyName,
        companyFullName,
        companyType,
        viewInApp,
        status,
        comment,
        address,
        zip,
        city,
        country,
        phone,
        fax,
        email,
        homepage,
        services,
        file,
      }
    ) => {
      try {
        const response = await api({ requiresAuth: true }).post(
          "/v1/companies",
          {
            name: companyName,
            full_name: companyFullName,
            address,
            zip,
            city,
            country_code: country,
            phone,
            fax,
            email,
            web: homepage,
            status,
            comment,
            branch_category_services: services,
            show_on_app: viewInApp,
            company_type: companyType,

          }
        );

        const { data } = response.data;

        if (file) {
          await dispatch("addCompanyLogo", { companyId: data.id, file });
        }

        commit("setToast", {
          display: true,
          type: "success",
          message: "Company Contact added successfully.",
        });
      } catch (err) {
        console.error(err);
      }
    },

    updateCompany: async ( 
      _,
      { 
        companyId, 
        name, 
        companyFullName, 
        companyType, 
        viewInApp, 
        status,
        comment,
        address,
        zip,
        city,
        country,
        phone,
        fax,
        email,
        homepage, 
        branchId, 
        services,

      }, ) =>
        await api({ requiresAuth: true }).patch(`/v1/companies/${companyId}`, {
          name,
          full_name: companyFullName,
          company_type: companyType,
          is_app: viewInApp,
          status,
          comment: comment,
          address,
          zip,
          city,
          country,
          phone,
          fax,
          email,
          homepage,
          branch_id: branchId,
          branch_category_services: services,
        }
      )
    },

  //   updateCompany: async (
  //     //{ dispatch },
  //     {
  //       companyId,
  //       name,
  //       companyFullName,
  //       companyType,
  //       viewInApp,
  //       status,
  //       comment,
  //       address,
  //       zip,
  //       city,
  //       country,
  //       phone,
  //       fax,
  //       email,
  //       homepage,
  //       branchId,
  //       services,
  //       //file // Added file parameter
  //     },
  //   ) => {
  //     try {
  //       // Update company details
  //       await api({ requiresAuth: true }).patch(`/v1/companies/${companyId}`, {
  //         name,
  //         full_name: companyFullName,
  //         company_type: companyType,
  //         is_app: viewInApp,
  //         status,
  //         comment: comment,
  //         address,
  //         zip,
  //         city,
  //         country,
  //         phone,
  //         fax,
  //         email,
  //         homepage,
  //         branch_id: branchId,
  //         branch_category_services: services,
  //       });
    
  //       // Check if a file is provided
  //       // if (file) {
  //       //   await dispatch("addCompanyLogo", { companyId, file });
  //       // }
    
  //       // Fetch updated company data if needed or show success message
  //       // commit("setToast", {
  //       //   display: true,
  //       //   type: "success",
  //       //   message: "Company Contact updated successfully.",
  //       // });
  //     } catch (err) {
  //       console.error(err);
  //       // Handle errors
  //     }
  //   },
    
  // },
}
