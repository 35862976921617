<template>
  <div class="customer row row--hover box box--default box--search-member " :class="{'disabled': isDisabled}">

    <div
      class="row__data row__data--small row__data--image "

    >
      <img v-if="customerThumbnail" :src="customerThumbnail" alt="" />
      <div v-else class="thumbnail thumbnail--customer" :style="color">
        {{ parseInitials(customer.first_name, customer.last_name) }}
      </div>
    </div>
    <div
      class="row__data row__data--name row__data--large"

    >
      {{ customerName }}
    </div>

    <div
      class="row__data row__data--address row__data--large"
      v-if="!this.selectingStatus"
    >
      {{ `${customerStreet} ${customerAddressNumber}` }}
    </div>
    <template   v-if="!this.selectingStatus">
      <div
          class="row__data row__data--zip row__data--small"

      >
        {{ customerZip }}
      </div>
      <div
          class="row__data row__data--city row__data--medium"

      >
        {{ customerCity }}
      </div>
      <div
          class="row__data  row__data--medium"
          v-if="isAdded"
      >

        <button
            class="row__button--add button button--default"
            :class="{'disabled': isDisabled, }"
            @click="removeClicked"

        >
          <span>Remove</span>
        </button>
      </div>
      <div
          class="row__data  row__data--medium"
          v-else
      >
        <button
            class="row__button--add button button--default"
            :class="{'disabled': isDisabled, }"
            @click="addClicked"

        >
          <span>Add</span>
        </button>
      </div>
    </template>
    <template v-else>
      <div
          class="row__data row__data--city row__data--large"

      >
      <VSelect
          class="select select--default select--model-dropdown "
          :options="this.familyStatuses"
          :reduce="(status) => status.id"
          label="name"
          placeholder="Relation"

          @input="updateFamilyId" :value="familyStatusId"

      />
      </div>
      <div
          class="row__data  row__data--small"

      >
        <div
            class="icon icon__delete"
            @click="removeClicked"

            ref="delete"
        >
        </div>

      </div>
    </template>

  </div>
</template>

<script>
import { parseCustomerName, parseInitials } from "@/helpers/entityParsers";

import statics from "@/statics";
import { formatDisplayDate } from "@/helpers/dateFormatters";
import { mapGetters } from "vuex";
export default {
  name: "CustomerFamilySearch",



  props: {
    customer: {
      required: true,
      type: Object,
    },
    selectingStatus: {
      required:true,
      type: Boolean
    },
    familyStatuses: {
      required: false,
      type: Array
    },
    familyStatusId: {
      required: false,
      type: Number
    },

  },

  data() {
    return {
      showActionsDropdown: false,
      parseInitials,
      formatDisplayDate,
      selectedInsurance: {},
      selectedCompany: {},

      isAdded: false,
    };
  },

  computed: {
    ...mapGetters(["language"]),
    isDisabled (){
      return  this.customer.members.length > 0;
    },

    customerName() {
      return parseCustomerName(this.customer);
    },
    customerStreet() {
      return this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].street
        : statics.empty;
    },
    customerAddressNumber() {
      return this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].number || ""
        : statics.empty;
    },
    customerZip() {
      return this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].zip
        : statics.empty;
    },
    customerCity() {
      return this.customer.customer_addresses.length
        ? this.customer.customer_addresses[0].city
        : statics.empty;
    },



    customerThumbnail() {
      return this.customer.photo ? this.customer.photo.thumbnail : null;
    },

    color() {
      return this.customer.color
        ? { background: this.customer.color }
        : { background: "#7f8fa4" };
    },
  },



  methods: {
    updateFamilyId(event){
      this.$emit('update:familyStatusId', event)
    },
    addClicked() {
      if(!this.isAdded && !this.isDisabled){
        this.isAdded = true;
        this.$emit('clicked-add-customer', this.customer);
      }

    },
    removeClicked() {
      if(!this.isDisabled){
        this.isAdded = false;
        this.$emit('clicked-remove-customer', this.customer);
      }

    },


  },
};
</script>

<style scoped lang="scss">
.customer__dropdown {
  width: 100%;
  background: 0;
}
.input--default, .select--default {
  background-color: var(--bg-3);
}
.box--search-member {
  box-shadow: 0px 2px 6px var(--shadow-7);
}
.table {

  .row {
    width: 98%;
    margin-left: 1%;
    margin-top: 5px;
    cursor: default;
    &.disabled{
      background: #F2F4F6;
    }
    &:hover{
      background: white;
    }

    .row__button--add {
      width: 100px;
      height: 38px;
      &.disabled, &.added{
        background: #B9C2CD;
        box-shadow: inherit;
      }
    }
  }
}
</style>
