<template>
  <div class="add modal modal--default">
    <div
      class="modal__panel"
      :class="showAddCustomerModal ? 'modal__panel--in' : 'modal__panel--out'"
    >
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="add__new">{{ $t(`addCustomerModal.${"title"}`) }}</div>

      <div class="add__title">{{ $t(`addCustomerModal.${"subTitle"}`) }}</div>

      <div class="customers">
        <div class="customer" @click="showAddCompanyModal()">
          <div class="customer__icon customer__icon--company"></div>
          <button class="add__button button button--default">
            {{ $t(`addCustomerModal.${"companyButton"}`) }}
          </button>
        </div>
        <div class="customer" @click="showAddPrivateModal()">
          <div class="customer__icon customer__icon--private"></div>
          <button class="add__button button button--default">
            {{ $t(`addCustomerModal.${"privateButton"}`) }}
          </button>
        </div>
        <div
          class="customer"
          v-if="!isAddingCustomer"
          @click="showAddUserModal()"
        >
          <div class="customer__icon customer__icon--user"></div>
          <button class="add__button button button--default">
            {{ $t(`addCustomerModal.${"userButton"}`) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AddCustomer",

  components: {},

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["showAddCustomerModal", "isAddingCustomer"]),
  },

  methods: {
    closeModal() {
      this.$store.commit("setIsAddingCustomer", false);
      this.$store.commit("setIsAddingPotentialCustomer", false);
      this.$store.commit("setShowAddCustomerModal", false);
      this.$store.commit("setIsAddingMember", false);
    },

    showAddCompanyModal() {
      this.$store.commit("setShowAddCustomerModal", false);
      this.$store.commit("setShowAddCompanyModal", true);
    },

    showAddPrivateModal() {
      this.$store.commit("setShowAddCustomerModal", false);
      this.$store.commit("setShowAddPrivateModal", true);
    },

    showAddUserModal() {
      this.$store.commit("setShowAddCustomerModal", false);
      this.$store.commit("setShowAddUserModal", true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/_add-panel-modal.scss";
</style>
