<template>
  <div class="users">
    <div class="page__header">
      <div class="page__title">{{ $t(`pageTitles.${"users"}`) }}</div>
      <div class="page__right">
        <form class="search" @submit.prevent="searchUsers()">
          <input
            class="search__search"
            :class="{ 'search--active': searchKeywords.length }"
            type="text"
            v-model="searchKeywords"
          />
        </form>
        <button
          v-if="$checkRolePermission('user_create')"
          class="users__button button button--default"
          @click="$store.commit('setShowAddUserModal', true)"
        >
          Add User
        </button>
      </div>
    </div>

    <div class="table">
      <Loading v-if="usersLoading" />
      <template v-else>
        <NoData v-if="!usersPerPage.data.length" :type="'users'" />
        <template v-else>
          <div class="table__header box box--default">
            <div class="table__label table__label--small"></div>
            <div class="table__label table__label--large">
              {{ $t(`users.${"tableHeader"}.${"name"}`) }}
            </div>
            <div class="table__label table__label--medium">
              {{ $t(`users.${"tableHeader"}.${"contact"}`) }}
            </div>
            <div class="table__label table__label--large">
              {{ $t(`users.${"tableHeader"}.${"team"}`) }}
            </div>
            <div class="table__label table__label--large">
              <!-- {{ $t(`users.${"tableHeader"}.${"team"}`) }} -->
              Company Name
            </div>
            <div class="table__label table__label--large">
              {{ $t(`users.${"tableHeader"}.${"address"}`) }}
            </div>
            <div class="table__label table__label--small">
              {{ $t(`users.${"tableHeader"}.${"zipCode"}`) }}
            </div>
            <div class="table__label table__label--medium">
              {{ $t(`users.${"tableHeader"}.${"city"}`) }}
            </div>
            <div class="table__label table__label--medium">
              {{ $t(`users.${"tableHeader"}.${"supervisor"}`) }}
            </div>
            <div class="table__label table__label--medium">
              {{ $t(`users.${"tableHeader"}.${"salesGroup"}`) }}
            </div>
            <div
              class="table__label table__label--small"
              v-if="$checkRolePermission('user_delete')"
            ></div>
          </div>

          <div class="table__body">
            <User
              v-for="user in usersPerPage.data"
              :key="user.id"
              :user="user"
              :deleteUser="deleteUser"
            />
          </div>
        </template>
      </template>
    </div>
    <Pagination
        :limit="3"
      :data="usersPerPage"
      @pagination-change-page="getResults"
    ></Pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import User from "@/components/Users/User";
import Loading from "@/partials/Loading";
import NoData from "@/partials/NoData";
export default {
  name: "Users",

  components: {
    User,
    Loading,
    NoData,
  },

  data() {
    return { searchKeywords: "" };
  },

  computed: {
    ...mapGetters(["usersLoading", "rolePermissions", "usersPerPage"]),
  },

  async created() {
    this.$store.commit("setUsersLoading", true);
    const { page } = this.$route.query;
    if (!page) {
      this.$router.replace({
        query: {
          page: 1,
        },
      });
    }
    await this.$store.dispatch("getUsersPage", { page });
    this.$store.commit("setUsersLoading", false);
  },

  methods: {
    deleteUser({ display, message, action, id }) {
      this.$store.commit("setDeleteModal", {
        display,
        message,
        action,
        id,
      });
    },

    async searchUsers() {
      this.$store.commit("setUsersLoading", true);
      if (this.searchKeywords) {
        await this.$store.dispatch("searchUsers", this.searchKeywords);
      } else {
        await this.$store.dispatch("getUsersPage", {
          page: this.$route.query.page,
        });
      }
      this.$store.commit("setUsersLoading", false);
    },

    async getResults(paginationPage) {
      let page = Number(this.$route.query.page);
      if (Number(paginationPage) !== page) {
        this.$router.replace({
          query: {
            page: paginationPage,
          },
        });
        page = Number(this.$route.query.page);
        this.$store.commit("setCurrentUsersPage", page);
        this.$store.commit("setUsersLoading", true);
        if (this.searchKeywords) {
          await this.$store.dispatch("getUsersPage", {
            page,
            search: this.searchKeywords,
          });
        } else {
          await this.$store.dispatch("getUsersPage", { page });
        }
        this.$store.commit("setUsersLoading", false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.users {
  height: 100%;

  &__button {
    width: 190px;
  }
  .table {
    margin-bottom: 20px;
    height: calc(100% - 40px - 20px - 20px - 30px);
  }
}

</style>
