import api from "@/api";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    deleteFile: async ({ commit }, uuid) => {
      try {
        await api({ requiresAuth: true }).delete(`/v1/media/${uuid}`);
        commit("setToast", {
          display: true,
          type: "success",
          message: "File deleted successfully.",
        });
      } catch (err) {
        console.error(err);
      }
    },

    renameFile: async ({ commit }, { uuid, name }) => {
      try {
        await api({ requiresAuth: true }).patch(`/v1/media/${uuid}`, {
          file_name: name,
        });
        commit("setToast", {
          display: true,
          type: "success",
          message: "File updated successfully.",
        });
      } catch (err) {
        console.error(err);
      }
    },

    changeFileProperties: async (_, { uuid, isApp, isPrivate }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/media/${uuid}/app/properties`,
          {
            is_private: isPrivate,
            is_app: isApp,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    openFile: async (_, file) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          `/v1/media/${file.uuid}`,
          {
            responseType: "blob",
          }
        );

        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", file.file_name);
        document.body.appendChild(fileLink);

        if (
          response.data.type === "application/pdf" ||
          response.data.type === "image/jpeg" ||
          response.data.type === "image/png"
        ) {
          const { width, height } = screen;
          window.open(
            fileURL,
            "_blank",
            `location=yes,width=${width},height=${height},scrollbars=yes,status=yes`
          );
        } else {
          fileLink.click();
        }
        document.body.removeChild(fileLink);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
