<template>
  <div
    class="comment"
    @mouseover="showPreview()"
    @mouseout="hidePreview()"
    :class="{ 'input--error': commentHasError }"
    ref="comment"
  >
    <template v-if="!readonly">
      <textarea v-model="commentToEdit"> </textarea>
      <div
        class="comment__submit"
        v-if="
          commentToEdit !== comment && (commentToEdit.trim('') || !isRequired)
        "
        @click="update()"
      ></div>
    </template>

    <template v-else>
      <textarea v-model="commentToEdit" readonly> </textarea>
    </template>

    <div
      class="comment__preview"
      ref="commentPreview"
      :style="{
        left: `${previewXPos}px`,
        top: `${previewYPos}px`,
        width: `${previewWidth}px`,
        opacity: show ? 1 : 0,
      }"
    >
      <div class="comment__preview-text">
        {{ commentToEdit }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditableComment",

  props: {
    isRequired: {
      required: false,
      type: Boolean,
      default: false,
    },
    comment: {
      required: false,
      type: String,
    },
    updateComment: {
      required: false,
      type: Function,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      commentToEdit: "",
      commentHasError: false,
      previewXPos: 0,
      previewYPos: 0,
      previewWidth: 0,
      show: false,
    };
  },

  watch: {
    comment(value) {
      this.commentToEdit = value;
    },
  },

  mounted() {
    document.body.append(this.$refs.commentPreview);
    this.previewWidth = this.$refs.comment.clientWidth;
    this.commentToEdit = this.comment;

    if (this.isRequired) {
      this.$watch("commentToEdit", (value) => {
        if (value.trim("")) {
          this.commentHasError = false;
        } else {
          this.commentHasError = true;
        }
      });
    }
  },

  beforeDestroy() {
    if (document.body.contains(this.$refs.commentPreview)) {
      this.deletePreview();
    }
  },

  methods: {
    update() {
      if (this.isRequired) {
        if (this.commentToEdit.trim("")) {
          this.updateComment(this.commentToEdit);
        } else {
          this.commentHasError = true;
        }
      } else {
        this.updateComment(this.commentToEdit);
      }
    },

    showPreview() {
      this.calculatePreviewWidthAndPos();
      this.show = true;
    },

    hidePreview() {
      this.show = false;
      this.previewXPos = 0;
      this.previewYPos = 0;
    },

    deletePreview() {
      document.body.removeChild(this.$refs.commentPreview);
    },

    calculatePreviewWidthAndPos() {
      const commentEl = this.$refs.comment;
      const rect = commentEl.getBoundingClientRect();
      this.previewXPos = rect.x;
      this.previewYPos = rect.y + 55;
    },
  },
};
</script>

<style scoped lang="scss">
.comment {
  background: var(--bg-15);
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  border-radius: var(--radius-1);
  display: flex;
  align-items: center;
  padding: 2px 10px;
  position: relative;

  textarea {
    width: 100%;
    background: 0;
    border: 0;
    resize: none;
    color: var(--text-9);
  }

  &__submit {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-left: 5px;
    background: url("../assets/icons/default/confirm_icon.svg");

    &:hover {
      background: url("../assets/icons/default/confirm_hover_icon.svg");
    }
  }

  &__preview {
    position: absolute;
    background: var(--bg-1);
    border-radius: var(--radius-1);
    padding: 10px;
    z-index: 99;
    white-space: pre-wrap;
    pointer-events: none;

    &-text {
      word-break: break-all;
    }
  }
}
</style>
