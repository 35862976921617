<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel modal__panel--sales">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--setting"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">Add Company to sale Category Price</div>
          </div>
        </div>
        <div class="add__form">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <div class="form__field">
                <VSelect
                    class="select select--default"
                    :class="{ 'select--error': companyHasError }"
                    :options="companies"
                    :reduce="(company) => company.id"
                    v-model="companyId"
                    label="name"
                    placeholder="Company"
                />
                <div class="form__error" v-if="companyHasError">
                  Please select a company
                </div>
              </div>
              <div class="form__field">
                <input
                  type="text"
                  class="input input--default"
                  :class="{ 'input--error': provisionHasError }"
                  placeholder="Standard percentage"
                  v-model="provision"
                />
                <div class="form__error" v-if="provisionHasError">
                  Please enter a valid percentage
                </div>
              </div>



            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        class="modal__button modal__button--submit button button--default"
        @click="addSaleCategoryPriceCompany()"
      >
        <span>Add</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/partials/Loading";
export default {
  name: "AddSaleCategoryCompanyModal",

  components: {
    Loading,
  },

  data() {
    return {
      formLoading: false,
      title: "",
      provision: "",
      companyId: "",
      isRequesting: false,

      titleHasError: false,
      provisionHasError: false,
      companyHasError: false,
    };
  },

  watch: {
    title() {
      this.validateTitle();
    },

    provision() {
      this.validateProvision();
    },

    companyId() {
      this.validateCompany();
    },
  },

  computed: {
    ...mapGetters(["salesCategoryPriceCompaniesData", "saleCategoryPriceId", "language"]),

    branchCategories() {
      return this.saleCategoriesData["branch-categories"];
    },

    companies() {
      return this.salesCategoryPriceCompaniesData.companies;
    },
  },

  async created() {
    this.formLoading = true;

    await this.$store.dispatch("getSaleCategoryCompanies", this.saleCategoryPriceId);
    this.formLoading = false;
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddSaleCategoryCompanyModal", false);
    },

    validateProvision() {
      let decimalProvision = "";
      if (this.provision && this.provision.includes(".")) {
        decimalProvision = this.provision.split(".")[1];
      }

      if (
        this.provision &&
        this.provision <= 100 &&
        this.provision >= 0 &&
        decimalProvision.length <= 4
      ) {
        this.provisionHasError = false;

      } else {
        this.provisionHasError = true;

      }
    },

    validateTitle() {
      if (!this.title) {
        this.titleHasError = true;
      } else {
        this.titleHasError = false;
      }
    },

    validateCompany() {
      if (!this.companyId) {
        this.companyHasError = true;
      } else {
        this.companyHasError = false;
      }
    },
    async addSaleCategoryPriceCompany()
    {
      this.isRequesting = true;
      this.validateProvision();
      this.validateCompany();  if (
        !this.provisionHasError &&
        !this.companyHasError
    ) {
      await this.$store.dispatch("addSaleCategoryPriceCompany", {
        companyId: this.companyId,
        provision: this.provision,
        salesCategoryPriceId: this.saleCategoryPriceId,
      });
      await this.$root.$emit('priceCompanyAdded')
      this.closeModal();
    }
      this.isRequesting = false;

    },

  },
};
</script>

<style scoped lang="scss">
.modal__button {
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
}

.add {
  &__form {
    width: 100% !important;
  }
}

.form__field {
  width: 280px !important;
  margin: 20px auto;
}
.modal__panel--sales {
  height: 450px;
}


</style>
