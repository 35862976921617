<template>
  <div class="modal confirmation-modal">
    <div class="confirmation-modal__panel">
      <div class="modal__main-icon modal__main-icon--delete-user"></div>
      <div class="confirmation-modal__body">
        <span
          >Are you sure that you want to delete this
          <span v-if="$route.name === 'Customers'">Customer </span
          ><span v-if="$route.name === 'Users'">Agent </span>
          <span v-if="$route.name === 'Reports'">Report </span>
          <span v-if="$route.name === 'UserRights'">role </span>
          <span>{{ deleteModal.message }}?</span>
        </span
        >
        <!-- <div>{{ deleteModal.message }}?</div> -->
      </div>
      <div class="confirmation-modal__footer">
        <button
          type="button"
          class="confirmation-modal__button confirmation-modal__button--cancel"
          @click="
            $store.commit('setDeleteModal', {
              display: false,
              message: '',
            })
          "
        >
          Cancel
        </button>
        <button
          type="button"
          class="confirmation-modal__button confirmation-modal__button--delete"
          @click="deleteEntity()"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DeleteModal",

  computed: {
    ...mapGetters(["deleteModal"]),
  },

  methods: {
    deleteEntity() {
      if(Array.isArray(this.deleteModal.action))
        this.deleteModal.action[0](this.deleteModal.id);
      else
        this.deleteModal.action(this.deleteModal.id);
      this.$store.commit("setDeleteModal", {
        display: false,
        message: "",
        action: null,
        id: null,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.delete {
  &__body {
    font-size: var(--x-smaller);
    text-align: center;
    color: var(--text-2);
    font-size: var(--medium);
    font-family: var(--muli-text-semi-bold);
    line-height: 33px;
    margin: 50px 0 45px 0;
  }
  &__footer {
    display: flex;
    justify-content: center;
  }
  &__button {
    height: 40px;
    width: 135px;
    margin-right: 30px;
    color: var(--text-1);
    border-radius: var(--radius-1);
    font-size: var(--medium);
    background: var(--bg-18);
    box-shadow: 0px 7px 18px var(--shadow-11);
    transition: all 0.3s ease;
    &:hover {
      box-shadow: 0px 0px 20px var(--shadow-11);
      background: var(--hover-3);
    }
    &--submit {
      background: var(--bg-26);
      box-shadow: 0px 7px 18px var(--shadow-8);
      margin: 0 0 0 30px;
      &:hover {
        box-shadow: 0px 0px 20px var(--shadow-8);
        background: var(--hover-4);
      }
    }
  }
}
</style>
