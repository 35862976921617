import api from "@/api";

export default {
  state: {
    branchCategoryServices: [],
  },
  getters: {
    branchCategoryServices: (state) => state.branchCategoryServices,
  },
  mutations: {
    setBranchCategoryServices: (state, data) => {
      state.branchCategoryServices = data;
    },
  },
  actions: {
    getBranchCategoryServices: async ({ commit }) => {
      try {
        const response = await api({ reqiresAuth: true }).get(
          "/v1/branch-category-services"
        );
        const { data } = response.data;
        commit("setBranchCategoryServices", data);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
