import api from "@/api";

export default {
  state: {
    franchises: [],
  },
  getters: {
    franchises: (state) => state.franchises,
  },
  mutations: {
    setFranchises: (state, data) => {
      state.franchises = data;
    },
  },
  actions: {
    getFranchises: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/franchises"
        );
        const { data } = response.data;
        commit("setFranchises", data);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
