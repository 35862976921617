<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel modal__panel--search-potential">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>

      </div>

      <div class="modal__main-icon modal__main-icon--potential"></div>
      <div
          v-if="!showSearch"
          class="modal__back"
          @click="goBack()"
      ></div>
      <div
          class="modal__close"
          @click="$store.commit('setShowAddSearchFamilyMemberModal', false)"
      ></div>

      <div class="form">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>

            <div class="navigator__title">{{ $t(`addSearchFamilyMemberModal.${"title"}`) }}</div>
          </div>
        </div>

          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">


                <div v-if="showSearch" class="form__field">
                  <div class="add__form">
                <div class="search__input__modal_wrapper">
                  <div class="form__field">
                    <div class="form__label">Search for available members to add</div>
                    <input
                        type="text"
                        @keyup.enter="onEnter"
                        class="input input--default input--family"
                        :class="{ 'input--error': nameKeywordsHasError }"
                        v-model="nameKeywords"
                    />
                    <div class="form__error"  v-if="nameKeywordsHasError">
                      Please enter name
                    </div>

                  </div>
                  <div
                      class="search__filter"

                      @click="showSearchFilter = !showSearchFilter"
                  ></div>
                </div>

                <div v-if="showSearchFilter">


                  <div class="form__label">Apply filters</div>

                  <div class="form__field">
                    <VSelect
                        class="select select--default select--shadow"
                        :options="resource.memberTypes"
                        label="type"
                        :appendToBody="true"
                        :calculatePosition="withPopper"
                        placeholder="Member type"
                        :reduce="(type) => type.id"
                        v-model="memberTypeId"

                    />


                  </div>
                  <div class="form__field">
                    <div class="search-filter__column search-filter__column--inputs">
                      <div class="search-filter__column">
                        <Datepicker
                            class="input input--default"
                            :config="statics.datePickerConfig"
                            placeholder="Birthday"
                            v-model="birthday"
                        />
                      </div>
                      <div class="search-filter__column">
                        <input
                            type="text"
                            class="input input--default"
                            placeholder="Zip"
                            v-model="zip"
                        />
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                </div>


                <div
                  v-if="showResults"
                  class="table "
                  :class="{'showSearchFilter': showSearchFilter}"
                >
                  <span class="empty" v-if="!customers.length">
                    <div class="empty__title" >
                      No family members found to add
                    </div>
                  </span>
                  <div class="table__body"  v-else>

                    <CustomerFamilySearch
                        @clicked-add-customer="clickedAddCustomer"
                        v-for="customer in customers"
                        :key="customer.id"
                        :customer="customer"
                        :selecting-status="false"
                        @clicked-remove-customer="clickedRemoveCustomer"
                    />
                  </div>

                </div>
                <div v-if="showFamilyRelations" class="table">

                  <div class="form__label">
                    What is your relation with client ?
                  </div>
                  <div class="table__body"  >
                    <CustomerFamilySearch

                        v-for="customer in selectedCustomers"
                        :key="customer.customer.id"
                        :customer="customer.customer"
                        :familyStatuses="resource.familyStatuses"
                        :selectingStatus="true"
                        :familyStatusId="customer.familyStatusId"
                        @update:familyStatusId="customer.familyStatusId = $event"
                        @clicked-remove-customer="clickedRemoveCustomer"
                    />

                  </div>
                </div>

            </div>
          </transition>

      </div>

      <button
          class="modal__button modal__button--submit button button--default"
          @click="clickHandler()"

      >
        <span>{{ buttonState }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import Loading from "@/partials/Loading";
import { parseCustomerName } from "@/helpers/entityParsers";

import {mapGetters} from "vuex";
import statics from "@/statics";
import CustomerFamilySearch from "@/components/Customers/CustomerFamilySearch";

export default {
  name: "AddSearchFamilyMember",

  components: {
    CustomerFamilySearch,
    Loading,
  },


  data() {
    return {
      statics,
      formLoading: false,
      nameKeywords: "",
      potentialCustomerContact: {},
      showSearch: true,
      showResults: false,
      showFamilyRelations: false,
      customerExists: false,
      nameKeywordsHasError: false,
      buttonState: "Search",
      birthday: "",
      zip: "",
      parseCustomerName,
      resource : {},
      memberTypeId: "",
      loaded: false,
      customers : [],
      selectedCustomers: [],
      showSearchFilter: true,
    };
  },

  watch: {
    phoneKeywords(value) {
      if (value) {
        this.nameKeywordsHasError = false;
      } else {
        this.nameKeywordsHasError = true;
      }
    },

    selectedCustomers(state) {
      if (state && this.selectedCustomers.length > 0) {
        this.buttonState = "Next";
      }else {
        this.buttonState = "Search";
      }
    },
    showFamilyRelations(state) {
      if (state) {
        this.buttonState = "Add members";
      }else {
        this.buttonState = "Search";
      }
    }
  },

  computed: {
    ...mapGetters([
      "searchCustomerForms",
      "customerToAddMember"
    ]),

  },

  methods: {
    onEnter: function() {
        this.clickHandler();
    },
    goBack(){
      this.showFamilyRelations = false;
      this.showSearch = true;
      this.selectedCustomers = [];
      this.showResults = true;
    },
    clickedAddCustomer(value){
        this.selectedCustomers.push({customer: value, familyStatusId: 1});

    },

    clickedRemoveCustomer(value){
      this.selectedCustomers = this.selectedCustomers.filter(item => item.customer !== value)
      if(this.selectedCustomers.length == 0){
        this.goBack();
      }
      //this.selectedCustomers
    },

    async addMember(){
      this.formLoading = true;
      let membersToAdd =  this.selectedCustomers.map(function (item){
        return {

          family_status_id: item.familyStatusId,
          member_id: item.customer.id
        }

      });

      await this.$store.dispatch("addMember",{
              customerId: this.customerToAddMember,
              members: membersToAdd
        }
      );

      if (this.$route.name === "AccountDetails") {
        await this.$store.dispatch("getGlobalCustomer", this.customerToAddMember);
      }
      this.$store.commit("setShowAddSearchFamilyMemberModal", false);
      this.$store.commit("setToast", {
        display: true,
        type: "success",
        message: "Customer member added successfully.",
      });
      this.formLoading = false;
    },

    clickHandler() {
      if (this.showSearch && this.selectedCustomers.length == 0) {
        this.search();
      } else if (this.selectedCustomers.length > 0 && this.showFamilyRelations == false) {
        this.showSearch = false;
        this.showResults = false;
        this.showFamilyRelations = true;
      }else if (!this.showResults && this.showFamilyRelations) {
        this.addMember();
      }
    },



    async search() {
      if (this.nameKeywords) {
        this.formLoading = true;
        this.showSearchFilter = false;
        this.nameKeywordsHasError = false;
        try {

        this.customers =  await this.$store.dispatch("searchCustomerForFamily", {
            parentCustomerId: this.customerToAddMember,
            name: this.nameKeywords,
            member_type_id: this.memberTypeId,
            birthday: this.birthday,
            zip: this.zip,
          });

         this.showResults = true;
        } catch (err) {
          console.error(err);
        }
        this.formLoading = false;
      } else {
        this.nameKeywordsHasError = true;
      }
    },
  },

  async created() {
    this.formLoading = true;
    await this.$store.dispatch("getSearchAddFamilyMemberForms");
    this.resource = this.searchCustomerForms;
    this.formLoading = false;
    this.loaded = true;
  }
};
</script>

<style scoped lang="scss">
.input--default, .select--default {
  background-color: var(--bg-3);
}
.table {
  height: 530px;
  &.showSearchFilter {
    height: 400px;
  }

  .table__body {
    height: calc(100% - 34px - 100px);
  }
}

.search-filter {
  position: absolute;
  top: 100%;
  right: 0;
  background: var(--bg-1);
  width: 900px;
  padding: 60px;
  z-index: 2;
  box-shadow: 0px 5px 30px var(--shadow-4);
  border-radius: var(--radius-3);

  &__title {
    color: var(--text-5);
    font-family: var(--muli-text-semi-bold);
    font-size: var(--medium);
    margin-bottom: 50px;
  }

  &__row,
  &__column--inputs {
    display: flex;
  }

  &__row {
    margin-bottom: 40px;
  }

  &__column {
    flex: 1;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__button {
    margin: 60px auto 0 auto !important;
  }
}
.add__content {
  height: calc(100% - 130px);
  .form {
    &__label {
      color: var(--text-2);
      text-align: center;
      margin-bottom: 20px;
      font-size: var(--x-small);
      font-family: var(--muli-text-semi-bold);
    }

    &__field {
      margin-bottom: 15px;
      flex: 1;
    }
  }
}
.button {
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;


  &__title {
    max-width: 310px;
    text-align: center;
    color: var(--text-2);
    font-size: var(--medium);
    line-height: 23px;

  }
}
.search__input__modal_wrapper {
  display: flex;
}
.search__filter {
  width: 25px;
  height: 50px;
  margin-top: 28px;
  margin-left: 10px;
  border-left: 0px;
}


</style>
