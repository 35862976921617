<template>
  <div
    class="policy-accident-wrapper"
    :class="[
      `policy-accident-wrapper--${statusColor}`,
      { 'animate__animated animate__zoomOutLeft': isDeleting },
      { deleting: wasDeleted },
      { 'policy-accident-wrapper--open': showChildren },
    ]"
  >
    <div class="policy-accident">
      <div class="policy-accident__row">
        <div
          class="policy-accident__data policy-accident__data--large policy-accident__data--accident-date"
        >
          <div>
            <div>
              {{ userName }}
            </div>
            <div>{{ formatDisplayDate(accident.date) }}</div>
          </div>
          <div class="policy-accident__contact">
            <a
              class="icon icon__phone"
              :href="
                accident.user_id.phone
                  ? `tel:${accident.user_id.phone}`
                  : 'javascript:void(0)'
              "
              :class="{ 'icon__phone--active': accident.user_id.phone }"
            ></a>
            <a
              class="icon icon__mail"
              :href="
                accident.user_id.email
                  ? `mailto:${accident.user_id.email}`
                  : 'javascript:void(0)'
              "
              :class="{ 'icon__mail--active': accident.user_id.email }"
            ></a>
          </div>
        </div>
        <div class="policy-accident__data policy-accident__data--large">
          <EditableComment :comment="accident.comment" :readonly="true" />
        </div>
        <div class="policy-accident__data policy-accident__data--medium">
          {{ price }}
        </div>
        <div class="policy-accident__data policy-accident__data--medium">
          <div class="policy-accident__status-badge"></div>
          <span>{{ accident.accident_status.name }}</span>
        </div>
        <div class="policy-accident__data policy-accident__data--large">
          <div
            class="icon icon__add"
            @click="openAddAccidentModal(accident)"
          ></div>
          <div
            class="icon icon__pdf"
            :class="{ 'icon__pdf--active': files.length }"
            @click="openPolicyFilesModal(accident.id, files, 'accident')"
          ></div>
          <div
            class="icon icon__edit"
            @click="openEditAccidentModal(accident)"
          ></div>
          <div class="icon icon__delete" @click="removeAccident()"></div>
          <div
            class="icon icon__chevron-down-gray"
            :class="{ 'icon__chevron-down-gray--active': showChildren }"
            v-if="accident.childrens.length"
            @click="toggleChildren()"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="policy-accident__children"
      :style="{
        maxHeight: showChildren ? `${containerMaxHeight}px` : '0px',
      }"
    >
      <AccidentChildren
        v-for="accident in accidents"
        :key="accident.id"
        :accident="accident"
        :openEditAccidentModal="openEditAccidentModal"
        :openPolicyFilesModal="openPolicyFilesModal"
      />
    </div>
  </div>
</template>

<script>
import EditableComment from "@/partials/EditableComment";
import { formatDisplayDate } from "@/helpers/dateFormatters";
import statics from "@/statics";
import AccidentChildren from "@/components/AccountDetails/AccidentChildren";
export default {
  name: "Accident",

  components: {
    EditableComment,
    AccidentChildren,
  },

  props: {
    accident: {
      required: true,
      type: Object,
    },

    openPolicyFilesModal: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      formatDisplayDate,
      statics,
      isDeleting: false,
      wasDeleted: false,
      showChildren: false,
      accidentHeight: 66,
    };
  },

  computed: {
    userName() {
      return `${this.accident.user_id.first_name} ${this.accident.user_id.last_name}`;
    },

    statusColor() {
      return this.statics.accidentStatuses.find(
        (status) => status.id === this.accident.accident_status.id
      ).name;
    },

    price() {
      return this.accident.price
        ? `${this.accident.price} .-CHF`
        : statics.empty;
    },

    containerMaxHeight() {
      return this.accident.childrens.length * this.accidentHeight;
    },

    files() {
      return [
        ...this.accident.accident_files,
        ...this.accident.childrens
          .map((accident) => accident.accident_files)
          .flat(),
      ];
    },

    accidents() {
      if (this.accident.childrens.length) {
        return [...this.accident.childrens].sort((acc, accident) =>
          acc.accident_status.id < accident.accident_status.id ? -1 : 1
        );
      }
      return [];
    },
  },

  methods: {
    openEditAccidentModal(accident) {
      this.$store.commit("setAccidentToEdit", accident);
      this.$store.commit("setShowAddAccidentModal", true);
    },

    toggleChildren() {
      this.showChildren = !this.showChildren;
    },

    async removeAccident() {
      try {
        this.isDeleting = true;
        await this.$store.dispatch("removeAccident", this.accident.id);
        this.wasDeleted = true;
        setTimeout(async () => {
          await this.$store.dispatch(
            "getGlobalCustomer",
            this.$route.params.id
          );
        }, 300);
      } catch (err) {
        console.error(err);
      }
    },

    openAddAccidentModal({ policy_id, id }) {
      this.$store.commit("setAccidentPolicyId", policy_id);
      this.$store.commit("setAccidentParentId", id);
      this.$store.commit("setShowAddAccidentModal", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-accident-wrapper--processing {
  background: #7f8fa41a;
}
.policy-accident-wrapper--accepted {
  background: #22db8e1a;
}
.policy-accident-wrapper--declined {
  background: #db22221a;
}

.policy-accident-wrapper {
  margin-bottom: 15px;
  box-shadow: 0px 0px 15px var(--shadow-4);
  position: relative;
  border-radius: var(--radius-1);

  &:last-child {
    margin-bottom: 0;
  }

  &--open {
    padding: 0 1px 1px 0;
  }

  &--processing {
    .policy-accident__status-badge {
      background: #7f8fa4;
    }
  }

  &--accepted {
    .policy-accident__status-badge {
      background: #22db8e;
    }
  }

  &--declined {
    .policy-accident__status-badge {
      background: #db2222;
    }
  }
}

::v-deep.policy-accident {
  height: 66px;
  border: 0;
  z-index: 1;

  &__data {
    padding-left: 40px;
    color: var(--text-9);
    &--small {
      flex: 1;
    }
    &--medium {
      flex: 2;
    }
    &--large {
      flex: 3;
    }

    &--accident-date {
      justify-content: space-between;
    }
  }

  &__contact {
    display: flex;

    .icon__phone {
      margin-right: 15px;
    }
  }

  &__status-badge {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .comment {
    background: var(--bg-1);
  }

  .icon {
    margin-right: 15px;
  }

  &__children {
    overflow: hidden;
    transition: all 0.3s ease;
  }
}
</style>
