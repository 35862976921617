var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"comment",staticClass:"comment",class:{ 'input--error': _vm.commentHasError },on:{"mouseover":function($event){return _vm.showPreview()},"mouseout":function($event){return _vm.hidePreview()}}},[(!_vm.readonly)?[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.commentToEdit),expression:"commentToEdit"}],domProps:{"value":(_vm.commentToEdit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.commentToEdit=$event.target.value}}}),(
        _vm.commentToEdit !== _vm.comment && (_vm.commentToEdit.trim('') || !_vm.isRequired)
      )?_c('div',{staticClass:"comment__submit",on:{"click":function($event){return _vm.update()}}}):_vm._e()]:[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.commentToEdit),expression:"commentToEdit"}],attrs:{"readonly":""},domProps:{"value":(_vm.commentToEdit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.commentToEdit=$event.target.value}}})],_c('div',{ref:"commentPreview",staticClass:"comment__preview",style:({
      left: (_vm.previewXPos + "px"),
      top: (_vm.previewYPos + "px"),
      width: (_vm.previewWidth + "px"),
      opacity: _vm.show ? 1 : 0,
    })},[_c('div',{staticClass:"comment__preview-text"},[_vm._v(" "+_vm._s(_vm.commentToEdit)+" ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }