import api from "@/api";

export default {
  state: {
    countries: [],
  },
  getters: {
    countries: (state) => state.countries,
  },
  mutations: {
    setCountries: (state, data) => {
      state.countries = data;
    },
  },
  actions: {
    getCountries: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get("/v1/countries");
        const { data } = response.data;
        commit("setCountries", data);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
